// write your custom styles here!
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&family=Quicksand:wght@300;400;500;600;700&display=swap');

.print-container {
      font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
  width: 350px;
  margin: 5px auto;
  text-align: center;
  .today-date{
    margin-top: 10px;
  }

  h3 {
    text-align: center;
    margin: 4px;
    font-size: 22px;
    font-weight: normal;
  }

  p,
  tr,
  strong,
  b {
    font-size: 15px;
    margin: 0;
  }

  b,
  strong {
    font-weight: bold;
  }
tr{
      display: flex;
    justify-content: space-between;
    width: 100%;
}


  table {
    max-width: 100%;
    background-color: transparent;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  table {
    width: 100%;
    margin-bottom: 4px;
    text-align: left;
  }
  tr td:first-child {
    width: 1%;
    white-space: nowrap;
    vertical-align: top;
}

}