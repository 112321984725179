// custom-switch-box
.custom-switch-box {
  .react-switch {
    height: 30px;
    border-radius: 20px;
    width: 61px;
    background: #fff;
    top: 4px;

    .react-switch-button {
      height: 24px;
      max-width: 25px;
      top: 0;
      border-radius: 100%;
      background-color: #0dcd94;
      top: 3px;
      left: 3px;
    }

    .react-switch-labels {
      span {
        z-index: 1;
        font-weight: 700;
        font-size: 15px;
        line-height: 17px;
      }
    }
  }
}

.switch-outer {

  // position: relative;
  .custom-switch-box {
    // position: absolute;
    // right: 17px;
    // top: 6px;
    // margin-top: 7px;
  }

  .custom-numpad {
    width: 150px;
    margin-right: 10px;
    position: relative;

    @media screen and (max-width: 1919px) {
      width: 100px;
    }

    input {
      height: 30px;
    }
  }
}

.react-switch-checkbox {
    height: 0;
    width: 0;
    position: absolute;
    visibility: hidden;
  }
  .react-switch {
    cursor: pointer;
    width: 100px;
    height: 50px;
    background: #fff;
    position: relative;
    transition: background-color 0.2s;
  }
  
  .react-switch .react-switch-button {
    position: absolute;
    z-index: 1;
    top: 2px;
    left: 2px;
    width: 45px;
    height: 45px;
    transition: 0.2s;
    background: #000;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  }
  
  .react-switch-checkbox:checked + .react-switch-button {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }
  
  .react-switch-checkbox:active + .react-switch-button {
    width: 60px;
  }
  
  .react-switch-labels {
    z-index: 0;
    height: 100%;
    border: 1px solid #B1B1B1;
border-radius: 20px;
  }
  
  .react-switch-labels span {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
  }
  .react-switch-labels span:first-child {
    left: 0;
  }
  .react-switch-labels span:last-child {
    right: 0;
  }
  