// expand-table-row
.custom-data-table {
  // Sales table
  .sales-pos-table {
    .expand-table-row {
      thead {
        tr {
          th {
            &[aria-label="GID sortable"],
            &[aria-label="Customer Name sortable"],
            &[aria-label="#Items sortable"],
            &[aria-label="Total sortable"],
            &[aria-label="Sale Time sortable"] {
              display: none;
            }
            @media screen and (max-width: 767px) {
              &[aria-label="Mobile sortable"],
              &[aria-label="Status sortable"] {
                display: none;
              }
            }
          }
        }
      }

      tbody {
        tr {
          td {
            &[data-title="GID"],
            &[data-title="Customer Name"],
            &[data-title="#Items"],
            &[data-title="Total"],
            &[data-title="Sale Time"] {
              display: none;
            }
            @media screen and (max-width: 767px) {
              &[data-title="Mobile"],
              &[data-title="Status"] {
                display: none;
              }
            }
            &.reset-expansion-style {
              .row-expansion-style {
                @media screen and (min-width: 768px) {
                  .mobile-sales {
                    display: none;
                  }
                  .status-sales {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  // web orders table
  .web-orders-table {
    .expand-table-row {
      thead {
        tr {
          th {
            &[aria-label="GID sortable"],
            &[aria-label="Sale Time sortable"],
            &[aria-label="Order Since sortable"],
            &[aria-label="Order Type sortable"] {
              display: none;
            }
            @media screen and (max-width: 991px) {
              &[aria-label="Payment Type sortable"],
              &[aria-label="Status sortable"] {
                display: none;
              }
            }
            @media screen and (max-width: 576px) {
              &[aria-label="Address sortable"],
              &[aria-label="#Items sortable"],
              &[aria-label="Total sortable"] {
                display: none;
              }
            }
          }
        }
      }

      tbody {
        tr {
          td {
            &[data-title="GID"],
            &[data-title="Sale Time"],
            &[data-title="Order Since"],
            &[data-title="Order Type"] {
              display: none;
            }
            @media screen and (max-width: 991px) {
              &[data-title="Payment Type"],
              &[data-title="Status"] {
                display: none;
              }
            }
            @media screen and (max-width: 576px) {
              &[data-title="Address"],
              &[data-title="#Items"],
              &[data-title="Total"] {
                display: none;
              }
            }
            &.reset-expansion-style {
              .row-expansion-style {
                @media screen and (min-width: 992px) {
                  .payment-orders {
                    display: none;
                  }
                  .status-orders {
                    display: none;
                  }
                }
                @media screen and (min-width: 577px) {
                  .address-orders {
                    display: none;
                  }
                  .items-orders {
                    display: none;
                  }
                  .total-orders {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  // Items Table
  .items-pos-table {
    .expand-table-row {
      thead {
        tr {
          th {
            &[aria-label="Price sortable"],
            &[aria-label="Tax sortable"],
            &[aria-label="Total sortable"] {
              display: none;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            &[data-title="Price"],
            &[data-title="Tax"],
            &[data-title="Total"] {
              display: none;
            }
          }
        }
      }
    }
  }
  // Payments Table
  .payment-pos-table {
    .expand-table-row {
      thead {
        tr {
          th {
            &[aria-label="Tendered sortable"],
            &[aria-label="Change sortable"] {
              display: none;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            &[data-title="Tendered"],
            &[data-title="Change"] {
              display: none;
            }
          }
        }
      }
    }
  }
  // Task list table
  .task-detail-table {
    .expand-table-row {
      thead {
        tr {
          th {
            &[aria-label="Date & Time sortable"],
            &[aria-label="Status sortable"] {
              display: none;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            &[data-title="Date & Time"],
            &[data-title="Status"] {
              display: none;
            }
          }
        }
      }
    }
  }
  // Item contain table
  .items-contain {
    .expand-table-row {
      thead {
        tr {
          th {
            // &[aria-label="Name sortable"],
            &[aria-label="Tax sortable"],
            &[aria-label="Total sortable"],
            &[aria-label="Edit sortable"] {
              display: none;
            }
          }
        }
      }

      tbody {
        tr {
          &.promotion-row{
            .expand-cell{
              pointer-events: none;
              img{
                display: none;
              }
            }
            td {
              &[data-title="Name"]{
                display: table-cell;
              }
              // &:last-child{
              //     display: none;
              // }
            }
            // + tr{
            //   display: none;
            // }
            + .discount-row{
              display: table-row;
            }
          }
          &.discount-row{
            .expand-cell{
              pointer-events: none;
              img{
                display: none;
              }
            }
            td {
              &[data-title="Name"]{
                display: table-cell;
              }
              &:last-child{
                  display: table-cell;
              }
              //  &[data-title="Price"]{
              //   display: none;
              //  }
            }
          }
          td {
            // &[data-title="Name"],
            &[data-title="Tax"],
            &[data-title="Total"],
            &[data-title="Edit"] {
              display: none;
            }
          }
        }
      }
    }
  }
  // Takings report table
  .taking-table {
    .expand-table-row {
      thead {
        tr {
          th {
            &[aria-label="# Payout sortable"],
            &[aria-label="Payout sortable"],
            &[aria-label="Balance sortable"] {
              display: none;
            }
            @media screen and (max-width: 576px) {
              &[aria-label="# Refunds sortable"],
              &[aria-label="Refunds sortable"] {
                display: none;
              }
            }
          }
        }
      }

      tbody {
        tr {
          td {
            &[data-title="# Payout"],
            &[data-title="Payout"],
            &[data-title="Balance"] {
              display: none;
            }
            @media screen and (max-width: 576px) {
              &[data-title="# Refunds"],
              &[data-title="Refunds"] {
                display: none;
              }
            }
            &.reset-expansion-style {
              .row-expansion-style {
                @media screen and (min-width: 577px) {
                  .refunds-taking {
                    display: none;
                  }
                  .refunds1-taking {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  // seller report table
  .seller-table {
    .expand-table-row {
      thead {
        tr {
          th {
            &[aria-label="Refunds sortable"],
            &[aria-label="Balance sortable"] {
              display: none;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            &[data-title="Refunds"],
            &[data-title="Balance"] {
              display: none;
            }
          }
        }
      }
    }
  }
  // Register report table
  .register-table {
    .expand-table-row {
      thead {
        tr {
          th {
            &[aria-label="Total sortable"] {
              display: none;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            &[data-title="Total"] {
              display: none;
            }
          }
        }
      }
    }
  }
  // Transaction table
  .transaction-table{
    .expand-table-row {
      thead {
        tr {
          th {
            &[aria-label="User sortable"], 
            &[aria-label="Total sortable"], 
            &[aria-label="Details sortable"] {
              display: none;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            &[data-title="User"], 
            &[data-title="Total"], 
            &[data-title="Details"] {
              display: none;
            }
          }
        }
      }
    }
  }
  
}
