@media (max-width: 767px) {
  .custom-data-table .column-visibility-table.card .table th.selection-cell-header, .custom-data-table .column-visibility-table.card .table th.expand-cell-header{
    min-width: 27px;
    padding-left: 0;
  }
  .custom-data-table .column-visibility-table.card .table tbody td.selection-cell, .custom-data-table .column-visibility-table.card .table tbody td.expand-cell{
    min-width: 27px;
    padding-left: 0;
  }
  .navbar-toggler {
    left: 0;
  }

  .maxwidth_none .navbar .navbar-collapse,
  .maxwidth_1280px .navbar .navbar-collapse,
  .maxwidth_1152px .navbar .navbar-collapse,
  .maxwidth_1366px .navbar .navbar-collapse,
  .maxwidth_960px .navbar .navbar-collapse,
  .maxwidth_null .navbar .navbar-collapse {
    padding: 10px 10px 0 10px;
    position: fixed;
    top: 42px;
    left: -87%;
    /* Adjust this value for the width of the slide-in window */
    height: 100% !important;
    width: calc(100% - 96px);
    /* Adjust this value for the width of the slide-in window */
    background-color: #fff;
    box-shadow: -2px 0 5px -3px rgba(0, 0, 0, 0.2);
    transition: left 0.3s ease-out;
    /* Add a transition for smooth animation */
    z-index: 1000;
    display: block !important;
    opacity: 1 !important;
    border-radius: 0;

    &.show {
      left: 0;
      animation: none;
    }

    &.collapsing {
      animation: none;
    }

    &.collapse:not(.show) {
      display: block;
    }
  }

  .maxwidth_none .navbar .secondary-navbar.navbar-nav,
  .maxwidth_1280px .navbar .secondary-navbar.navbar-nav,
  .maxwidth_1152px .navbar .secondary-navbar.navbar-nav,
  .maxwidth_1366px .navbar .secondary-navbar.navbar-nav,
  .maxwidth_960px .navbar .secondary-navbar.navbar-nav,
  .maxwidth_null .navbar .secondary-navbar.navbar-nav {
    display: none;
  }

  .navbar {
    height: 42px;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
    width: 100%;
  }

  .navbar {
    .shortcut-overlay {
      display: block;
    }
  }

  .content {
    margin-top: 0;
    padding-top: 55px;
    
  }

  .navbar-toggler {
    position: relative;

    &.shortcut-overlay {
      position: fixed;
      top: 43px;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.5);
      /* Semi-transparent background color */
      display: block;
      z-index: 999;
      /* Place it below the slide-in window */
      cursor: pointer;
      /* Show a pointer cursor on hover */
      width: 100%;
      height: 100%;

      .navbar-toggler-icon {
        display: none;
      }
    }

  }

  .has-box-shadow {
    justify-content: space-between;
  }

  .toggle-btn {
    .btn {
      border-radius: 0;

      // &:first-child{
      //   border-left: 2px solid #fff;
      // }
      img {
        transform: rotate(91deg);
      }
    }
  }

  .right-tab{
    >.btn {
      position: fixed;
      top: 0;
      width: auto;
      right: 98px;
      border-radius: 0;
      height: 41px;
      z-index: 12;

      img {
        position: relative;
        top: -4px;
        left: -2px;
      }
    }
  }

  .online-status {
    position: fixed;
    top: 0;
    width: calc(100% - 186px) !important;
    left: 37px;
    padding: 0;
    flex-direction: row;
    letter-spacing: 0;
    height: 42px;
    z-index: 12;
    justify-content: center;
    align-items: center;
    img {
      position: relative;
      margin-right: 0 !important;
    }

    span {
      font-size: 17px;
    }
  }

}

.mobile-cash-register {
  .navbar .primary-navbar .nav-link.hide-for-mobile-cash-register {
    @media (max-width: 767px) {
      display: none;
    }
  }
}