// Mobile code for responsive
.mobile-wrapper {
  @media screen and (max-width: 767px) {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.4);
    padding: 15px;
    display: none;
  }
}


.navbar-toggler {
  position: absolute;
  right: 0;
  top: 0;
  background-color: transparent;
  height: 42px;
  border-radius: 0;
  width: 37px;
  padding: 0;
  box-shadow: none;
  border: none;
  cursor: pointer;

  &:focus {
    box-shadow: none;
  }
  svg{
    width: 30px;
    height: 21px;
  }

  // .navbar-toggler-icon {
  //   background-image: url("../../assets/images/hamburger.svg");
  //   background-repeat: no-repeat;
  //   background-position: center;
  //   width: 1.1em;
  // }
}

// Repsonsive code for full width
.maxwidth_none,
.maxwidth_1280px,
.maxwidth_1152px,
.maxwidth_1366px,
.maxwidth_960px,
.maxwidth_null {
  .pay-btn-wrapper {
    @media screen and (max-width: 1023px) {
      position: sticky;
      border-radius: 10px 10px 0 0;
      bottom: 0;
      background: #fff;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.4);
      padding: 0 15px;
      width: 104.4%;
      margin-left: -16px;
      margin-top: 20px;
      z-index: 1;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      margin: 0;
      left: 0;
      bottom: 12px;
      z-index: 9;
      position: fixed;
      // box-shadow: 0 -4px 12px 0 rgba(0, 0, 0, 0.2);
      box-shadow: none;
      background: transparent;
      padding: 0 12px;
      display: flex;
      // // background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 78%,rgba(255,255,255,1) 79%);
      // background: #fff;
      justify-content: space-between;
      border-radius: 0;
     

    }
  }

  .pay-btn {
    @media screen and (max-width: 1023px) {
      margin-bottom: 13px;
      margin-top: 13px !important;
    }
    @media screen and (max-width: 767px) {
      margin: 0 !important;
      width: 48%;
      font-size: 20px;
      justify-content: center;
      align-items: center;
    }


    &:hover {
      @media screen and (max-width: 1023px) {
        -webkit-transform: translateY(0);
        transform: translateY(0);
      }
    }
  }

  .right-tab {
    @media screen and (max-width: 1023px) {
      overflow: inherit;
    }
  }

  // .has-box-shadow {
  //   @media screen and (max-width: 1279px) {
  //     flex-direction: column-reverse;
  //   }
  // }
  .navbar {
    .secondary-navbar {
      @media screen and (max-width: 1919px) {
        padding: 9px 38px 9px 5px;
      }

      @media screen and (max-width: 1439px) {
        padding-right: 0;
      }

      @media screen and (max-width: 991px) {
        flex-direction: row;
      }

      .nav-link {
        @media screen and (max-width: 1919px) {
          padding: 0 15px;
        }

        @media screen and (max-width: 1439px) {
          padding: 0 10px;
          margin-right: 10px;
        }

        @media screen and (max-width: 767px) {
          margin-right: 0;
        }
      }

      &.navbar-nav {
        @media screen and (max-width: 767px) {
          flex-direction: row;
          overflow: auto;
          white-space: nowrap;
          width: 88%;
        }
      }
    }

    .navbar-collapse {
      @media screen and (max-width: 991px) {
        top: 44px;
        border-radius: 0;
        padding: 15px;
        height: auto !important;
        width: 30%;
        right: 0;
      }
  
    }

    .primary-navbar {
      @media screen and (max-width: 991px) {
        align-items: flex-start !important;
      }

      @media screen and (max-width: 767px) {
        background: transparent;
      }

      .nav-link {
        @media screen and (max-width: 1919px) {
          font-size: 15px;
          padding: 0 14px;
        }

        @media screen and (max-width: 1479px) {
          font-size: 15px;
          padding: 0 11px;
        }

        @media screen and (max-width: 1279px) {
          height: 45px;
          font-size: 16px;
        }

        @media screen and (max-width: 991px) {
          border-right: none;
          border-left: none;
          padding: 0;
          color: #1a1a1a;
          font-size: 16px;
          font-weight: 400;
        }

        @media screen and (max-width: 767px) {
          background: transparent;
          width: 100% !important;
          border-right: none;
          justify-content: flex-start;
          font-size: 16px;
          vertical-align: middle;
          padding: 0.55rem 0.75rem;
          border-radius: 0;
          margin-bottom: 0.2rem;
          font-weight: 500;
        }
        img{
          @media screen and (max-width: 767px) {
          margin-right: 8px;
    vertical-align: text-bottom;
    filter: grayscale(100%);
    width: 20px;
    height: 20px;
          }
        }
        &.active{
          background-color: #e9f0f8;
          border-bottom: 3px solid #3965d8;
          img{
            @media screen and (max-width: 767px) {
            filter: none;
            }
          }
        }
      }
    }
  }

  .content {
    @media screen and (max-width: 991px) {
      padding-bottom: 0;
    }
    @media screen and (max-width: 767px) {
      padding-bottom: 5px;
    }
  }

  .custom-card {
    .card-header {

      // @media screen and (max-width: 1279px) {
      //   padding: 0.3rem 0.65rem;
      // }
      @media screen and (max-width: 767px) {
        padding: 0.6rem 0.65rem;
      }

      h2 {
        @media screen and (max-width: 1279px) {
          margin-left: 0;
        }
      }
    }

    // .card-body{
    //   @media screen and (max-width: 1279px) {
    //     padding-left: 0.675rem !important;
    //     padding-right: 0.675rem !important;
    //   }
    // }
  }

  .register {
    .card-header {
      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }

    .top-search {
      @media screen and (max-width: 767px) {
        width: 100%;
        justify-content: space-between;
        margin-top: 10px;
      }
    }
  }

  .register-outer {
    .col-lg-8 {
      @media screen and (max-width: 1479px) {
        padding-right: 0;
      }

      @media screen and (max-width: 1023px) {
        width: 100%;
        padding-right: calc(var(--bs-gutter-x) / 2);
      }

      @media screen and (max-width: 767px) {
        padding-right: calc(var(--bs-gutter-x) / 3);
        padding-left: calc(var(--bs-gutter-x) / 3);
      }
    }

    .col-lg-4 {
      @media screen and (max-width: 1023px) {
        width: 100%;
        margin-top: 20px;
      }

      @media screen and (max-width: 767px) {
        padding-right: calc(var(--bs-gutter-x) / 3);
        padding-left: calc(var(--bs-gutter-x) / 3);
        margin-top: 16px;
      }
    }

    .card-body {
      @media screen and (max-width: 1479px) {
        // padding-left: 0.675rem !important;
        // padding-right: 0.675rem !important;
      }
    }
  }

  .top-search {
    @media screen and (max-width: 1279px) {
      width: 65%;
    }

    .add-btn {

      @media screen and (max-width: 1279px) {
        padding: 0.46rem 0.75rem;
        width: 135px;
      }
      @media screen and (max-width: 767px) {
        width: auto;
        height: 37px;
      }
      img{
        @media screen and (max-width: 767px) {
          width: 28px;
          height: auto;
        }
      }
    }

    .stock-search {
      input {
        @media screen and (max-width: 1279px) {
          padding: 0.46rem 0.75rem;
        }

        +div {
          @media screen and (max-width: 767px) {
            min-width: auto !important;
            width: 90%;
            z-index: 11;
          }
        }
      }

      .react-auto-complete {
        .list-group-item {
          @media screen and (max-width: 767px) {
            border-bottom: 1px solid #eee;
          }

          span {
            &:first-child {
              @media screen and (max-width: 767px) {
                width: 100%;
                margin-bottom: 5px;
              }
            }
          }
        }
      }
    }
  }

  .custom-data-table {
    .card {
      .items-contain {

        // @media screen and (max-width: 1279px) {
        //   height: calc(100vh - 480px);
        // }
        // @media screen and (max-width: 1023px) {
        //   height: auto;
        // }
        .table {
          tbody {
            // @media screen and (max-width: 1919px) {
            //   max-height: 550px;
            //   height: 550px;
            // }
            // @media screen and (max-width: 1279px) {
            //   height: calc(100vh - 537px);
            //   max-height: calc(100vh - 537px) !important;
            // }
            // @media screen and (max-width: 959px) {
            //   height: auto;
            //   max-height: calc(100vh - 487px) !important;
            // }
            // @media screen and (max-width: 767px) {
            //   height: 350px !important;
            //   max-height: 350px !important;
            // }
          }
        }
      }
    }
  }

  .items-contain {

    // @media screen and (max-width: 1279px) {
    //   min-height: calc(100vh - 480px);
    // }
    thead {
      tr {
        th {
          &:first-child {
            @media screen and (max-width: 1279px) {
              width: 10%;
            }
            @media screen and (max-width: 767px) {
              width: auto;
            }
          }

          &:nth-child(2) {
            @media screen and (max-width: 1279px) {
              width: 15%;
            }
            @media screen and (max-width: 767px) {
              width: auto;
            }
          }

          &:nth-child(4) {
            @media screen and (max-width: 1279px) {
              width: 16%;
            }
            @media screen and (max-width: 767px) {
              width: auto;
            }
          }

          &:nth-child(6) {
            @media screen and (max-width: 1279px) {
              width: 10%;
            }
            @media screen and (max-width: 767px) {
              width: auto;
            }
          }
        }
      }
    }

    tbody {
      tr {
        td {
          @media screen and (max-width: 1279px) {
            cursor: pointer;
          }

          &:first-child {
            @media screen and (max-width: 1279px) {
              width: 10%;
            }
            @media screen and (max-width: 575px) {
              width: auto;
            }
          }

          &:nth-child(2) {
            @media screen and (max-width: 1279px) {
              width: 27%;
            }
            @media screen and (max-width: 767px) {
              width: auto;
            }
          }

          &:nth-child(4) {
            @media screen and (max-width: 1279px) {
              width: 16%;
            }
            @media screen and (max-width: 767px) {
              width: auto;
            }
          }

          &:nth-child(6) {
            @media screen and (max-width: 1279px) {
              width: 10%;
            }

            @media screen and (max-width: 991px) {
              justify-content: flex-start;
            }
            @media screen and (max-width: 767px) {
              width: auto;
            }
          }
        }
      }
    }

    .custom-numberpad {

      // @media screen and (max-width: 959px) {
      //   left: -145px;
      // }
      @media screen and (max-width: 959px) {
        left: 50%;
        transform: translateX(-49%);
        position: absolute !important;
      }
        @media screen and (max-width: 767px) {
          transform: translateX(-67%);
      }
    }
  }

  .total-info {
    @media screen and (max-width: 1279px) {
      flex-wrap: wrap;
      justify-content: flex-start;
      padding: 1rem 0 0.5rem;
      margin-top: 0.3rem !important;
    }
    @media screen and (max-width: 767px) {
  margin-top: 20px !important;
  border-radius: 4px;
  padding: 1rem 0;
    }

    .list-group-item {
      @media screen and (max-width: 1919px) {
        padding-left: 0;
        padding-right: 0;
      }

      @media screen and (max-width: 1279px) {
        padding: 0 1rem 0.5rem;
      }

      @media screen and (max-width: 767px) {
        width: 100% !important;
        display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
      }

      &:first-child {
        @media screen and (max-width: 1919px) {
          padding-left: 0.5rem;
        }

        @media screen and (max-width: 1279px) {
          padding-left: 1rem;
        }
      }

      &:last-child {
        @media screen and (max-width: 1919px) {
          padding-right: 0.5rem;
        }

        @media screen and (max-width: 1279px) {
          padding-right: 1rem;
        }

        @media screen and (max-width: 767px) {
          width: 100% !important;
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }

      p {
        @media screen and (max-width: 1919px) {
          font-size: 15px;
        }

        @media screen and (max-width: 1279px) {
          font-size: 17px;
        }
      }

      h2 {
        @media screen and (max-width: 1919px) {
          font-size: 13px;
        }

        @media screen and (max-width: 1279px) {
          font-size: 0.875rem;
        }
      }
    }

    .custom-numberpad {
      @media screen and (max-width: 767px) {
        bottom: auto;
        top: auto;
        left: 50%;
        transform: translateX(-65%);
      }
    }
  }

  .cta-btn-wrapper {
    .btn {

      // @media screen and (max-width: 767px) {
      //   width: 48%;
      //   margin-bottom: 10px;
      // }
      &:last-child {
        // @media screen and (max-width: 767px) {
        //   margin-bottom: 0;
        // }
      }
    }
  }

  .switch-outer {
    .custom-numpad {
      @media screen and (max-width: 1479px) {
        width: 70px;
      }

      @media screen and (max-width: 1279px) {
        width: 200px;
      }
      @media screen and (max-width: 767px) {
        width: 80px;
      }
    }
  }

  .shortcut {

    // @media screen and (max-width: 1279px) {
    //   height: calc(100vh - 412px);
    // }
    @media screen and (max-width: 1023px) {
      height: calc(100vh - 711px);
      width: 101.8%;
    }

    @media screen and (max-width: 767px) {
      height: calc(100vh - 63px);
    }
    @media screen and (max-height: 412px) {
      height: 500px;
    }
    .wrapper {
      @media screen and (max-width: 1023px) {
        min-height: 64px;
      }

      @media screen and (max-width: 1023px) {
        min-height: 100%;
      }

      h3 {
        @media screen and (max-width: 1279px) {
          font-size: 12px;
          word-break: break-all;
        }

        @media screen and (max-width: 1023px) {
          font-size: 14px;
        }
      }
    }
  }

  .back-btn {
    // @media screen and (max-width: 1279px) {
    //   top: 12px;
    //   left: 7px;
    // }

    // @media screen and (max-width: 767px) {
    //   top: 18px;
    // }
  }

  .shortcut-wrapper {
    h2 {
      @media screen and (max-width: 1279px) {
        margin-right: 15px;
      }
    }
  }

  .custom-numberpad {
    .MuiPaper-root {
      &.MuiPaper-elevation1 {
        @media screen and (max-width: 1279px) {
          width: 317px;
        }

        @media screen and (max-width: 1023px) {
          padding-bottom: 13px;
        }
      }

      .MuiButton-root {
        @media screen and (max-width: 1279px) {
          font-size: 20px;
          padding: 9px 0 !important;
        }
      }
    }

    .cta-btn {
      @media screen and (max-width: 1279px) {
        margin-left: 12px;
      }

      .btn {
        @media screen and (max-width: 1279px) {
          font-size: 18px;
          padding: 0.56rem 0.75rem;
        }
      }
    }
  }

  .footer {
    @media screen and (max-width: 991px) {
      position: relative;
    }
    @media screen and (max-width: 767px) {
      // padding-bottom: 80px;
      // position: fixed;
      background: transparent;
      box-shadow: none;
    }

 

    // @media screen and (max-width: 767px) {
    //   padding-bottom: 100px;
    // }

    .list-group-item {
      @media screen and (max-width: 1919px) {
        padding: 0 15px;
      }

      @media screen and (max-width: 1479px) {
        font-size: 13px;
      }

      @media screen and (max-width: 1279px) {
        // padding: 0 7px;
        letter-spacing: -0.2px;
      }

      @media screen and (max-width: 991px) {
        font-size: 14px;
      }

      @media screen and (max-width: 767px) {
        width: 100% !important;
        border-right: none;
        border-bottom: 2px solid $blue;
        border-top: none !important;
        padding: 0;
        justify-content: flex-start !important;

      }

      &.show{
        @media screen and (max-width: 767px) {
        display: flex;
        }
      }
      &.hide{
        @media screen and (max-width: 767px) {
        display: none;
        }
      }

      &:first-child {
        @media screen and (max-width: 1679px) {
          width: 31%;
        }

        @media screen and (max-width: 991px) {
          width: 50%;
        }

        @media screen and (max-width: 767px) {
          // font-size: 15px;
          justify-content: center;
        }
      }

      &:nth-child(2) {
        @media screen and (max-width: 1679px) {
          width: 22%;
        }

        @media screen and (max-width: 991px) {
          width: 50%;
          justify-content: center;
          // font-size: 16px;
        }
      }

      &:nth-child(3) {
        @media screen and (max-width: 1679px) {
          width: 35%;
        }

        @media screen and (max-width: 991px) {
          width: 50%;
          border-top: 2px solid #0daeeb;
        }

        @media screen and (max-width: 767px) {
          border-bottom: none !important;
          padding-top: 10px;
        }

        div {
          @media screen and (max-width: 1919px) {
            padding: 0 15px;
            font-size: 15px;
          }

          @media screen and (max-width: 1479px) {
            font-size: 14px;
          }

          @media screen and (max-width: 1279px) {
            padding: 0 9px;
            font-size: 12px;
          }

          @media screen and (max-width: 991px) {
            padding: 0 11px;
            width: 100%;
            font-size: 13px;
          }

          @media screen and (max-width: 767px) {
            padding: 0;
            height: 42px;
          }
        }
      }

      &:last-child {
        @media screen and (max-width: 1479px) {
          font-size: 14px;
        }

        @media screen and (max-width: 1279px) {
          font-size: 12px;
        }

        @media screen and (max-width: 991px) {
          width: 50%;
          border-top: 2px solid #0daeeb;
          font-size: 17px;
        }
        @media screen and (max-width: 767px) {
          display: none;
        }
        // @media screen and (max-width: 767px) {
        //   border-bottom: none !important;
        //   position: fixed;
        //   top: 0;
        //   width: calc(100% - 132px) !important;
        //   left: 37px;
        //   padding: 0;
        //   flex-direction: row;
        //   letter-spacing: 0;
        // height: 42px;
        // z-index: 12;
        // }
        // img{
        //   @media screen and (max-width: 767px) {
        //     position: relative;
        //     margin-right: 0 !important;
        //   }
        // }
        // span{
        //   @media screen and (max-width: 767px) {
        //     font-size: 17px;
        //   }
        // }
      }
    }
  }
}

