.dark-mode {
  background-color: rgb(18, 18, 18);
  .wrapper{
    background-color: rgb(18, 18, 18);
  }
  .navbar-light:not(.headroom--not-top) {
    background-color: rgb(30, 30, 30);
    color: rgba(255, 255, 255, 0.87);
    background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    );
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  }
  * {
    color: rgba(255, 255, 255, 0.87) !important;
    // border-color: rgba(255, 255, 255, 0.05) !important;
  }
  .nav-item .nav-link:hover,
  .nav-item.active > .nav-link {
    background-color: rgba(255, 255, 255, 0.05);
  }
  .maxwidth_none .navbar .primary-navbar .nav-link.active, .maxwidth_1280px .navbar .primary-navbar .nav-link.active, .maxwidth_1152px .navbar .primary-navbar .nav-link.active, .maxwidth_1366px .navbar .primary-navbar .nav-link.active, .maxwidth_960px .navbar .primary-navbar .nav-link.active, .maxwidth_null .navbar .primary-navbar .nav-link.active{
    @media screen and (max-width: 767px) {
      background-color: rgba(255, 255, 255, 0.05);
    }
  }
  .maxwidth_none .total-info, .maxwidth_1280px .total-info, .maxwidth_1152px .total-info, .maxwidth_1366px .total-info, .maxwidth_960px .total-info, .maxwidth_null .total-info{
    @media screen and (max-width: 767px) {
      background-color: rgb(18, 18, 18) !important;
    }
  }
  .maxwidth_none .navbar .navbar-collapse, .maxwidth_1280px .navbar .navbar-collapse, .maxwidth_1152px .navbar .navbar-collapse, .maxwidth_1366px .navbar .navbar-collapse, .maxwidth_960px .navbar .navbar-collapse, .maxwidth_null .navbar .navbar-collapse,.maxwidth_none .pay-btn-wrapper, .maxwidth_1280px .pay-btn-wrapper, .maxwidth_1152px .pay-btn-wrapper, .maxwidth_1366px .pay-btn-wrapper, .maxwidth_960px .pay-btn-wrapper, .maxwidth_null .pay-btn-wrapper,.shortcut-container.bottom {
    background-color: rgb(30, 30, 30);
    // border: 1px solid rgba(255, 255, 255, 0.05) !important;
    background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    );
    box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 1px, rgba(0, 0, 0, 0.12) 0px 2px 1px,
      rgba(0, 0, 0, 0.2) 0px 1px 3px;
  }
  .sidebar {
    background-color: rgb(30, 30, 30) !important;
    border-right: 1px solid rgba(255, 255, 255, 0.05);
    .sidebar-inner .accordion-button:not(.collapsed) {
      background-color: transparent;
    }
    .nav-item .nav-link:hover,
    .nav-item.active > .nav-link {
      background-color: rgba(255, 255, 255, 0.05);
    }
  }
  .custom-time-picker {
    .form-label {
      background-color: rgb(30, 30, 30) !important;
    }
  }
  .daterangepicker td.off,
  .daterangepicker td.off.in-range,
  .daterangepicker td.off.start-date,
  .daterangepicker td.off.end-date,
  .daterangepicker select.hourselect,
  .daterangepicker select.minuteselect,
  .daterangepicker select.secondselect,
  .daterangepicker select.ampmselect,
  select option,
  .daterangepicker.show-ranges.ltr .drp-calendar.left,
  .daterangepicker.show-calendar .drp-buttons {
    background: transparent;
    border-color: rgba(255, 255, 255, 0.05) !important;
  }
  .daterangepicker td.in-range {
    background-color: rgb(18, 18, 18) !important;
  }
  .rdtPicker,
  .daterangepicker,
  .daterangepicker .calendar-table {
    background-color: rgb(30, 30, 30);
    background-image: linear-gradient(
      rgba(255, 255, 255, 0.12),
      rgba(255, 255, 255, 0.12)
    );
    box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 1px, rgba(0, 0, 0, 0.12) 0px 2px 1px,
      rgba(0, 0, 0, 0.2) 0px 1px 3px;
    border-color: rgba(255, 255, 255, 0.05) !important;
    &:checked {
      background-image: linear-gradient(
          rgba(255, 255, 255, 0.12),
          rgba(255, 255, 255, 0.12)
        ),
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    }
  }
  .daterangepicker .ranges li:hover,
  .daterangepicker td.available:hover,
  .daterangepicker th.available:hover {
    background-color: rgb(18, 18, 18);
  }
  .daterangepicker td.active {
    background-color: #357ebd !important;
  }
  .calendar-time option {
    background-color: rgb(18, 18, 18);
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px rgb(30, 30, 30) inset !important;
    -webkit-text-fill-color: #fff !important;
  }
  .transaction-details .wrapper {
    background-color: transparent;
  }
  .totals-box {
    .form-control {
      &::-webkit-input-placeholder {
        color: rgba(255, 255, 255, 0.87) !important;
      }

      &:-ms-input-placeholder {
        color: rgba(255, 255, 255, 0.87) !important;
      }

      &::placeholder {
        color: rgba(255, 255, 255, 0.87) !important;
      }
    }
  }
  .form-control {
    color: #fff !important;
    background-color: rgb(30, 30, 30);
    background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    );
    border-color: rgb(89, 89, 89) !important;
    &:focus {
      background: transparent;
    }
    &:not(:placeholder-shown) ~ label {
      background-color: rgb(30, 30, 30) !important;
    }
  }
  .top-search {
    .stock-search input {
      background-color: rgb(30, 30, 30);
      background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)
      );
      border-color: rgb(89, 89, 89) !important;
      &:focus {
        background: transparent;
      }
      &:not(:placeholder-shown) ~ label {
        background-color: rgb(30, 30, 30) !important;
      }
      &::-webkit-input-placeholder {
        color: rgba(255, 255, 255, 0.87) !important;
      }

      &:-ms-input-placeholder {
        color: rgba(255, 255, 255, 0.87) !important;
      }

      &::placeholder {
        color: rgba(255, 255, 255, 0.87) !important;
      }
    }
    // .form-label {
    //   background-color: rgb(30, 30, 30) !important;
    // }
    .stock-search.react-auto-complete .list-group-item {
      background-color: rgb(18, 18, 18);
    }
     .stock-search.react-auto-complete .list-group-item.focused {
      background-color: #1e1e1e;
      background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)
      );
    }
    .stock-search.react-auto-complete .list-group-item:hover {
      background-color: #1e1e1e;
      background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)
      );
    }
  }
  .form-floating.with-label-up .form-label {
    background-color: rgb(30, 30, 30) !important;
  }
  .form-floating {
    &.form-field--is-active {
      .form-label {
        background-color: rgb(30, 30, 30) !important;
      }
    }
    .form-label {
      background-color: transparent !important;
    }
  }
  .input-group-text {
    background-color: transparent;
    border-color: rgb(89, 89, 89) !important;
  }
  .custom-react-select {
    .react-select__control {
      background: transparent;
      border-color: rgb(89, 89, 89) !important;
    }
    .react-select__placeholder {
      background-color: rgb(30, 30, 30) !important;
    }
    .react-select__indicator.react-select__dropdown-indicator {
      background: url("../../assets/images/download-white1.svg") no-repeat
        center / 12px 19px;
    }
    .react-select__menu {
      background-color: rgb(30, 30, 30);
      background-image: linear-gradient(
        rgba(255, 255, 255, 0.12),
        rgba(255, 255, 255, 0.12)
      );
      box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 1px,
        rgba(0, 0, 0, 0.12) 0px 2px 1px, rgba(0, 0, 0, 0.2) 0px 1px 3px;
      border-color: rgba(255, 255, 255, 0.05) !important;
    }
  }
  .custom-react-select .react-select__option:hover,
  .custom-react-select .react-select__option:focus,
  .custom-react-select .react-select__option:active,
  .custom-react-select .react-select__option:focus:active {
    background-color: #121212;
    /* Add any other styles you want to remove or modify */
  }

  .custom-react-select .react-select__option--is-selected {
    background-color: #121212; /* Or any other desired color */
    /* Add any other styles you want to remove or modify */
  }
  .Toastify__toast {
    background-color: rgb(30, 30, 30);
    border: 1px solid rgba(255, 255, 255, 0.05) !important;
    background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    );
    box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 1px, rgba(0, 0, 0, 0.12) 0px 2px 1px,
      rgba(0, 0, 0, 0.2) 0px 1px 3px;
  }
  .checkout-popup .custom-numberpad {
    background-color: #121212 !important;
    background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    );
    border-top-color: rgba(255, 255, 255, 0.05);
    border-right-color: rgba(255, 255, 255, 0.05);
    border-left-color: rgba(255, 255, 255, 0.05);
    border-color: rgba(255, 255, 255, 0.05) !important;
  }
  .custom-numberpad .arrow-wrapper {
    background: transparent;
    border: 1px solid rgb(217, 217, 217) !important;
  }
  .custom-numberpad .arrow-wrapper .btn:first-child {
    border-right: 1px solid #fff;
  }
  .items-contain tbody tr td:nth-child(3) input {
    background: transparent;
  }
  .checkout-popup .custom-numberpad .bottom-cta .btn:disabled{
    background: #b1b1b1 !important;
  }
  .checkout-popup .custom-numberpad .bottom-cta .btn{
    background-color: transparent !important;
    border: 1px solid #fff;
  }
  .btn-outline-blackcustom,
  .checkout-popup .custom-numberpad .bottom-cta .btn:first-child,
  .login .btn-blue,
  .btn.btn-purpule-light,
  .btn-light,
  .btn-outline-seagreen,
  .btn-grey-custom-shade,
  .custom-numberpad .cta-btn .btn.cancel,
  .custom-numberpad .cta-btn .btn.clear,
  .btn-white,
  .btn-outline-danger {
    background-color: transparent !important;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    // border-color: rgb(89, 89, 89) !important;
    border-color: rgb(217, 217, 217) !important;
    color: rgb(217, 217, 217) !important;
    &:hover {
      color: rgb(245, 245, 245) !important;
      background-color: transparent !important;
      border-color: rgb(245, 245, 245) !important;
    }
  }
  .btn-link {
    border-color: transparent !important;
  }
  .page-item {
    &.disabled {
      .page-link {
        background-color: transparent;
      }
    }
    .page-link {
      background-color: transparent;
      border-color: rgba(255, 255, 255, 0.05) !important;
    }
  }
  .stats .bar-chart-wrapper {
    background: transparent;
  }
  .dropdown-menu {
    background-color: rgb(30, 30, 30);
    background-image: linear-gradient(
      rgba(255, 255, 255, 0.12),
      rgba(255, 255, 255, 0.12)
    );
    box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 1px, rgba(0, 0, 0, 0.12) 0px 2px 1px,
      rgba(0, 0, 0, 0.2) 0px 1px 3px;
    border-color: rgba(255, 255, 255, 0.05) !important;
    .dropdown-item {
      &:hover {
        background-color: rgba(255, 255, 255, 0.08);
      }
    }
  }
  .custom-tab-nav {
    .nav-link {
      &.active,
      &:hover {
        background: transparent;
      }
    }
  }
  .custom-data-table .with-scroll-on-table .table tbody tr{
    background-color: #1e1e1e !important;
  }
  .automation-settings.seven-column {
    border-color: rgba(255, 255, 255, 0.05);
  }
  .custom-modal {
    .modal-content {
      background-color: rgb(30, 30, 30);
      .modal-header,
      .modal-footer {
        border-color: rgba(255, 255, 255, 0.05);
      }
    }
    .btn-close {
      background: url("../../assets/images/close-white.svg") no-repeat center /
        1rem;
    }
  }
  .custom-card.reports-table {
    background: transparent !important;
  }

  .reports-table .card-header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  }
  .reports-table .card-header,
  .reports-table .reports-heading-range,
  .reports-table.no-fixed-height .search-header {
    background-color: rgb(30, 30, 30);
    border-color: rgba(255, 255, 255, 0.05) !important;
  }
  .reports-table .custom-range label {
    background-color: rgb(30, 30, 30);
  }
  .custom-range label {
    background-color: rgb(30, 30, 30);
  }
  .reports-table .card-body {
    background: transparent;
  }
  .footer-with-shadow {
    background-color: rgb(30, 30, 30);
    border: 1px solid rgba(255, 255, 255, 0.05) !important;
    background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    );
    box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 1px, rgba(0, 0, 0, 0.12) 0px 2px 1px,
      rgba(0, 0, 0, 0.2) 0px 1px 3px;
    border-left: none !important;
  }
  .tender-wrapper {
    background: transparent;
    border-color: rgba(255, 255, 255, 0.05);
  }
  .custom-card {
    background-color: rgb(30, 30, 30);
    // border: 1px solid rgba(255, 255, 255, 0.05) !important;
    background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    );
    box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 1px, rgba(0, 0, 0, 0.12) 0px 2px 1px,
      rgba(0, 0, 0, 0.2) 0px 1px 3px;
    .react-bootstrap-table
      .table-header
      th.sortable
      .react-bootstrap-table-sort-order,
    .react-bootstrap-table .table-header th:hover .order,
    .react-bootstrap-table .table-header th:hover .order-4 {
      background: url("../../assets/images/sort-white.svg") no-repeat center /
        cover;
    }
    .card-header {
      border-color: rgba(255, 255, 255, 0.05) !important;
    }
    th {
      background-color: rgb(18, 18, 18) !important;
      background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)
      );
      border-top-color: rgba(255, 255, 255, 0.05);
      border-right-color: rgba(255, 255, 255, 0.05);
      border-left-color: rgba(255, 255, 255, 0.05);
      border-color: rgba(255, 255, 255, 0.05) !important;
    }
    tr {
      border-top-color: rgba(255, 255, 255, 0.05) !important;
      border-bottom-color: rgba(255, 255, 255, 0.05) !important;
      border-color: rgba(255, 255, 255, 0.05) !important;
    }
    td {
      background-color: rgb(30, 30, 30) !important;
      border-color: rgba(255, 255, 255, 0.05) !important;
    }
  }
  .pagination-wrapper .dropdown .btn {
    background-color: rgb(30, 30, 30);
    border-color: rgb(89, 89, 89) !important;
    background-image: url("../../assets/images/download-white1.svg");
  }
  .mode .switch .slider {
    background-color: #fff;
  }
  .mode .switch .slider:before,
  .mode .switch .slider:after {
    background-color: rgb(30, 30, 30);
  }
  .custom-card .react-bootstrap-table input[type="checkbox"] {
    background-color: rgb(30, 30, 30);
    background-image: linear-gradient(
      rgba(255, 255, 255, 0.12),
      rgba(255, 255, 255, 0.12)
    );
    box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 1px, rgba(0, 0, 0, 0.12) 0px 2px 1px,
      rgba(0, 0, 0, 0.2) 0px 1px 3px;
    border-color: #fff;
  }
  .form-check-input[type="checkbox"] {
    background-color: rgb(30, 30, 30);
  }
  .column-visibility-accordion .accordion-body {
    background-color: rgb(30, 30, 30);
    background-image: linear-gradient(
      rgba(255, 255, 255, 0.12),
      rgba(255, 255, 255, 0.12)
    );
    box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 1px, rgba(0, 0, 0, 0.12) 0px 2px 1px,
      rgba(0, 0, 0, 0.2) 0px 1px 3px;
    border-color: rgba(255, 255, 255, 0.05) !important;
  }
  .login {
    background: #121212;
    // .form-control:not(:placeholder-shown) ~ label {
    //   background: #ffffff !important;
    //   color: #2e384d !important;
    // }
    // .form-control {
    //   background-color: transparent;
    //   background-image: none;
    //   color: #000 !important;
    // }
    // .form-floating .form-label {
    //   color: #000 !important;
    // }
    // .form-floating.form-field--is-active {
    //   .form-label {
    //     background-color: #fff !important;
    //   }
    // }
    // .form-check-input[type="checkbox"] {
    //   background-color: transparent;
    //   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    // }
    // .form-check-input:checked[type="checkbox"] {
    //   background-color: #000;
    // }
    // .form-check-label {
    //   color: #333333 !important;
    // }
    .form-floating .eye-icon path {
      fill: #fff;
    }
    .form-check-input:checked,
    .form-check-input:focus {
      border-color: #b1b1b1;
    }
    .bg-white {
      background-color: #1e1e1e !important;
      background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)
      );
      box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 1px,
        rgba(0, 0, 0, 0.12) 0px 2px 1px, rgba(0, 0, 0, 0.2) 0px 1px 3px;
      border-color: rgba(255, 255, 255, 0.05) !important;
    }
  }
  .dashboard-wrapper .widget-box li,
  .dashboard-wrapper .stats-list .list-group-item {
    background-color: rgb(30, 30, 30);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    );
    border: 1px solid rgba(255, 255, 255, 0.05);
  }
  .dashboard-wrapper .stats-dashboard .list-group {
    background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    );
    background-color: rgb(30, 30, 30);
    border-color: rgba(255, 255, 255, 0.05);
  }
  .list-group-item {
    background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    );
    background-color: rgb(30, 30, 30);
  }
  line {
    stroke: rgb(182, 182, 182);
    fill: rgb(177, 185, 196);
    filter: none;
    fill-opacity: 0.9;
  }
  .dashboard-wrapper .recharts-responsive-container .recharts-layer path {
    stroke: rgb(182, 182, 182);
  }
  // .recharts-tooltip-wrapper:not(.recharts-tooltip-item-list li){
  //   color: red !important;
  // }
  .recharts-default-tooltip {
    background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ) !important;
    background-color: rgb(30, 30, 30) !important;
  }
  .recieve-item .common-bg-form {
    background: transparent;
  }
  .general-tab .custom-data-table .custom-card {
    background: transparent;
  }
  .general-tab .custom-react-select .custom-overlay,
  .with-bg-color {
    background: rgb(30, 30, 30);
  }
  .add-promotion .with-bg-color .table {
    background: transparent;
  }
  .custom-data-table .card.customer-search .top-search .stock-search input {
    background-color: #1e1e1e;
    background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    );
    border-color: #595959 !important;
  }
  .import-csv .col > div:first-child {
    background-color: #121212 !important;
    border-color: rgba(255, 255, 255, 0.05);
  }
  .import-csv .export-modal ul li {
    border-color: rgba(255, 255, 255, 0.05);
  }
  .custom-timeline .vertical-timeline-element-content {
    background-color: rgb(30, 30, 30) !important;
    border: 1px solid rgba(255, 255, 255, 0.05) !important;
    background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    );
    box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 1px, rgba(0, 0, 0, 0.12) 0px 2px 1px,
      rgba(0, 0, 0, 0.2) 0px 1px 3px !important;
  }
  .custom-timeline
    .vertical-timeline-element-content
    .vertical-timeline-element-date {
    background-color: rgb(30, 30, 30) !important;
  }
  .vertical-timeline::before {
    background-color: rgb(30, 30, 30) !important;
  }
  .settings .custom-card {
    border: none;
  }
  .invoice-settings .custom-ck-editor .ck .ck-toolbar {
    background-color: rgb(30, 30, 30);
  }
  .ck.ck-editor__main > .ck-editor__editable {
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.05) !important;
  }
  .ck-balloon-rotator__content > * {
    // background: rgb(18, 18, 18)  !important;
    color: rgb(18, 18, 18) !important;
    background: rgb(18, 18, 18) !important;
  }
  .ck-balloon-rotator__content div:nth-child(3) *,
  .ck-balloon-rotator__content div:nth-child(3) *:hover {
    background: rgb(18, 18, 18) !important;
  }

  .payment-wrapper {
    background-color: rgb(18, 18, 18);
    background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    );
    border-color: rgba(255, 255, 255, 0.05);
  }
  .payment-info {
    border-color: rgba(255, 255, 255, 0.05);
  }

  .total-info {
    background-color: rgb(18, 18, 18) !important;
    background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    );
    border-top-color: rgba(255, 255, 255, 0.05);
    border-right-color: rgba(255, 255, 255, 0.05);
    border-left-color: rgba(255, 255, 255, 0.05);
    border-color: rgba(255, 255, 255, 0.05) !important;
    .list-group-item {
      background-color: rgb(18, 18, 18) !important;
      background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)
      );
    }
  }
  .settings .accordion-button:not(.collapsed),
  .settings .accordion-button {
    background-color: #1e1e1e;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    );
    border: 1px solid rgba(255, 255, 255, 0.05);
  }
  .wrapper.watermark-added .register .custom-card {
    background-color: #1e1e1e;
    background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    );
    box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 1px, rgba(0, 0, 0, 0.12) 0px 2px 1px,
      rgba(0, 0, 0, 0.2) 0px 1px 3px;
  }
  .todays-takings .list-group .list-group-item {
    background-color: #1e1e1e;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    );
    border: 1px solid rgba(255, 255, 255, 0.05);
  }
  .todays-takings .list-group .list-group-item .me-auto span.up {
    color: #049e64;
  }
  .todays-takings .list-group .list-group-item .me-auto span.down {
    color: red;
  }
  .todays-takings .list-group .list-group-item .me-auto span {
    background: transparent;
  }
  .settings .custom-tab-nav,
  .settings .settings-tab,
  .settings .general .accordion-body,
  .settings .registration-list .list-group-item {
    background: transparent;
  }
  .accordion-button:not(.collapsed)::after {
    background-image: url("../../assets/images/caret-up-white.svg");
  }
  .accordion-button:after {
    background-image: url("../../assets/images/caret-down-white.svg");
  }

  .custom-numberpad .MuiPaper-root .MuiButton-root {
    color: #000;
  }
  .custom-numberpad .MuiPaper-root.MuiPaper-elevation1 {
    background-color: #121212 !important;
    background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    );
    border-top-color: rgba(255, 255, 255, 0.05);
    border-right-color: rgba(255, 255, 255, 0.05);
    border-left-color: rgba(255, 255, 255, 0.05);
    border-color: rgba(255, 255, 255, 0.05) !important;
  }

  .custom-numberpad .MuiPaper-root .MuiButton-root {
    background-color: #1e1e1e;
    background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    );
    box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 1px, rgba(0, 0, 0, 0.12) 0px 2px 1px,
      rgba(0, 0, 0, 0.2) 0px 1px 3px;
  }
  .input-search .custom-numpad input,
  .custom-switch-box .react-switch {
    background: transparent;
  }
  .footer .list-group-item {
    background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    );
    background-color: rgb(30, 30, 30);
  }
  .footer .list-group-item:nth-child(3) div,
  .void-refund {
    background: transparent;
  }
  .ace-github .ace_marker-layer,
  .ace_active-line,
  .rdtPicker td.rdtToday,
  .rdtPicker td.rdtDay:hover,
  .rdtPicker td.rdtHour:hover,
  .rdtPicker td.rdtMinute:hover,
  .rdtPicker td.rdtSecond:hover,
  .rdtPicker .rdtTimeToggle:hover,
  td.rdtMonth:hover,
  td.rdtYear:hover,
  .custom-time-picker .react-datepicker__input-container input,
  .react-datepicker__header,
  .react-datepicker__month,
  .react-datepicker__time-container .react-datepicker__time,
  .rdtCounter .rdtBtn:hover,
  .react-datepicker__header *,
  .react-datepicker {
    background-color: rgb(18, 18, 18);
  }
  .rdw-editor-toolbar {
    background-color: transparent;
  }
  .rdw-editor-toolbar * {
    border-color: #000;
    color: #000 !important;
  }
  // .rdw-dropdown-optionwrapper,.rdw-dropdown-optionwrapper:hover{background-color: #1e1e1e;}
  .generate-invoice .card {
    background: transparent;
    border: 1px solid #595959;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item:hover {
    background-color: #216ba5;
  }
  .form-switch .form-check-input[type="checkbox"]:checked {
    background-color: #049e64;
  }
  .column-visibility-accordion .accordion-button:not(.collapsed) {
    background-color: rgb(18, 18, 18);
  }
  .sketch-picker {
    background-color: rgb(18, 18, 18) !important;
  }
  .flexbox-fix * {
    color: #000 !important;
  }
  .top-search .stock-search input + div {
    background: 121212;
  }

  .edit-user .custom-card .accordion-button:not(.collapsed),
  .edit-user .custom-card .card-body .fields li.header {
    background: rgb(18, 18, 18) !important;
  }
  .edit-user .custom-card .accordion-button {
    background: transparent;
  }
  .custom-card .react-bootstrap-table .form-switch input[type="checkbox"] {
    background-image: url("../../assets/images/circlegreen.svg");
    box-shadow: none;
    // background-color: rgb(18, 18, 18) !important;
    // border-color: #0dcd94;
  }
  .custom-card
    .react-bootstrap-table
    .form-switch.label-invert
    .form-check-input:checked {
    // background-color: #fff !important;
    background-image: url("../../assets/images/circlewhite.svg");
  }
  .css-hcsisw-wrapper,
  .react-timekeeper__clock-wrapper,
  .react-timekeeper__clock,
  .react-timekeeper-button-reset,
  .ck.ck-button:not(.ck-disabled):hover,
  a.ck.ck-button:not(.ck-disabled):hover,
  .ck.ck-button.ck-on:not(.ck-disabled):hover,
  a.ck.ck-button.ck-on:not(.ck-disabled):hover,
  .ck.ck-list,
  .ck.ck-list__item .ck-button:hover:not(.ck-disabled),
  .ck.ck-button.ck-on,
  a.ck.ck-button.ck-on,
  .ck.ck-link-form .ck-editor__top,
  .ck.ck-dropdown .ck-button.ck-dropdown__button.ck-on:active:focus,
  .ck.ck-dropdown .ck-button.ck-dropdown__button.ck-off:active,
  .ck.ck-dropdown .ck-button.ck-dropdown__button.ck-on:active,
  .react-timekeeper__dropdown-number,
  select option,
  td.rdtMonth:hover,
  td.rdtYear:hover {
    background: rgb(18, 18, 18);
  }

  .react-timekeeper__dropdown-number:hover {
    background-color: #1e1e1e;
  }
  svg.react-timekeeper__clock-hand circle {
    stroke: rgb(18, 18, 18);
    fill: rgb(18, 18, 18);
  }
  .import-offline-sales form > div,
  .rdtPicker thead tr:first-of-type th:hover {
    background: transparent !important;
  }
  .react-bootstrap-table .form-control {
    color: #fff !important;
  }
  .form-floating.with-label-up {
    .form-control {
      &::-webkit-input-placeholder {
        /* Edge */
        color: #fff !important;
        opacity: 1;
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #fff !important;
        opacity: 1;
      }

      &::placeholder {
        color: #fff !important;
        opacity: 1;
      }
    }
  }
  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    background-color: #216ba5;
  }
  .sortable-column{
    background-color: #000 !important;
  }
  .shortcut .row{
    padding-top: 2px;
    padding-left: 1px;
  }
  .footer{
    background: transparent;
  }
}
