// @font-face {
//   font-family: "Roboto";
//   src: local("Roboto"),
//     url("../../assets/fonts/Roboto-Black.woff") format("woff2"),
//     url("../../assets/fonts/Roboto-Black.woff") format("woff");
//   font-weight: 900;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "Roboto";
//   src: local("Roboto"),
//     url("../../assets/fonts/Roboto-Bold.woff2") format("woff2"),
//     url("../../assets/fonts/Roboto-Bold.woff") format("woff");
//   font-weight: bold;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "Roboto";
//   src: local("Roboto"),
//     url("../../assets/fonts/Roboto-BoldItalic.woff2") format("woff2"),
//     url("../../assets/fonts/Roboto-BoldItalic.woff") format("woff");
//   font-weight: bold;
//   font-style: italic;
//   font-display: swap;
// }

// @font-face {
//   font-family: "Roboto";
//   src: local("Roboto"),
//     url("../../assets/fonts/Roboto-BlackItalic.woff2") format("woff2"),
//     url("../../assets/fonts/Roboto-BlackItalic.woff") format("woff");
//   font-weight: 900;
//   font-style: italic;
//   font-display: swap;
// }

// @font-face {
//   font-family: "Roboto";
//   src: local("Roboto"),
//     url("../../assets/fonts/Roboto-Light.woff2") format("woff2"),
//     url("../../assets/fonts/Roboto-Light.woff") format("woff");
//   font-weight: 300;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "Roboto";
//   src: local("Roboto"),
//     url("../../assets/fonts/Roboto-Italic.woff2") format("woff2"),
//     url("../../assets/fonts/Roboto-Italic.woff") format("woff");
//   font-weight: normal;
//   font-style: italic;
//   font-display: swap;
// }

// @font-face {
//   font-family: "Roboto";
//   src: local("Roboto"),
//     url("../../assets/fonts/Roboto-LightItalic.woff2") format("woff2"),
//     url("../../assets/fonts/Roboto-LightItalic.woff") format("woff");
//   font-weight: 300;
//   font-style: italic;
//   font-display: swap;
// }

// @font-face {
//   font-family: "Roboto";
//   src: local("Roboto"),
//     url("../../assets/fonts/Roboto-MediumItalic.woff2") format("woff2"),
//     url("../../assets/fonts/Roboto-MediumItalic.woff") format("woff");
//   font-weight: 500;
//   font-style: italic;
//   font-display: swap;
// }

// @font-face {
//   font-family: "Roboto";
//   src: local("Roboto"),
//     url("../../assets/fonts/Roboto-Regular.woff2") format("woff2"),
//     url("../../assets/fonts/Roboto-Regular.woff") format("woff");
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "Roboto";
//   src: local("Roboto"),
//     url("../../assets/fonts/Roboto-Medium.woff2") format("woff2"),
//     url("../../assets/fonts/Roboto-Medium.woff") format("woff");
//   font-weight: 500;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "Roboto";
//   src: local("Roboto"),
//     url("../../assets/fonts/Roboto-Thin.woff2") format("woff2"),
//     url("../../assets/fonts/Roboto-Thin.woff") format("woff");
//   font-weight: 100;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "Roboto";
//   src: local("Roboto"),
//     url("../../assets/fonts/Roboto-ThinItalic.woff2") format("woff2"),
//     url("../../assets/fonts/Roboto-ThinItalic.woff") format("woff");
//   font-weight: 100;
//   font-style: italic;
//   font-display: swap;
// }

// body{
//   background-color: $black;
// }

// login
.modal-open {
  .modal {
    display: block;
  }
}

.login {
  background-image: url("../../assets/images/signin-bg.png");
  background-repeat: no-repeat;
  background-size: cover;

  .bg-white {
    max-width: 510px;

    @media screen and (max-width: 767px) {
      padding: 0 10px;
    }
  }

  iframe {
    display: block;
  }

  .container {
    @media screen and (max-width: 767px) {
      padding: 0 10px;
    }
  }
}

.accordion-button:after {
  background-image: url("../../assets/images/caret-down.svg");
  background-position: center;
  width: 14px;
  height: 16px;
  background-repeat: no-repeat;
  background-size: contain;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("../../assets/images/caret-up.svg");
  transform: rotate(0);
  background-position: center;
}

.table-bordered > :not(caption) > * > * {
  border-color: $blackcustom;
  border-width: 0 0 0.0313rem 0.0313rem;
}

.table-bordered > :not(caption) > * {
  border-color: $blackcustom !important;
  border-width: 0.0313rem 0;
}

.btn {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;

  &.btn-outline-blackcustom {
    color: $dark-gray;

    &:hover,
    &:active,
    &:focus {
      background: #e9f0f8;
      color: $dark-gray;
    }
  }

  &:disabled {
    background: $blackcustom !important;
    border-color: $blackcustom;
  }

  &.btn-purpule-light {
    border: 1px solid $primary;
    color: $dark-gray;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover,
    &:focus,
    &:active {
      color: $dark-gray;
    }
  }
}

.btn,
.btn:hover,
.btn:active,
.btn:focus {
  color: $white;
}

ul {
  padding: 0;
  list-style-type: none;
  margin: 0;
}

#root {
  height: 100%;
}

.fullscreen {
  // background: #f7f8fa;
  height: 100%;
}

.wrapper {
  background: #f7f8fa;

  // height: 100%;
  .register-outer {
    position: relative;
  }

  .watermark {
    display: none;
  }

  &.watermark-added {
    // background: transparent;

    .watermark {
      display: block;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      background-position: center;
      background-repeat: no-repeat;
      width: 99.5%;
      height: 99%;
      opacity: 0.2;
    }

    .register {
      .custom-card {
        background: transparent;
      }
    }
  }
}

.btn-link {
  &:active,
  &:focus {
    box-shadow: none;
  }

  &:active {
    &:focus {
      box-shadow: none;
    }
  }
}

.btn {
  &.btn-light {
    color: $black;

    &:hover,
    &:focus,
    &:active {
      color: $black;
    }
  }
}

.btn.btn-outline-primary:focus {
  background-color: $blue;
}

.btn-blue:disabled,
.btn-blue.disabled {
  color: #fff;
}

.btn-primary:disabled,
.btn-primary.disabled {
  color: #fff;
}

.form-control {
  padding-top: 0.56rem;
  padding-bottom: 0.56rem;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #1a1a1a;

  &::-webkit-input-placeholder {
    /* Edge */
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $gray-600;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $gray-600;
  }

  &::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $gray-600;
  }
}

body {
  background-color: #f7f8fa;
  // background-image: url("../../assets/images/logo-big.png");
  background-repeat: no-repeat;
  background-position: center;
  font-family: "Roboto", sans-serif;
  color: $blackshade1;
  background-size: 90%;
}

html,
body {
  height: 100%;
}

// iframe {
//   display: none;
// }

// Default scroll styling
.scroll-auto {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
    border: 1px solid $blue;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0;
    background: #eaedf2;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: $blue;
  }
}

// Differnet modal widths
.modal-85w {
  @media screen and (min-width: 1366px) {
    width: 85%;
    max-width: none !important;
  }

  @media screen and (max-width: 1365px) {
    max-width: none !important;
    width: auto;
    margin: 15px;
  }
}

.modal-96w {
  @media screen and (min-width: 1366px) {
    width: 96%;
    max-width: none !important;
  }

  @media screen and (max-width: 1365px) {
    max-width: none !important;
    width: auto;
    margin: 15px;
  }
}

.custom-modal {
  &.modal-dialog {
    &.modal-388w {
      @media screen and (min-width: 577px) {
        width: 388px;
        max-width: none !important;
        margin: auto;
      }

      @media screen and (max-width: 576px) {
        max-width: none !important;
        width: auto;
        margin: 15px;
      }
    }
  }
}

// Loader
.custom-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  line-height: 50px;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999999;

  &:before {
    content: "";
    display: block;
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(rgba(20, 20, 20, 0.5), rgba(0, 0, 0, 0.5));
    background: -webkit-radial-gradient(
      rgba(20, 20, 20, 0.5),
      rgba(0, 0, 0, 0.5)
    );
  }
}

// input field

input {
  &::placeholder {
    font-size: 14px;
  }
}

.date-time {
  input {
    &::placeholder {
      font-size: 14px;
      color: $black !important;
      opacity: 0.5;
      font-weight: 500;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: rgba(219, 227, 233, 0.34);
  cursor: not-allowed;
}

.form-floating {
  position: relative;
  width: 100%;

  .form-control {
    color: $black;
  }

  .form-label {
    opacity: 1;
    border-radius: 5px;
    transition:
      opacity 0.1s ease-in-out,
      transform 0.1s ease-in-out;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0.56rem 0.75rem;
    margin-bottom: 0;
    transform-origin: 0 0;
    max-height: 44px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $gray-600;

    @media screen and (max-width: 1023px) {
      font-size: 14px;
    }
  }

  &.form-field--is-active {
    .form-label {
      transform: scale(0.85) translateY(-0.8rem) translateX(0.15rem);
      opacity: 1;
      background: $white;
      padding: 0 5px;
      color: $dark-gray;
      font-weight: 700;
      left: 5px;
      height: auto;
    }
  }

  &.form-field--is-filled {
    .form-label {
      transform: scale(0.85) translateY(-0.8rem) translateX(0.15rem);
      opacity: 1;
      color: $dark-gray;
      background: $white;
      font-weight: 700;
      padding: 0 5px;
      left: 5px;
      height: auto;
    }
  }

  .text-danger {
    font-size: 0.875rem;
  }

  &.text-area {
    .form-floating {
      input {
        height: 100px;
      }

      label {
        top: 32px;
      }
    }
  }

  .eye-icon {
    position: absolute;
    right: 12px;
    cursor: pointer;
    top: 11px;

    path {
      fill: $blue;
    }
  }

  &.with-label-up {
    input {
      &::placeholder {
        font-size: 14px;
        color: $black !important;
        opacity: 0.5;
      }
    }

    .form-label {
      transform: scale(0.85) translateY(-0.8rem) translateX(0.15rem);
      opacity: 1;
      background: $white;
      left: 5px;
      padding: 0 5px;
      height: auto;
      color: $dark-gray;
    }
  }
}

.form-floating > .form-control:-webkit-autofill {
  padding: 0.56rem 0.75rem;
}

.form-floating > .form-control:-webkit-autofill ~ label {
  transform: scale(0.85) translateY(-0.8rem) translateX(0.15rem);
  opacity: 1;
  background: $white;
  left: 5px;
  padding: 0 5px;
  height: auto;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  transform: scale(0.85) translateY(-0.8rem) translateX(0.15rem);
  opacity: 1;
  background: $white;
  left: 5px;
  padding: 0 5px;
  height: auto;
  font-weight: 700;
  color: $dark-gray;
}

.form-floating > .form-control,
.form-floating > .form-select .form-control:focus {
  height: auto;
  padding: 0.56rem 0.75rem;
}

.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding: 0.56rem 0.75rem;
}

.form-floating > .form-control:focus {
  border-color: $dark-gray;
}

.form-control:focus {
  box-shadow: none;
  border-color: $dark-gray;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.form-select {
  border-color: $blackcustom;
  color: $black;
  font-size: 14px;
  cursor: pointer;

  option {
    line-height: 100px;
  }
}

.has-box-shadow {
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
}

.navbar {
  .secondary-navbar {
    padding: 9px 38px 9px 5px;

    .nav-link {
      padding: 0 15px;
      border-radius: 6px;
      background-color: transparent;
      // color: #1a1a1a !important;
      font-size: 16px;
      margin-right: 10px;
      font-weight: 400;
      opacity: 1;
      border-radius: 0;
      position: relative;

      &:last-child {
        margin-right: 0;
      }

      &:hover,
      &.active {
        &::before {
          opacity: 1;
        }
      }

      &:active {
        color: black;

        &::before {
          opacity: 1;
        }
      }

      &::before {
        content: "";
        position: absolute;
        left: 15px;
        bottom: -5px;
        width: calc(100% - 30px);
        height: 3px;
        background: $blueshade2;
        opacity: 0;
        transition: all 0.3s;
      }
    }
  }

  .primary-navbar {
    .d-flex + a {
      border-left: 1px solid $blackcustom;
    }

    .nav-link {
      padding: 0 14px;
      height: 55px;
      border-radius: 0;
      border-right: 1px solid $blackcustom;
      color: $blueshade2;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;

      &:last-child {
        border-right: none;
      }

      &.expand-window {
        min-width: 57px;
        background-color: $blackshade1;
        width: auto;

        img {
          margin-right: 0;
        }
      }

      img {
        margin-right: 10px;
        // @media screen and (max-width: 1919px) {
        //   margin-right: 5px;
        //   width: 15px;
        // }
      }
    }

    .custom-dropdown {
      position: absolute;
      right: 10px;
      top: 0;

      @media screen and (max-width: 1279px) {
        right: 8px;
        top: 5px;
      }

      .dropdown-menu {
        right: 0 !important;
        left: auto;
        border-radius: 2px;

        .dropdown-item {
          color: #1a1a1a;
          font-size: 16px;
          font-weight: 400;
        }

        .dropdown-divider {
          border: 1px solid $blackcustom;
        }
      }

      .btn-primary {
        background: transparent;
        display: flex;
        flex-direction: column;
        color: $blueshade2;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        align-items: center;
        border: none;
        box-shadow: none;

        &:hover,
        &:active,
        &:focus {
          outline: none;
          box-shadow: none;
        }

        span {
          margin-top: 4px;

          @media screen and (max-width: 1279px) {
            display: none;
          }
        }
      }
    }
  }
}

// content
.content {
  margin-top: 15px;
  padding-bottom: 55px;
  // @media screen and (max-width: 1919px) {
  //   padding-left: 0.5rem;
  //   padding-right: 0.5rem;
  // }
}

// register
.register {
  position: relative;
  min-height: auto;

  .items-contain {
    position: relative;

    @media screen and (max-height: 414px) {
      height: 500px;
    }

    .rcs-custom-scroll .rcs-custom-scrollbar {
      top: 39px;
    }

    .rcs-custom-scroll .rcs-inner-handle {
      height: calc(100% - 45px);
    }

    tbody {
      @media screen and (max-width: 991px) {
        padding-bottom: 48px;
      }

      @media screen and (max-width: 767px) {
        padding-bottom: 0;
      }
    }
  }
}

// minimum-price-indicator modal
.minimum-price-indicator {
  text-align: left;

  .list-group {
    list-style-type: disc;

    .list-group-item {
      line-height: normal;
      font-weight: 500;
      position: relative;
      margin-bottom: 10px;
      border-radius: 6px;
      transition: all 0.3s ease;

      // &:before {
      //   content: "";
      //   position: absolute;
      //   top: 14px;
      //   left: -2px;
      //   width: 10px;
      //   height: 10px;
      //   background-color: $black;
      //   border-radius: 100%;
      // }
      &:hover {
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.4);
      }
    }
  }

  p {
    margin-top: 0.5rem;
    font-weight: 500;
    line-height: normal;
  }
}

.input-group-merge {
  > .btn {
    position: absolute;
    right: 0;
    border-radius: 0px 5px 5px 0px !important;
    width: 34px;
    padding: 0;
    z-index: 4;
    height: 100%;
  }
}

.top-search {
  // position: absolute;
  // right: 0;
  // top: -54px;
  // .navbar-search {
  //     overflow: hidden;
  // }
  .search-bar {
    .btn-seagreen {
      position: absolute;
      right: 0;
      width: 85px;
      height: 39px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 5px 5px 0 !important;
      z-index: 4;
    }
  }

  .add-btn {
    width: 275px;
    border: 1px solid $primary;
    color: $dark-gray;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 767px) {
      width: 100%;
      justify-content: center;
    }
  }
  .camera {
    margin-left: 10px;
    path {
      fill: #3965d8;
    }
  }

  .form-control {
    border-radius: 6px !important;
    border: none;
    padding-left: 14px !important;

    &::-webkit-input-placeholder {
      /* Edge */
      font-size: 14px;
      font-weight: 500;
      color: $black;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      font-size: 14px;
      font-weight: 500;
      color: $black;
    }

    &::placeholder {
      font-size: 14px;
      font-weight: 500;
      color: $black;
    }
  }

  .stock-code-search {
    width: auto;
    margin-right: 10px;

    @media screen and (max-width: 576px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 10px !important;
      margin-top: 20px;
    }
    .clear{
      margin-left: 10px;
      img{
        width: 15px;
      }
    }
  }

  .stock-search {
    width: 150%;

    &.react-auto-complete {
      > div {
        width: 100%;
      }

      .list-group {
        &:first-child {
          border-radius: 6px 6px 0 0;
        }

        &:last-child {
          border-radius: 0 0 6px 6px;
        }
      }

      .list-group-item {
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        cursor: pointer;
        border-radius: 0;
        cursor: pointer;

        &:hover {
          transition: all 0.3s;
          background: $primary;
          color: #fff;
        }

        &.focused {
          background: $primary;

          span {
            color: #fff;
          }
        }

        span {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: $black;

          &:first-child {
            width: 40%;
          }

          &:nth-child(2) {
            width: 30%;
          }

          &:nth-child(3) {
            width: 15%;
          }

          &:last-child {
            width: 15%;
          }
        }
      }
    }

    input {
      border: none;
      outline: none;
      padding: 0.6rem 0.75rem;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5;
      background-color: $white;
      background-clip: padding-box;
      border: 1px solid $blackcustom;
      border-radius: 6px;
      width: 100%;
      transition: width 0.2s ease-in-out;

      &::-webkit-input-placeholder {
        /* Edge */
        font-weight: 400;
        font-size: 16px;
        color: $gray-600;
        line-height: 19px;
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        font-weight: 400;
        font-size: 16px;
        color: $gray-600;
        line-height: 19px;
      }

      &::placeholder {
        font-weight: 400;
        font-size: 16px;
        color: $gray-600;
        line-height: 19px;
      }

      + div {
        z-index: 11;
        background: #fff !important;
        border-radius: 6px !important;
        padding: 0 !important;
        margin-top: 0;
        min-width: 500px !important;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.12);
        // div {
        //   cursor: pointer;
        //   &:first-child {
        //     border-radius: 6px 6px 0 0;
        //   }
        //   &:last-child {
        //     border-radius: 0 0 6px 6px;
        //   }
        // }
      }
    }
  }
}

// Data table
.custom-card {
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  background-color: #fff;
  border: none;
  //   @media screen and (max-width: 767px) {
  // margin-bottom: 10px;
  //   }

  .card-header {
    padding: 0.6rem 0.85rem;
    border-radius: 6px 6px 0 0;
    border-bottom-color: $blackcustom;

    h2 {
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      color: $dark-gray;
      margin-bottom: 0;
    }
  }

  .card-body {
    padding-top: 0;

    .fields {
      list-style-type: none;
      padding-left: 0;
      border: 1px solid rgba(112, 112, 112, 0.18);
      border-radius: 4px;
      margin-bottom: 0;

      li {
        padding-left: 28px;
        padding-right: 15px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        height: 51px;

        @media screen and (max-width: 767px) {
          padding-left: 10px;
          padding-right: 10px;
        }

        &.sales {
          height: 64px;

          p {
            @media screen and (max-width: 575px) {
              font-size: 12px;
            }
          }
        }

        &.header {
          background-color: $gray-200;
          height: 50px;

          .left-column,
          .right-column {
            font-weight: bold;
            color: $black;
            font-size: 14px;
          }
        }

        .left-column {
          flex: 0 0 auto;
          width: 41.66667%;
          height: 100%;
          display: flex;
          font-size: 14px;
          align-items: center;
          border-right: 1px solid rgba(112, 112, 112, 0.18);
          color: $black;
        }

        .right-column {
          flex: 0 0 auto;
          width: 58.33333%;
          padding-left: 20px;

          @media screen and (max-width: 767px) {
            padding-left: 10px;
          }

          .form-check-label {
            @media screen and (max-width: 575px) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  .card-footer {
    justify-content: flex-end;
    display: flex;

    .btn {
      position: relative;
      right: 0;
    }

    &.adjust-btn {
      .btn {
        position: relative;
        right: 130px;
      }

      &.with-slide {
        .btn {
          position: relative;
          right: 150px;
        }
      }
    }
  }

  .card-body,
  .card-footer {
    padding-left: 0;
    padding-right: 0;
    border-top: none;
  }

  .search-label {
    > span {
      display: none;
    }
  }

  .search-bar {
    position: relative;

    @media screen and (max-width: 767px) {
      width: 100%;
    }

    .form-control {
      color: $black;
      border: 1px solid $blackcustom;

      @media screen and (max-width: 767px) {
        width: 100%;
      }

      &::-webkit-input-placeholder {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: $gray-600;
      }

      &:-ms-input-placeholder {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: $gray-600;
      }

      &::placeholder {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: $gray-600;
      }
    }

    .clear {
      position: absolute;
      right: 0;
      top: 1px;
      // background-color: $white;
      background-image: url("../../assets/images/cross-blue.svg");
      background-repeat: no-repeat;
      right: 4px;

      height: 36px;
      background-position: center;
      text-indent: -9999px;

      &:hover,
      &:active,
      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
  }

  .react-bootstrap-table {
    input[type="checkbox"] {
      width: 1.125em;
      height: 1.125em;
      margin-top: 0.1875em;
      vertical-align: top;
      background-color: $white;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      border: 1px solid $blackcustom;
      -webkit-appearance: none;
      appearance: none;
      -webkit-print-color-adjust: exact;
      color-adjust: exact;
      border-radius: 0.25em;
      transition:
        background-color 0.2s ease-in-out,
        background-position 0.2s ease-in-out,
        border-color 0.2s ease-in-out,
        box-shadow 0.2s ease-in-out;

      &:checked {
        background-color: $blue;
        border-color: $blue;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
      }
    }

    .table-header {
      border-bottom: none;

      // border-top: none;
      th {
        cursor: pointer;
        font-weight: 700;
        background-color: $gray-200;

        input[type="checkbox"] {
          width: 16.75px;
          height: 16.75px;
        }

        &:first-child {
          border-left: none;
        }

        &:last-child {
          border-right: none;
        }

        &:hover {
          .order,
          .order-4 {
            background: url("../../assets/images/sort-down.svg") no-repeat
              center / cover;
            -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
            width: 14px;
            height: 14px;
            position: absolute;
            top: 50%;
            margin-top: -8px;
            right: 3px;
          }
        }
      }

      .caret-4-desc,
      .react-bootstrap-table-sort-order {
        position: absolute;
        background: url("../../assets/images/sort-down.svg") no-repeat center /
          cover;
        width: 14px;
        height: 14px;
        -webkit-transition: 0.3s linear all;
        transition: 0.3s linear all;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        // top: 14px;
        top: 50%;
        margin-top: -8px;
        right: 3px;

        &:before {
          font-size: 0;
          margin-left: 1px;
        }

        &:after {
          font-size: 0;
        }
      }

      .caret-4-asc,
      .react-bootstrap-table-sort-order.dropup {
        position: absolute;
        // top: 14px;
        top: 50%;
        margin-top: -8px;
        background: url("../../assets/images/sort-down.svg") no-repeat center /
          cover;
        width: 14px;
        height: 14px;
        -webkit-transition: 0.3s linear all;
        transition: 0.3s linear all;
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        right: 0;

        &:before {
          font-size: 0;
          margin-left: 1px;
        }

        &:after {
          font-size: 0;
        }
      }
    }

    thead,
    tbody,
    tfoot,
    tr,
    td,
    th {
      // border-color: rgba(112, 112, 112, 0.18);
      color: $black;
    }

    td:not(:last-child) {
      border-right: none;
    }
  }

  .table tbody {
    @media screen and (max-width: 1365px) {
      max-height: calc(100vh - 440px) !important;
    }

    @media screen and (max-width: 767px) {
      max-height: none !important;
    }
  }

  .react-bootstrap-table-pagination,
  .pagination {
    margin-top: 27px;
    margin-left: 0;
    margin-right: 0;

    @media screen and (max-width: 767px) {
      margin-top: 0;
    }
  }

  .pagination-wrapper .react-bootstrap-table-pagination-total {
    @media screen and (max-width: 991px) {
      display: none;
    }

    @media screen and (max-width: 767px) {
      display: block;
    }
  }

  // &.mh-auto {
  //   min-height: 33vh;
  // }
  &.pagination-absolute {
    padding-bottom: 61px;

    @media screen and (max-width: 767px) {
      padding-bottom: 110px;
    }

    // .pagination-wrapper {
    //   height: calc(100vh - 360px);

    //   @media screen and (max-width: 1279px) {
    //     height: calc(100vh - 382px);
    //   }

    //   @media screen and (max-width: 767px) {
    //     height: calc(100vh - 133px);
    //   }

    //   @media screen and (max-height: 414px) {
    //     height: calc(100vh - 133px);
    //   }

    // }
  }
}

// .custom-data-table .card.without-scroll .table tbody {
//   width: auto;
//   padding-right: 0;
// }

// Defaukt custom data table
.custom-data-table {
  &.below-description {
    .card .table tbody tr {
      border-bottom: none;
    }
  }

  &.mh-auto {
    min-height: 33vh;
  }

  thead {
    tr {
      border-top: none;

      th {
        border-bottom: none;

        &:first-child {
          width: 3.5%;
          cursor: pointer;
        }

        &:nth-child(2) {
          width: 5%;
        }

        &:last-child {
          text-align: center;
          pointer-events: none;
        }
      }
    }
  }

  .card {
    .table {
      th {
        padding-left: 0.5rem;
        padding-right: 20px;
        white-space: normal;
        position: relative;
        text-transform: capitalize;
        font-weight: 700;
        font-size: 14px;
        color: $dark-gray;
        // left: 0.3px;

        @media screen and (max-width: 1919px) {
          letter-spacing: -0.4px;
        }

        @media screen and (max-width: 1679px) {
          padding-left: 0.4rem;
        }
      }

      tbody {
        overflow: auto;
        max-height: 500px;

        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-track {
          border-radius: 0;
          border: 4px solid #fff;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 4px;
          border: 4px solid #000;
        }

        td {
          padding: 0.6rem 0.5rem;
          white-space: normal;
          position: relative;
          vertical-align: middle;

          &.form-field--is-active {
            border: 2px solid #dd5a43;
          }

          &.form-field--is-filled.form-field--is-active {
            border: 2px solid #dd5a43;
          }

          @media screen and (max-width: 1679px) {
            padding-left: 0.4rem;
            padding-right: 0.4rem;
          }

          &:first-child {
            cursor: pointer;
            border-left: none;
          }

          &:last-child {
            border-right: none;
          }

          &.focused {
            outline: 2px solid $primary;
          }

          &.reset-expansion-style {
            padding: 0;
          }

          sup {
            top: 50%;
            right: 6px;
            font-size: initial;
            position: absolute;
          }
        }
      }
    }
  }

  tbody {
    tr {
      @media screen and (max-width: 767px) {
        width: 100%;
        padding: 15px 0;
      }

      &:last-child {
        @media screen and (max-width: 767px) {
          padding-bottom: 5px;
        }

        @media screen and (max-width: 767px) {
          margin-bottom: 0;
        }
      }

      td {
        font-weight: bold;
        font-size: 14px;
        word-break: break-all;

        @media screen and (max-width: 1919px) {
          letter-spacing: -0.4px;
        }

        @media screen and (max-width: 1479px) {
          letter-spacing: -0.4px;
        }

        // &:first-child {
        //   // width: 3.5%;

        //   @media screen and (max-width: 767px) {
        //     top: 0;
        //     right: 10px;
        //   }
        // }

        // &:nth-child(2) {
        //   // width: 5%;
        // }

        &:last-child {
          // display: flex;
          // justify-content: space-evenly;
          // align-items: center;

          &.react-bs-table-no-data {
            width: 100% !important;
            padding: 90px 0;
            height: auto;
            color: $black;
            font-size: 1rem;
            font-weight: bold;
            position: relative;
            cursor: auto !important;
            display: table-cell;

            @media screen and (max-width: 767px) {
              padding-left: 0 !important;
              height: 140px;
              justify-content: center !important;
              right: 0;
            }
          }
        }

        .btn {
          margin-right: 0.5rem;
          display: flex;
          align-items: flex-start;

          &:active,
          &:focus {
            box-shadow: none;
          }

          &:last-child {
            margin-right: 0;
          }

          @media screen and (max-width: 1979px) {
            margin-right: 0.4rem;
          }

          @media screen and (max-width: 1679px) {
            margin-right: 0.2rem;
          }

          @media screen and (max-width: 1479px) {
            margin-right: 0.5rem;
          }

          img {
            transition: all 0.3s;

            &:hover {
              transform: scale(1.2);
            }
          }

          svg {
            transition: all 0.3s;

            &:hover {
              transform: scale(1.2);
            }
          }
        }
      }
    }
  }

  .badge {
    border-radius: 20px;
    font-size: 14px;
    line-height: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    padding: 4.5px 10px;
    min-width: 85px;
  }

  .with-scroll-on-table {
    .react-bootstrap-table {
      .rcs-custom-scroll .rcs-inner-container:after {
        @media screen and (max-width: 991px) {
          background-image: none;
        }
      }
    }

    .table {
      thead {
        position: sticky;
        top: 0;
        z-index: 1;
      }

      tbody {
        overflow: auto;
        height: auto !important;
        max-height: none !important;
      }
    }
  }
}

// column-visibility-table and expand-table-row
.custom-data-table {
  .column-visibility-table {
    .items-contain {
      tbody tr td .dtr-data {
        width: 100%;
      }
    }

    tr {
      th {
        &:last-child {
          width: 80px;
        }
      }
    }

    tbody {
      tr {
        td {
          &:last-child {
            &.react-bs-table-no-data {
              text-align: center;
            }
          }

          .form-control {
            // max-width: 110px;
            font-weight: bold;

            @media screen and (max-width: 1279px) {
              max-width: none;
              text-align: left !important;
            }
          }

          .badge {
            width: auto;
            padding: 4.5px 10px;
            border-radius: 20px;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
          }
        }
      }
    }

    &.card {
      .table {
        .expand-row-icon {
          min-width: 16px;
          height: 16px;
        }

        th {
          left: 0;
          vertical-align: middle;

          &.selection-cell-header,
          &.expand-cell-header {
            text-align: center;
            padding: 0.6rem 0.5rem;
            width: 47px;
            min-width: 47px;
          }

          &.expand-cell-header {
            width: 27px;
            padding-right: 0;
          }
        }

        tbody {
          td {
            &.selection-cell,
            &.expand-cell {
              text-align: center;
              width: 47px;
              min-width: 47px;
              z-index: 0;
            }

            &.expand-cell {
              width: 27px;
              padding-right: 0;
            }
          }
        }
      }
    }

    &.items-table {
      &.card .table thead th {
        letter-spacing: -0.3px;
      }

      &.card .table thead th {
        &[aria-label="Stockcode sortable"] {
          @media screen and (min-width: 960px) {
            max-width: 140px;
          }
        }
      }

      &.card .table tbody td {
        &[data-title="Stockcode"] {
          @media screen and (min-width: 960px) {
            max-width: 140px;
          }
        }
      }
    }
  }

  // // lookup table
  // .lookup-table {
  //   &.custom-card .react-bootstrap-table .table-header th:first-child {
  //     text-align: left;
  //   }

  //   .add-btn {
  //     @media screen and (max-width: 576px) {
  //       position: absolute;
  //       top: 14px;
  //       right: 14px;
  //     }
  //   }
  // }

  // lookup table ends here
  .expand-table-row {
    tbody {
      tr {
        td {
          &.reset-expansion-style {
            background-color: #f6f8fa;
            padding: 0;
            // width: 100%;
            width: auto;
            display: table-cell;

            .row-expansion-style {
              padding: 15px;
              padding-left: 65px;

              ul {
                li {
                  display: flex;
                  font-size: 14px;
                  color: #1a1a1a;

                  .dtr-title {
                    font-weight: 700;
                    min-width: 150px;
                    text-align: left;
                  }

                  .dtr-data {
                    font-weight: 500;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .column-visibility-table {
    &.card {
      .expand-table-row {
        thead {
          position: relative;
        }

        tbody {
          tr {
            td {
              &.reset-expansion-style {
                padding: 0;
                width: auto;

                .row-expansion-style {
                  padding: 15px;
                }
              }
            }
          }
        }
      }
    }
  }

  // Auto aidth for all column in column visibility table
  .auto-width {
    .table {
      th {
        &:first-child {
          width: auto;
        }

        &:nth-child(2) {
          width: auto;
        }

        &:last-child {
          width: auto;
        }
      }

      tbody tr td .btn {
        display: inline-block;
        width: 60%;
        padding: 0.2rem 0.25rem;
      }

      tbody tr td:last-child {
        text-align: center;
      }
    }
  }
}

// two-column
.two-column {
  thead {
    tr {
      th {
        &:first-child {
          width: 10%;
        }

        &:nth-child(2) {
          width: 75%;
        }

        &:last-child {
          width: 15%;
          text-align: center;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        &:first-child {
          width: 10%;
        }

        &:nth-child(2) {
          width: 75%;
        }

        &:last-child {
          width: 15%;

          @media screen and (min-width: 1365px) {
            justify-content: center;
          }
        }
      }
    }
  }

  .pagination-wrapper .react-bootstrap-table-pagination div:first-child {
    top: -66px !important;
  }
}

// Table styling without checkbox
.custom-data-table {
  .card {
    &.custom-card {
      min-height: auto;
      height: 100%;
    }
  }
}

// pagination
.pagination-wrapper {
  position: relative;

  .react-bootstrap-table-pagination {
    @media screen and (max-width: 767px) {
      display: flex;
      align-items: center;
      flex-direction: column;
      position: absolute;
      left: 0;
      bottom: 0;
      bottom: -101px;
      justify-content: center;
      width: 100%;
    }

    div.react-bootstrap-table-pagination-list-hidden {
      display: none;
      visibility: hidden;
    }

    .react-bootstrap-table-pagination-list {
      flex: 0 0 auto;
      width: 100%;

      @media screen and (min-width: 768px) {
        position: absolute;
        bottom: -48px;
        z-index: 1;
        top: auto;
      }

      // div:first-child {
      //   @media screen and (min-width: 768px) {
      //     position: absolute;
      //     padding-right: 6px;
      //     top: -5px;
      //     z-index: 1;
      //     width: 50%;
      //     right: 0;
      //   }
      // }
    }

    > div {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;

      @media screen and (max-width: 767px) {
        justify-content: center;
      }

      &:first-child {
        @media screen and (min-width: 768px) {
          position: absolute;
          right: 0;
          width: auto;
          top: -55px;
        }
      }

      &:last-child {
        @media screen and (max-width: 767px) {
          margin-top: 10px;
        }
      }
    }
  }

  .react-bootstrap-table-pagination-total {
    margin-right: 14px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    color: $dark-gray;
  }

  .dropdown {
    .btn {
      border-radius: 6px;
      border: solid 1px $blackcustom;
      background-color: $white;
      width: 67px;
      color: rgba(0, 0, 0, 0.6);
      font-size: 14px;
      font-weight: 500;
      margin-right: 7px;
      background-image: url("../../assets/images/dropdown.svg");
      background-repeat: no-repeat;
      background-position: 81% center;
      text-align: left;
      padding-left: 13px;
      justify-content: flex-start;

      &:focus {
        box-shadow: none;
      }
    }
  }

  .dropdown-menu {
    top: 42px;
    min-width: 6rem;
    padding: 0;
    left: auto;
    right: 0;

    .dropdown-item {
      padding: 0;

      &:first-child {
        a {
          border-radius: 0.5rem 0.5rem 0 0;
        }
      }

      &:last-child {
        a {
          border-radius: 0 0 0.5rem 0.5rem;
        }
      }
    }

    a {
      font-size: 0.875rem;
      font-weight: 500;
      padding: 0.5rem 1rem;
      color: #262b40;
      display: flex;

      &:hover {
        background: transparent;
      }
    }
  }
}

.pagination {
  .page-link {
    font-size: 14px;
  }
}

.pagination-wrapper .dropdown-menu a.dropdown-item {
  padding: 0.5rem 1rem;
}

.custom-card .search-bar .clear {
  background-image: url("../../assets/images/cross-blue.svg");
  background-repeat: no-repeat;
  right: 4px;
  top: 2px;
  height: 36px;
  background-position: center;
  text-indent: -9999px;

  &:hover,
  &:active,
  &:focus {
    box-shadow: none;
    outline: none;
  }
}

// Itmes contain
// .custom-data-table .card .items-contain .table tbody {
//   height: calc(100vh - 399px);
//   max-height: calc(100vh - 399px);
// }
.custom-data-table .card .items-contain .table th {
  padding-right: 0.5rem;
}

.custom-data-table .card .items-contain .table .table-header {
  border-top: none;
}

.custom-data-table .custom-card.no-box-shadow {
  box-shadow: none;
}

.items-contain {
  height: calc(100vh - 371px);

  @media screen and (max-width: 767px) {
    height: calc(100vh - 356px);
  }

  thead {
    tr {
      th {
        &:first-child {
          width: 7%;

          @media screen and (max-width: 767px) {
            width: auto;
          }
        }

        &:nth-child(2) {
          width: 35%;

          @media screen and (max-width: 767px) {
            width: auto;
          }
        }

        &:nth-child(3) {
          width: 12%;

          @media screen and (max-width: 767px) {
            width: auto;
          }
        }

        &:nth-child(4) {
          width: 13%;

          @media screen and (max-width: 767px) {
            width: auto;
          }
        }

        &:nth-child(5) {
          width: 10%;

          @media screen and (max-width: 767px) {
            width: auto;
          }
        }

        &:nth-child(6) {
          width: 8%;
          text-align: center;

          @media screen and (max-width: 767px) {
            width: auto;
          }
        }

        &:last-child {
          width: 15%;

          @media screen and (max-width: 767px) {
            width: auto;
          }
        }
      }
    }
  }

  tbody {
    // border-bottom: 1px solid rgba(112, 112, 112, 0.18);
    tr {
      td {
        // border-bottom: none;
        cursor: pointer;

        &:first-child {
          width: 7%;
          .form-control {
            padding-left: 0.25rem;
            padding-right: 0.25rem;
          }
          @media screen and (max-width: 767px) {
            width: auto;
          }
        }

        &:nth-child(2) {
          width: 35%;

          @media screen and (max-width: 767px) {
            width: 15%;
            .form-control {
              padding-left: 0.25rem;
              padding-right: 0.25rem;
            }
          }

          .custom-numberpad {
            @media screen and (max-width: 767px) {
              transform: translateX(-16%);
            }
          }
        }

        &:nth-child(3) {
          width: 12%;

          @media screen and (max-width: 767px) {
            width: auto;
          }

          input {
            width: 100%;
            height: 34px;
            border-radius: 6px;
            border: solid 1px #c5c5c5;
            background: #fff;
            padding-left: 10px;
            cursor: pointer;
          }
        }

        &:nth-child(4) {
          width: 13%;

          @media screen and (max-width: 767px) {
            width: auto;
          }
        }

        &:nth-child(5) {
          width: 10%;

          @media screen and (max-width: 767px) {
            width: auto;
          }
        }

        &:nth-child(6) {
          width: 8%;

          @media screen and (max-width: 767px) {
            width: auto;
          }

          .btn {
            margin: auto !important;
          }
        }

        &:last-child {
          width: 15%;

          @media screen and (max-width: 767px) {
            width: auto;
          }

          > div {
            justify-content: space-evenly;
          }
        }
      }

      &:first-child {
        .numpad-container {
          .custom-numberpad {
            // top: 97px;
            bottom: auto;
            top: auto;
            position: fixed;
          }
        }
      }

      &:nth-child(2) {
        .numpad-container {
          .custom-numberpad {
            // top: 147px;
            bottom: auto;
            top: auto;
            position: fixed;
          }
        }
      }

      &:nth-child(3) {
        .numpad-container {
          .custom-numberpad {
            // top: 197px;
            bottom: auto;
            top: auto;
            position: fixed;
          }
        }
      }

      &:nth-child(4) {
        .numpad-container {
          .custom-numberpad {
            // top: 247px;
            bottom: auto;
            top: auto;
            position: fixed;
          }
        }
      }

      &:nth-child(5) {
        .numpad-container {
          .custom-numberpad {
            // top: 297px;
            bottom: auto;
            top: auto;
            position: fixed;
          }
        }
      }

      &:nth-child(6) {
        .numpad-container {
          .custom-numberpad {
            // top: 347px;
            bottom: auto;
            top: auto;
            position: fixed;
          }
        }
      }

      &:nth-child(7) {
        .numpad-container {
          .custom-numberpad {
            // top: 397px;
            bottom: auto;
            top: auto;
            position: fixed;
          }
        }
      }

      &:nth-child(8) {
        .numpad-container {
          .custom-numberpad {
            // top: 447px;
            bottom: auto;
            top: auto;
            position: fixed;
          }
        }
      }
    }
  }
}

// NUm pad custom style
.numpad-container {
  position: relative;

  .custom-numberpad {
    // top: 502px;
    // .cta-btn .btn {
    //   padding: 7.5px 0;
    // }
    // .MuiPaper-root .MuiButton-root {
    //   padding: 7px 0 !important;
    // }
    bottom: 36px;
    top: auto;
  }
}

.custom-numberpad {
  font-size: 1em;
  z-index: 111;
  position: absolute;
  // top: 98px;
  bottom: auto;

  .ripple-button {
    border-radius: 4px;
    border: none;
    margin: 8px;
    padding: 14px 24px;
    background: #1976d2;
    color: #fff;
    overflow: hidden;
    position: relative;
    cursor: pointer;
  }

  .ripple-button > .ripple {
    width: 20px;
    height: 20px;
    position: absolute;
    background: #63a4ff;
    display: block;
    content: "";
    border-radius: 9999px;
    opacity: 1;
    animation: 0.9s ease 1 forwards ripple-effect;
  }

  @keyframes ripple-effect {
    0% {
      transform: scale(1);
      opacity: 1;
    }

    50% {
      transform: scale(10);
      opacity: 0.375;
    }

    100% {
      transform: scale(35);
      opacity: 0;
    }
  }

  .ripple-button > .content {
    position: relative;
    z-index: 2;
  }

  .jeiWCh {
    display: flex;
    flex-wrap: wrap;
    -webkit-box-flex: 1;
    flex-grow: 1;
    background: transparent;
    justify-content: space-between;
  }

  .MuiButton-label {
    width: 100%;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
  }

  .MuiPaper-root {
    &.MuiPaper-elevation1 {
      padding: 15px;
      box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.29);
      background-color: #e9f0f8;
      // width: 294px;
      border-radius: 6px;
      display: flex;
      justify-content: space-between;
      // width: 420px;
      width: 336px;
    }

    .MuiButton-root {
      padding: 8px 0 !important;
      box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.28);
      border-radius: 6px !important;
      background-color: #fff;
      margin: 5px 0;
      width: 29.3% !important;
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      color: $black;
      border: none;
    }

    .jeiWCh {
      background: transparent;
      justify-content: space-between;
    }

    .qlSAn {
      background: #0daeeb;
    }

    .bbaovq {
      svg {
        path {
          fill: $blueshade2;
        }
      }
    }
  }

  .cta-btn {
    display: flex;
    flex-direction: column;
    margin-left: 20px;

    .btn {
      margin: 5px 0;
      width: 87px;
      font-size: 22px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.28);
      border-radius: 6px;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;

      &.cancel {
        background: $grey-custom-shade;
        border: 1px solid $blueshade2;
        color: $blueshade2;
      }

      &.clear {
        background: #d5efe5;
        color: #1a1a1a;
        border: 1px solid #049e64;
      }
    }
  }

  .bottom-cta {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .btn {
      margin: 5px 0;
      width: 48%;
      font-size: 22px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    }
  }

  .arrow-wrapper {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background: #fff;
    border-radius: 0.3rem;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 5px;

    .btn {
      box-shadow: none;
      width: 50%;
      height: 40px;
      border: none;
      border-radius: 0;
      margin: 0;
      border-radius: 0 6px 6px 0;

      &:first-child {
        border-right: 1px solid $blackcustom;
        border-radius: 6px 0 0 6px;
      }
    }
  }
}

// Optional modal sizing as its not in the volt theme
.modal-60w {
  @media screen and (min-width: 1134px) {
    width: 1133px;
    max-width: none !important;
  }

  @media screen and (max-width: 1133px) {
    max-width: none !important;
    width: auto;
    margin: 15px;
  }

  &.item-popup {
    @media screen and (min-width: 1134px) {
      width: 923px;
      max-width: none !important;
    }
  }
}

.modal-50w {
  @media screen and (min-width: 1134px) {
    width: 881px;
    max-width: none !important;
  }

  @media screen and (max-width: 1133px) {
    max-width: none !important;
    width: auto;
    margin: 15px;
  }
}

// custom modal
.custom-modal {
  &.with-border-bottom {
    .modal-footer {
      border-top: 1px solid $blackcustom;
      padding-top: 1rem !important;
    }

    .form-cta-btn {
      bottom: -70px;

      @media screen and (max-width: 767px) {
        bottom: -66px;
      }
    }
  }

  &.custom-data-table {
    min-height: calc(100% - 3.5rem);
    height: auto;
  }

  .btn-close {
    position: absolute;
    right: 16px;
    background: url("../../assets/images/cross-black.svg") no-repeat center /
      1rem;
    opacity: 1;
    top: 19px;

    &:active {
      box-shadow: none;
    }
  }

  &.modal-dialog {
    @media screen and (max-width: 991px) {
      width: auto;
      max-width: none !important;
      margin: 15px;
    }
  }

  .modal-header {
    padding: 16px 20px;
    justify-content: flex-start !important;
    border-color: $blackcustom;
  }

  .modal-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: $dark-gray;
  }

  .modal-body {
    padding-left: 23px;
    padding-right: 23px;

    @media screen and (max-width: 1023px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .modal-footer {
    border-top: none;

    // @media screen and (max-width: 576px) {
    //   justify-content: flex-start;
    // }
    .btn {
      margin-right: 162px;
      min-width: 147px;
      height: 49px;

      @media screen and (max-width: 767px) {
        margin: 0;
      }

      &.btn-outline {
        border: 1px solid $blackcustom;
        color: $dark-gray;

        &:hover,
        &:focus,
        &:active {
          outline: none;
          box-shadow: none;
        }
      }
    }
  }

  .form-cta-btn {
    right: 15px;
    bottom: -54px;
    min-width: 147px;
    height: 49px;

    @media screen and (max-width: 767px) {
      right: auto;
      left: 13px;
    }
  }

  .fa-exclamation-circle {
    height: 80px;
    width: 80px !important;

    path {
      fill: $yellow;
    }
  }

  .react-bootstrap-table {
    th {
      padding-left: 0.875em;
      position: relative;

      &:last-child {
        text-align: center;
      }
    }

    td {
      &:last-child {
        // display: flex;
        // justify-content: center;

        // @media screen and (max-width: 1365px) {
        //   justify-content: flex-start;
        // }

        .btn {
          display: flex;

          &:active,
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }

  .pagination-wrapper .react-bootstrap-table-pagination div:first-child {
    top: -60px;
  }

  &.without-save-btn {
    .modal-footer {
      .btn {
        margin: 0.25rem;
        min-width: 147px;
      }
    }
  }

  .custom-data-table {
    min-height: auto;
    height: auto;
  }

  .pagination-wrapper {
    .react-bootstrap-table-pagination {
      .react-bootstrap-table-pagination-list {
        // bottom: -58px;
        bottom: 0;
      }
    }
  }

  .with-scroll-on-table {
    .pagination-wrapper {
      height: 360px;
      padding-bottom: 50px;

      @media screen and (max-width: 767px) {
        padding-bottom: 100px;
        height: 430px;
      }
    }

    .react-bootstrap-table-pagination {
      @media screen and (max-width: 767px) {
        bottom: 0;
      }
    }
  }
}

.custom-card {
  position: relative;

  .form-cta-btn {
    right: 15px;
    bottom: 36px;
  }
}

.form-cta-btn {
  right: 15px;
  bottom: -50px;
}

// 2 modals overlay stackup each other

.modal:before {
  content: "";
  position: fixed;
  left: 50%;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: $black;
  opacity: 0.5;
  transform: translateX(-50%);
}

// task-list-details

.task-list-details {
  height: calc(100vh - 281px) !important;

  .px-3 {
    padding-left: 0 !important;
  }

  .table-bordered > :not(caption) > * > * {
    border-width: 0 0 0 0.0313rem;
  }

  thead {
    tr {
      th {
        &:first-child {
          width: 30%;
        }

        &:nth-child(2) {
          width: 37%;
        }

        &:nth-child(3) {
          width: 20%;
        }

        &:last-child {
          width: 13%;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        &:first-child {
          width: 30%;
        }

        &:nth-child(2) {
          width: 37%;
        }

        &:nth-child(3) {
          width: 20%;
        }

        &:last-child {
          width: 13%;
        }
      }
    }
  }
}

// Form elemnts inside the table

.react-bootstrap-table {
  .form-control {
    padding-top: 0.46rem;
    padding-bottom: 0.46rem;
    height: 35px;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #1a1a1a !important;
    font-weight: 400 !important;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* Remove default arrow */
    background-image: url("../../assets/images/dropdown.svg");
    /* Add custom arrow */
    background-repeat: no-repeat;
    background-position: 95% center;
    color: $black !important;
    padding-right: 15px !important;
    padding-left: 10px !important;
    font-size: 12px !important;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: 6px;
    height: 35px;
    border: 1px solid $blackcustom;
  }
}

.react-bootstrap-table th .caret-4-desc:before {
  margin-left: 3.5px;
  content: "\2191";
  opacity: 0.4;
}

.react-bootstrap-table th .caret-4-asc:before {
  margin-left: 3.5px;
  content: "\2191";
}

.react-bootstrap-table th .caret-4-asc:after {
  content: "\2193";
  opacity: 0.4;
}

.react-bootstrap-table th .caret-4-desc:after {
  content: "\2193";
}

.form-check-input {
  background-color: $white;
  cursor: pointer;
}

// form-check-label
.form-check-label {
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}

textarea {
  resize: none;
}

// total-info
.total-info {
  border-radius: 0;
  background-color: $gray-200;
  justify-content: space-between;

  @media screen and (max-width: 1919px) {
    // flex-wrap: wrap;
  }

  .list-group-item {
    background-color: transparent;
    align-items: center;
    padding: 10px 1rem;
    width: 11.11%;

    // @media screen and (max-width: 1919px) {
    //   padding-left: 0;
    //   padding-right: 0;
    //   // justify-content: center;
    //   // display: flex;
    //   // flex-direction: column;
    //   // align-items: flex-start;
    // }
    p {
      margin-bottom: 0;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: #1a1a1a;
      // @media screen and (max-width: 1919px) {
      //   font-size: 15px;
      // }
    }

    h2 {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: $dark-gray;
      min-height: 26px;

      @media screen and (max-width: 767px) {
        min-height: auto;
        margin: 0;
      }
    }

    .enter-discount {
      height: 31px;
    }

    &.switch-outer {
      h2 {
        min-height: auto;
      }
    }
  }

  .custom-numberpad {
    bottom: 31px;
    top: auto;
  }
}

.custom-numpad {
  input {
    width: 100%;
    height: 44px;
    border-radius: 6px;
    background: #fff;
    padding-left: 10px;
    cursor: pointer;
  }
}

.cta-btn-wrapper {
  flex-wrap: wrap;
  @media screen and (max-width: 767px) {
    padding-top: 10px;
  }

  > div {
    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
      display: flex;
      justify-content: space-between;
    }
  }

  .btn {
    // width: 13.5%;
    // background: $grey-custom-shade;
    border: 1px solid $blueshade2;
    border-radius: 6px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: $blueshade2;
    margin-right: 12px;

    @media screen and (max-width: 767px) {
      width: 31%;
      margin: 0;
    }

    &:first-child {
      margin-left: 12px;

      @media screen and (max-width: 767px) {
        margin: 0;
      }
    }
  }
}

// Other options
.other-options {
  .btn-purpule-light {
    color: $blueshade2;
    border: 1px solid $primary;

    img {
      margin-right: 10px;
    }
  }
}

// footer
.footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 6;
  background: #fff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.12);

  .list-group {
    align-items: center;
    flex-wrap: wrap;
  }

  .list-group-item {
    background-color: transparent;
    align-items: center;
    display: flex;
    font-weight: 800;
    font-size: 13px;
    line-height: 15px;
    color: $dark-gray;
    padding: 0 20px;
    height: 57px;
    border-right: 1px solid $blackcustom;
    border-radius: 0 !important;

    // @media screen and (max-width: 1919px) {
    //   padding: 0 15px;
    // }
    &:first-child {
      width: 33%;
      // @media screen and (max-width: 1919px) {
      //   width: 31%;
      // }
    }

    &:nth-child(2) {
      width: 25%;
      // @media screen and (max-width: 1919px) {
      //   width: 22%;
      // }
    }

    &:nth-child(3) {
      border-right: none;
      width: 30%;
      justify-content: center;

      // @media screen and (max-width: 1919px) {
      //   width: 35%;
      // }
      div {
        height: 23px;
        padding: 0 25px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #e9f0f8;
        font-weight: 800;
        font-size: 13px;
        line-height: 15px;
        color: $dark-gray;
        // @media screen and (max-width: 1919px) {
        //   padding: 0 15px;
        //   font-size: 15px;
        // }
      }
    }

    &:last-child {
      width: 12%;
      border-right: none;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 800;
      font-size: 12px;
      line-height: 16px;
      // @media screen and (max-width: 1919px) {
      //   font-size: 15px;
      // }
      display: flex;
      flex-direction: column;

      span {
        color: #35c068;
        font-size: 14px;
      }

      img {
        position: absolute;
        right: 20px;

        @media screen and (max-width: 1279px) {
          right: 0;
        }
      }
    }
  }
}

// shortcut

.shortcut {
  height: calc(100vh - 333px);
  width: 102.8%;
  padding-right: 10px;

  .spinner-border {
    position: absolute;
    left: 45%;
    top: 45%;
  }

  &.scroll-auto {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .row {
    --bs-gutter-x: 1rem;
  }

  .list-group {
    &.box {
      width: 23.5%;

      .list-group-item {
        width: 100%;
      }
    }
  }

  .wrapper {
    padding: 10px;
    min-height: 106px;
    border-radius: 6px;
    border: 1px solid $blackcustom;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: all 0.3s ease-in-out;
    color: $blackshade1;
    cursor: pointer;
    background: #fff;
    position: relative;

    &:nth-child(3n) {
      margin-right: 0;
    }

    &:hover,
    &.active {
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      outline: -webkit-focus-ring-color auto 1px;
    }

    h3 {
      font-size: 14px;
      position: relative;
      transition: all 0.3s ease-in-out;
      color: #1a1a1a;
      font-weight: 700;
    }

    span {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      position: relative;
      margin-bottom: 0;
      color: $dark-gray;
    }
  }
}

.category-list {
  .wrapper {
    min-height: 60px;
    justify-content: center;
    user-select: none;

    h3 {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;
      &::selection {
        background-color: transparent;
      }
    }
  }
}

.sortable-list {
  margin: 0;
}

.sortable-column {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
  // min-height: 88px;
  border-radius: 5px;
  border: solid 1px #e5e5e5;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  text-align: center;
  cursor: pointer;
  position: relative;
  width: 32%;
  margin-bottom: 0;
  font-size: 14px;
  position: relative;
  color: $blackshade1;
  font-weight: 700;
  margin-right: 2%;
  background: #fff;

  @media screen and (max-width: 1279px) {
    font-size: 12px;
  }

  @media screen and (max-width: 1023px) {
    font-size: 14px;
  }

  @media screen and (max-width: 767px) {
    width: 49%;
  }

  &:nth-child(3n) {
    margin-right: 0;

    @media screen and (max-width: 767px) {
      margin-right: 2%;
    }
  }

  &:nth-child(2n) {
    @media screen and (max-width: 767px) {
      margin-right: 0;
    }
  }

  &:hover,
  &.active {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    outline: -webkit-focus-ring-color auto 1px;
    // color: #fff;
    // &:before {
    //   transform: scaleY(1);
    // }
    // h3 {
    //   color: #fff;
    // }
  }
}

// input-search
.input-search {
  .btn-inside-field {
    position: absolute;
    right: 0;
    width: 112px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 5px 5px 0 !important;
    z-index: 4;
  }

  .form-control {
    border-radius: 6px !important;
    padding-left: 14px !important;
    border-left: solid 1px #c5c5c5 !important;

    &::-webkit-input-placeholder {
      /* Edge */
      font-size: 14px;
      font-weight: 500;
      color: $black;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      font-size: 14px;
      font-weight: 500;
      color: $black;
    }

    &::placeholder {
      font-size: 14px;
      font-weight: 500;
      color: $black;
    }
  }

  .custom-numpad {
    width: 100%;

    input {
      border-radius: 6px !important;
      padding-left: 14px !important;
      border-left: solid 1px #c5c5c5 !important;
      padding: 0.66rem 0.75rem;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5;
      color: $gray-700;
      background-color: $white;
      background-clip: padding-box;
      border: 1px solid $blackcustom;
      padding-top: 0.56rem;
      padding-bottom: 0.56rem;
      height: auto;

      &::-webkit-input-placeholder {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: $gray-600;
      }

      &:-ms-input-placeholder {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: $gray-600;
      }

      &::placeholder {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: $gray-600;
      }
    }
  }
}

// pay-btn animation
.right-tab {
  // overflow: hidden;
  .custom-numberpad {
    top: 0;
    left: -360px;

    @media screen and (max-width: 1023px) {
      left: 0;
      top: -256px;
    }
  }

  .input-group {
    .scroll-auto-parent {
      position: relative;
      width: 100%;
    }

    .list-group {
      width: 100%;
      position: absolute;
      z-index: 112;
      top: 41px;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.12);
      border-radius: 6px;
      max-height: 210px;
      // border: 1px solid #b1b1b1;

      .list-group-item {
        cursor: pointer;

        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: $black;
      }

      p {
        margin-bottom: 0;
      }
    }
  }
}

.pay-btn {
  width: 100%;
  min-height: 53px;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: $blackcustom;
  border-radius: 6px;
  text-transform: capitalize;
  letter-spacing: 1.3px;
  font-weight: 700;
  background: #d3d3d3;
  border: none;
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  outline: none;
  position: relative;
  padding: 10px;
  z-index: 1;
  margin-bottom: 20px;

  &.have-price {
    background: #35c068;
    color: #1a1a1a;
  }

  &.btn-primary:disabled,
  &.btn-primary.disabled {
    background: #d3d3d3;
    opacity: 1;
    color: #b1b1b1;
  }
}

// .pay-btn::before {
//   content: "";
//   border-radius: 10px;
//   min-width: calc(100% + 14px);
//   min-height: calc(60px + 14px);
//   border: 3px solid $blueshade2;
//   box-shadow: 12px 12px 24px rgba(12, 45, 91, 0.64);
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   opacity: 0;
//   transition: all 0.3s ease-in-out 0s;
// }

.pay-btn:hover,
.pay-btn:active:focus,
.pay-btn:active,
.pay-btn:focus {
  // color: #fff;
  // transform: translateY(-6px);
  box-shadow: none;
  // background: #d3d3d3;
  // color: $blackcustom;
  outline: none;
  border: none;
}

// .pay-btn:active {
//   box-shadow: none;
// }
// .pay-btn:hover::before,
// .pay-btn:focus::before {
//   opacity: 1;
// }

// .pay-btn::after {
//   content: "";
//   width: 30px;
//   height: 30px;
//   border-radius: 100%;
//   border: 3px solid $blueshade2;
//   position: absolute;
//   z-index: -1;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   animation: ring 1.5s infinite;
// }

// .pay-btn:hover::after,
// .pay-btn:focus::after {
//   animation: none;
//   display: none;
// }

// @keyframes ring {
//   0% {
//     width: 30px;
//     height: 30px;
//     opacity: 1;
//   }
//   100% {
//     width: 300px;
//     height: 300px;
//     opacity: 0;
//   }
// }

.back-btn {
  position: absolute;
  top: 11px;
  left: 10px;
  padding: 0;
  box-shadow: none;
  background: transparent;
  border: none;
}

.automation-settings {
  .automation-list {
    &.overflow-auto {
      overflow-y: auto;
      height: 189px;
      overflow-x: hidden;
    }
  }

  .automation-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .form-label {
      font-size: 13px;
    }
    &.overflow-auto {
      overflow-y: auto;
      height: 189px;
      overflow-x: hidden;
    }

    // div {
    //   width: 43%;

    //   @media screen and (max-width: 1439px) {
    //     width: 41%;
    //   }

    //   @media screen and (max-width: 991px) {
    //     width: 37%;
    //   }
    // }

    // .link {
    //   width: 7%;

    //   @media screen and (max-width: 1439px) {
    //     width: 9%;
    //   }

    //   @media screen and (max-width: 991px) {
    //     width: 12%;
    //   }
    // }

    .btn {
      width: 29.3px;
      height: 29.3px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.mx-2 {
        width: 49.3px;
        height: 39.3px;
        margin-right: 0 !important;

        img {
          width: 49.3px;
          height: 39.3px;
        }
      }
    }
  }
}
// Add items
.automation-settings {
  &.three-column {
    display: flex;
    flex-wrap: wrap;

    &.scroll-auto {
      overflow-y: auto;
      height: 113px;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 4px;
        border: 1px solid $blue;
      }

      &::-webkit-scrollbar-track {
        border-radius: 0;
        background: #eaedf2;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 0;
        background: $blue;
      }
    }

    .automation-input {
      margin: 0.5rem 0;
      flex-wrap: nowrap;
      flex: 0 0 auto;
      width: 33.33333%;
      padding-right: calc(var(--bs-gutter-x) / 2);
      padding-left: calc(var(--bs-gutter-x) / 2);

      @media screen and (max-width: 576px) {
        width: 100%;
      }

      div {
        width: 100%;

        &.link {
          width: auto;
          margin: 0 15px;
          margin-right: 0;
        }
      }
    }
  }

  &.seven-column {
    border-top: 1px solid #b1b1b1;
    border-bottom: 1px solid #b1b1b1;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 101%;

    @media screen and (max-width: 767px) {
      width: 100%;
    }

    &.padding-res {
      @media screen and (min-width: 768px) {
        padding-left: 25px;
      }
      .top-label .form-check-label.prompt {
        display: flex;
        @media screen and (max-width: 767px) {
          display: none;
        }
      }
      &.scroll-auto {
        padding-right: 10px;
        padding-left: 35px;
        @media screen and (max-width: 767px) {
          padding: 0;
        }
      }
      &.automation-settings .automation-input {
        @media screen and (max-width: 767px) {
          padding-top: 45px;
        }
      }
    }

    &.scroll-auto {
      overflow-y: auto;
      height: 219px;
      overflow-x: hidden;

      @media screen and (max-width: 767px) {
        height: auto;
        overflow: visible;
      }

      &::-webkit-scrollbar {
        width: 4px;
        border: 1px solid $blue;
      }

      &::-webkit-scrollbar-track {
        border-radius: 0;
        background: #eaedf2;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 0;
        background: $blue;
      }
    }
    .top-label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 15px;
      .form-check-label {
        width: 8.5%;
        text-align: left;
        font-size: 13px;
        margin-right: 31px;
        @media screen and (max-width: 991px) {
          width: 8%;
          margin-right: 11px;
        }
        @media screen and (max-width: 767px) {
          display: none;
        }

        &.qty-on-hand {
          width: 74px;
          margin-right: 31px;
          @media screen and (max-width: 991px) {
            margin-right: 21px;
          }
        }

        &.link {
          width: 34px;
          justify-content: flex-end;
          display: flex;
          margin: 0;
          @media screen and (max-width: 767px) {
            margin: 0 !important;
          }
        }
        &:first-child {
          width: 44px;
        }
        &.prompt {
          width: 55px;
          display: none;
          margin: 0;
        }
        &.advaced-img {
          margin: 0;
          width: 29px;
          text-align: center;
          @media screen and (max-width: 767px) {
            margin: 0 !important;
          }
          img {
            width: 20px;
            height: auto;
          }
        }
      }
    }
    .automation-input {
      position: relative;
      margin-bottom: 1rem;

      @media screen and (max-width: 767px) {
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.4);
        padding: 30px 10px 10px;
        border-radius: 6px;
        position: relative;
      }
      .tag-img {
        cursor: pointer;
        position: absolute;
        left: -35px;
        width: auto;
        margin: 0;
        @media screen and (max-width: 767px) {
          top: 9px;
          right: 13px;
          left: auto;
          width: auto !important;
        }
      }
      div {
        // width: 12.5%;
        margin-right: 31px;
        width: 8.5%;
        @media screen and (max-width: 991px) {
          width: 8%;
          margin-right: 11px;
        }
        @media screen and (max-width: 767px) {
          width: 48% !important;
          margin: 0 !important;
          margin-bottom: 1rem !important;
        }

        &.link {
          width: 34px;
          justify-content: flex-end;
          display: flex;
          margin: 0;
          @media screen and (max-width: 767px) {
            width: 11% !important;
            align-items: center;
            margin: 0 !important;
          }
        }
        &.first-qty {
          width: 44px;
        }
        &.advaced-img {
          width: 29px;
          margin: 0;
          text-align: center;
          cursor: pointer;
          img {
            width: 20px;
            height: auto;
          }
          @media screen and (max-width: 767px) {
            width: 11% !important;
            align-items: center;
            margin: 0 !important;
          }
        }
        &.qty-on-hand {
          margin-right: 31px;
          width: 74px;
          @media screen and (max-width: 991px) {
            margin-right: 21px;
          }
        }
        &.custom-checkbox {
          width: 55px;
          align-items: center;
          margin-right: 0;
          @media screen and (max-width: 767px) {
            width: 11% !important;
            align-items: center;
          }

          label {
            margin-bottom: 6px;
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            color: $dark-gray;

            @media screen and (max-width: 991px) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.item-popup {
  .automation-input {
    // &:not(:first-child) {
    //   .form-label,
    //   .form-check-label {
    //     @media screen and (min-width: 768px) {
    //       display: none;
    //     }
    //   }
    // }
    .form-check-label,
    .form-label {
      @media screen and (min-width: 768px) {
        display: none;
      }
    }
  }
}

// confirmation popup
.confirmation {
  &.custom-modal {
    &.item-not-found {
      .modal-body {
        display: flex;
        justify-content: center;
      }

      .modal-footer {
        .btn {
          min-width: auto !important;
          display: flex;
          justify-content: space-between;
          padding-left: 2.8rem !important;
          padding-right: 2.8rem !important;
          align-items: center;

          @media screen and (max-width: 991px) {
            padding-left: 1.5rem !important;
            padding-right: 1.5rem !important;
          }

          @media screen and (max-width: 576px) {
            padding-left: 1.2rem !important;
            padding-right: 1.2rem !important;
          }
        }
      }
    }
  }

  &.custom-modal .form-cta-btn {
    bottom: -49px;
    // bottom: -70px;
    // right: 35%;
  }

  .modal-body {
    padding: 50px 23px;

    @media screen and (max-width: 767px) {
      padding: 15px;
    }

    h1 {
      font-weight: 400;
      font-size: 20px;
      line-height: 25px;
      color: $dark-gray;
      display: flex;
      align-items: center;

      p {
        margin-bottom: 0;
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        color: $dark-gray;
      }
    }
  }

  .modal-footer {
    .btn {
      margin: 0.25rem;
      // min-width: auto;
    }
  }

  &.checkout-complete {
    .modal-body {
      text-align: center;
      flex-direction: column;

      span {
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        color: $dark-gray;
        display: flex;
        width: 100%;
        justify-content: center;
      }

      div {
        height: 50px;
        background: #049e64;
        border-radius: 38px;
        padding: 11px 38px;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        display: inline-flex;
        color: $white;
        margin: auto;
      }
    }
  }
}

// Custom react select
// Custom react select
.custom-react-select {
  &.multi-select {
    .react-select__control {
      height: auto;
    }
  }

  .react-select__indicator {
    position: relative;
    left: -9px;
    background: url("../../assets/images/caret-down.svg") no-repeat center /
      12px 8px;

    svg {
      display: none;
    }
  }

  &.d-flex {
    > div {
      width: 100%;
    }
  }

  .react-select__multi-value {
    background-color: #049e64;
    color: $white;
    border-radius: 20px;
    margin-top: 6px;
    font-weight: 900;
    font-size: 14px;
    line-height: 19px;
  }

  .react-select__multi-value__label {
    color: $white;
    padding-left: 10px;
  }

  .react-select__multi-value__remove {
    &:hover {
      background-color: $blue;
    }
  }

  &.for-images {
    .react-select__placeholder {
      top: 4px;
    }

    .react-select__control {
      height: auto;
    }

    .react-select__single-value {
      padding-top: 12px;
      padding-bottom: 8px;

      img {
        width: 50px;
        height: 50px;
      }
    }

    .react-select__option {
      padding: 10px;
      height: auto;

      img {
        width: 50px;
        height: 50px;
      }
    }
  }
}

.custom-react-select .react-select__control:hover,
.custom-react-select .react-select__control--is-focused {
  border-color: $blackcustom !important;
  box-shadow: none !important;
}

.custom-react-select .react-select__control {
  border-color: $blackcustom !important;
  min-height: 40.1px;
  cursor: pointer;
  border-radius: 6px;
}

.custom-react-select {
  .react-select__control--menu-is-open {
    .react-select__indicator {
      svg {
        width: 26px;
        height: 25px;

        path {
          fill: $darkblue;
        }
      }
    }
  }
}

.custom-react-select {
  // z-index: 9;
  .react-select__control--menu-is-open {
    .react-select__indicator {
      svg {
        path {
          fill: $white;
        }
      }
    }
  }
}

.custom-react-select .react-select__placeholder,
.custom-react-select .react-select__single-value {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  opacity: 1;
  color: $gray-600;

  @include media-breakpoint-down(md) {
    font-size: 14px;
  }
}

.custom-react-select .react-select__menu {
  z-index: 2;
}

.custom-react-select .react-select__single-value {
  padding-left: 6px;
  font-weight: 400;
  color: #1a1a1a;
}

.custom-react-select .react-select__placeholder {
  border-radius: 5px;
  pointer-events: none;
  display: flex;
  align-items: center;
  margin: 0;
  transform-origin: 0 0;
  background: #fff;
  //   color: #939393;
  //  font-weight: 400;
}

.custom-react-select
  .react-select__value-container--has-value
  .react-select__placeholder {
  transform: scale(0.85);
  top: -11px;
  color: $dark-gray;
  font-weight: 700;
  opacity: 1;
}

.react-select__placeholder {
  opacity: 0.6;
  background: $white;
  padding: 0 5px;
}

.react-select__indicator-separator {
  display: none;
}

.custom-react-select .react-select__clear-indicator {
  background: none;
  left: 0;

  svg {
    display: block;
  }
}

.custom-react-select .react-select__input input:focus {
  border: none !important;
  box-shadow: none !important;
}

// .custom-react-select .react-select__menu {
//   border: none;
//   background: $seelct-bg-color;
//   border: 1px solid #dfdfdf;
//   /* Deep Dropdown */

//   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
//   border-radius: 3px;
//   z-index: 2;
// }

.custom-react-select .react-select__option {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #5b5876;
  font-weight: normal;
  height: 36px;
  transition: all 0.3s;
  display: flex;
  cursor: pointer;
  align-items: center;
}

.custom-react-select .react-select__option:first-child {
  border-radius: 6px 6px 0px 0px;
}

.custom-react-select .react-select__option:last-child {
  border-radius: 0px 0px 6px 6px;
}

.custom-react-select .react-select__option:hover,
.custom-react-select .react-select__option:focus,
.custom-react-select .react-select__option:active,
.custom-react-select .react-select__option:focus:active {
  background-color: $darkblue;
  color: $white;
}

.custom-react-select .react-select__option {
  background-color: transparent;
  /* Or any other desired color */
  /* Add any other styles you want to remove or modify */
}

.custom-react-select .react-select__option--is-focused {
  background-color: $gray-200;
  // color: $white;
}

.custom-react-select .react-select__option--is-selected {
  color: $white;
  background-color: $darkblue;
}

.custom-react-select .react-select__menu-list {
  padding: 0;
}

// Edit item
.total {
  border-top: 1px solid $blackcustom;
  border-bottom: 1px solid $blackcustom;
  font-weight: 500;
  color: $black;
}

// confirmation popup
.confirmation {
  &.select-qty {
    .modal-body {
      text-align: left;
    }
  }
}

// select-qty
.select-qty {
  .list-group {
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: 767px) {
      justify-content: space-between;
    }
  }

  .list-group-item {
    @media screen and (max-width: 767px) {
      width: 48%;
      margin-right: 0 !important;
    }

    width: 22.9%;
    margin-top: 10px;
    border-radius: 4px;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    color: #fff;
    margin-right: 12.5px;
    border: 1px solid $blackcustom !important;
    font-size: 14px;
    color: #1a1a1a;
    font-weight: 700;

    &:nth-child(4n) {
      margin-right: 0;
    }

    p {
      margin-bottom: 0;
      font-size: 14px;
      font-size: 25px;
      font-weight: bold;
    }

    span {
      font-weight: 500;
    }

    &:hover {
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.4);
    }

    &:first-child,
    &:last-child {
      border-radius: 4px;
    }
  }
}

// item-list
.item-list {
  h1 {
    font-weight: bold;
    font-size: 1.3rem;
    text-align: left;
    padding-left: 1rem;
  }

  .modal-body {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }

  .table {
    margin-bottom: 0;
  }

  thead {
    display: flex;
    padding: 0 1rem;

    tr {
      display: flex;
      justify-content: space-between;
      width: 100%;
      background: $blueshade2;
      border-radius: 6px;
      margin-bottom: 5px;

      th {
        display: flex;
        color: #fff;
        height: 45px;
        align-items: center;
        padding: 0.75rem 0.5rem;

        &:first-child {
          width: 70%;
        }

        &:nth-child(2) {
          width: 15%;
        }

        &:last-child {
          width: 15%;
        }
      }
    }
  }

  tbody {
    height: 172px;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;

    tr {
      margin: 5px 0;
      // background: $blue;
      justify-content: space-between;
      width: 100%;
      flex-direction: row;
      display: flex;
      border-radius: 6px;
      transition: all 0.3s;
      background-color: rgba(0, 0, 0, 0.05);
      cursor: pointer;

      &:hover {
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.4);
      }

      &:last-child {
        margin-bottom: 0;
      }

      td {
        display: flex;

        // background: $blue;
        &:first-child {
          width: 70%;
        }

        &:nth-child(2) {
          width: 15%;
        }

        &:last-child {
          width: 15%;
        }
      }
    }
  }
}

// verify-age
.verify-age {
  p {
    color: $blue;
    font-size: 30px;
    font-weight: bold;
  }

  .modal-footer {
    .btn {
      min-width: 147px;
    }
  }
}

// .shortcut .sortable-column .wrapper {
//   padding: 10px;
//   min-height: 88px;
//   border-radius: 5px;
//   border: solid 1px #e5e5e5;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   text-align: center;
//   transition: all 0.3s ease-in-out;
//   color: $blackshade1;
//   cursor: pointer;
//   position: relative;
// }
// .category-list.sortable-column .wrapper h3 {
//   width: 100%;
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   margin-bottom: 0;
//   font-size: 14px;
//   position: relative;
//   transition: all 0.3s ease-in-out;
//   color: $blackshade1;
// }

// checkout-popup
.checkout-popup {
  .modal-body {
    padding-top: 0;
    padding-bottom: 0;
  }

  .modal-footer {
    display: none;
  }

  .btn-close {
    display: none;
  }

  .custom-card {
    height: 100%;
  }

  .custom-numberpad {
    position: relative;
    height: 100%;
    background-color: #e9f0f8;

    .MuiPaper-root {
      &.MuiPaper-elevation1 {
        width: auto;
        box-shadow: none;
      }

      .MuiButton-root {
        padding: 16px 0 !important;
      }
    }

    .bottom-cta {
      .btn {
        font-size: 16px;
        line-height: 19px;
        background: $white;
        border: none;
        height: 49px;
        color: $dark-gray;
        box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.28);

        &:hover,
        &:active,
        &:focus {
          border: 1px solid $blackcustom !important;
        }
      }
    }
  }

  .cta-btns {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .btn {
      width: 48%;
      margin-bottom: 10px;
    }
  }

  .totals-box {
    .badge {
      padding: 10px;
      border-radius: 4px !important;
      background: transparent;
      font-weight: 900;
      font-size: 16px;
      line-height: 19px;
      padding-top: 0;
      padding-bottom: 0;
      color: $primary;
    }

    .list-group {
      border-radius: 0;
    }

    .list-group-item {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
      font-weight: 700;
      padding-top: 15px;
      padding-bottom: 15px;
      font-size: 16px;
      line-height: 19px;
      color: #1a1a1a;
      border-bottom: 1px solid $blackcustom;

      .form-floating {
        width: 158px;
      }

      &:nth-child(3) {
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }

    .cta-btns {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-right: calc(var(--bs-gutter-x) / -4);
      margin-left: calc(var(--bs-gutter-x) / -4);

      > * {
        padding-right: calc(var(--bs-gutter-x) / 4);
        padding-left: calc(var(--bs-gutter-x) / 4);
      }

      .btn {
        width: 100%;
        margin-bottom: 10px;
        background: #d5efe5;
        border: 1px solid #049e64;
        color: #1a1a1a;
        height: 49px;
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        height: 91px;
        word-break: break-all;

        &:nth-child(3n) {
          margin-right: 0;
        }

        @media screen and (max-width: 576px) {
          margin-right: 11px;
        }
      }
    }
  }

  .cta-btns {
    &.payment-info {
      .btn {
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        height: 91px;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #1a1a1a;

        img {
          margin-bottom: 7px;
        }

        &:hover,
        &:active,
        &:focus {
          box-shadow: none;
          background: transparent;
        }
      }
    }
  }
}

// customer-not-found
.customer-not-found {
  .modal-footer {
    display: flex;
  }
}

// transaction-details

.options {
  .btn {
    @media screen and (max-width: 991px) {
      width: 49%;
    }

    @media screen and (max-width: 575px) {
      margin-right: 0 !important;
      margin-top: 10px;
    }
  }

  @media screen and (max-width: 575px) {
    flex-wrap: wrap;
    justify-content: space-between !important;
  }
}

.transaction-details {
  &.custom-modal {
    .modal-footer {
      .btn {
        min-width: auto;
        margin-right: 10px;
        min-width: 130px;
        justify-content: center;

        @media screen and (max-width: 991px) {
          min-width: 110px;
          // margin-right: 119px;
        }

        @media screen and (max-width: 767px) {
          margin-right: 0;
          min-width: 48%;
          margin-bottom: 10px !important;
        }
      }

      .d-flex {
        @media screen and (max-width: 767px) {
          flex-wrap: wrap;
          justify-content: space-between;
          width: 100%;
        }
      }
    }

    .form-cta-btn {
      min-width: 130px;

      @media screen and (max-width: 991px) {
        min-width: 110px;
      }

      @media screen and (max-width: 767px) {
        margin-right: 0;
        min-width: 48%;
        bottom: -189px;
        right: 15px;
        min-width: 44%;
        left: auto;
      }
    }
  }

  .wrapper {
    display: flex;
    flex-wrap: wrap;
    background: transparent;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  .below-description {
    &.custom-data-table {
      .void-refund {
        .card.custom-card {
          height: auto;
        }

        .auto-width .table tbody tr td:last-child {
          text-align: center !important;
        }
      }

      .with-scroll-on-table .pagination-wrapper {
        height: auto;
        padding-bottom: 0;

        @media screen and (max-width: 575px) {
          padding-bottom: 50px;
        }
      }
    }

    &.adjust-column {
      .list-group {
        flex-direction: column;
      }
    }

    .delivery {
      .list-group-item {
        width: 100%;
        flex-direction: column;
      }
    }

    .react-bootstrap-table-pagination-list {
      display: none;
    }

    .pagination-wrapper .react-bootstrap-table-pagination div:first-child {
      padding-right: 0;
    }

    .pagination-wrapper .dropdown .btn {
      margin-right: 0;
    }

    h2 {
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      color: $dark-gray;
    }

    .list-group {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      // @media screen and (max-width: 1365px) {
      //   width: 60%;
      // }
      // @media screen and (max-width: 991px) {
      //   width: 100%;
      // }
    }

    .list-group-item {
      width: 50%;

      @media screen and (max-width: 576px) {
        width: 100%;
      }
    }
  }

  .list-group {
    width: 49%;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .list-group-item {
    color: $black;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #1a1a1a;
    padding: 0.7rem 1rem;
    padding-left: 0;
    padding-right: 0;

    span {
      font-weight: 400;

      &.badge {
        border-radius: 20px;
        font-size: 16px;
        font-weight: 500;
        padding: 3px 11px;
        color: $white;
        line-height: 19px;
      }
    }

    .fw-bold {
      min-width: 150px;

      @media screen and (max-width: 576px) {
        min-width: 115px;
      }
    }

    .status {
      background: $blue;
      color: #fff;
      padding: 0 10px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        left: -13px;
        top: -1px;
        border-top: 13px solid transparent;
        border-bottom: 13px solid transparent;
        border-right: 13px solid #0daeeb;
      }
    }
  }

  .modal-footer {
    .btn {
      &:first-child {
      }
    }
  }

  .custom-tab-nav {
    border-bottom: none;
    padding: 0;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: -23px;
      bottom: 0;
      background: $blackcustom;
      width: calc(100% + 46px);
      height: 1px;
    }
  }
}

// five-column-split
.five-column-split {
  box-shadow: none;

  thead {
    tr {
      th {
        &:first-child {
          width: 10%;
        }

        &:nth-child(2) {
          width: 35%;
        }

        &:nth-child(3) {
          width: 15%;
        }

        &:nth-child(4) {
          width: 25%;
        }

        &:last-child {
          width: 15%;
          text-align: left !important;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        &:first-child {
          width: 10%;
        }

        &:nth-child(2) {
          width: 35%;
        }

        &:nth-child(3) {
          width: 15%;
        }

        &:nth-child(4) {
          width: 25%;
        }

        &:last-child {
          width: 15%;
          justify-content: flex-start !important;
        }
      }
    }
  }
}

// tooltip
.custom-overlay {
  cursor: pointer;

  path {
    fill: $darkblue;
  }
}

.custom-tooltip {
  .tooltip-inner {
    background: $darkblue;
  }

  &.general {
    max-width: 700px !important;

    .tooltip-inner {
      text-align: left;
      max-width: 100%;

      @media screen and (max-width: 767px) {
        max-width: 95%;
      }
    }
  }
}

// Sales
// lookup-table styling
.filter-by-status {
  @media screen and (min-width: 577px) {
    position: absolute;
    right: 220px;
    width: 153px;
  }

  @media screen and (max-width: 991px) {
    right: 76px;
  }

  @media screen and (max-width: 576px) {
    width: calc(100% - 30px);
    margin: auto;
  }
}

.lookup-table {
  .with-scroll-on-table {
    height: calc(100vh - 233px);
  }

  .pagination-wrapper .react-bootstrap-table-pagination div:first-child {
    top: -55px;

    @media screen and (max-width: 767px) {
      top: 0;
    }
  }

  thead {
    tr {
      th {
        &:first-child {
          text-align: center;
        }

        &:nth-child(2) {
          width: auto;
        }

        // &:first-child {
        //   width: 7.5%;
        // }
        // &:nth-child(2) {
        //   width: 7.5%;
        // }
        // &:nth-child(3) {
        //   width: 15%;
        // }
        // &:nth-child(4) {
        //   width: 12.5%;
        // }
        // &:nth-child(5) {
        //   width: 10%;
        // }
        // &:nth-child(6) {
        //   width: 10%;
        // }
        // &:nth-child(7) {
        //   width: 7.5%;
        // }
        // &:nth-child(8) {
        //   width: 12.5%;
        // }
        // &:nth-child(9) {
        //   width: 10%;
        // }
        &:last-child {
          width: auto;
          width: 7.5%;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        &:first-child {
          // width: 7.5%;
          .d-flex {
            @media screen and (max-width: 959px) {
              margin: 0;
              flex-direction: row !important;
            }

            @include media-breakpoint-down(sm) {
              // flex-direction: column !important;
            }
          }

          .badge {
            @media screen and (max-width: 959px) {
              margin-right: 10px;
            }
          }
        }

        // &:nth-child(2) {
        //   width: 7.5%;
        // }
        // &:nth-child(3) {
        //   width: 15%;
        // }
        // &:nth-child(4) {
        //   width: 12.5%;
        // }
        // &:nth-child(5) {
        //   width: 10%;
        // }
        // &:nth-child(6) {
        //   width: 10%;
        // }
        // &:nth-child(7) {
        //   width: 7.5%;
        // }
        // &:nth-child(8) {
        //   width: 12.5%;
        // }
        // &:nth-child(9) {
        //   width: 10%;
        // }
        &:last-child {
          // width: 7.5%;
          width: auto;

          .btn {
            margin: auto;

            @media screen and (max-width: 959px) {
              margin: 0;
            }
          }
        }
      }
    }
  }
}

//

// different break points depends upon the expand fucntinonlaity of screen
// maxwidth_1366px
.maxwidth_1366px {
  .navbar {
    // .secondary-navbar {
    //   padding: 9px 0.5rem;

    //   .nav-link {
    //     padding: 7px 10px;
    //     margin-right: 5px;
    //   }
    // }

    .primary-navbar {
      .nav-link {
        // font-size: 15px;
        padding: 16px 10px;
        letter-spacing: -0.4px;

        // &:nth-child(4) {
        //   min-width: 160px;
        //   width: 160px;
        // }

        // &:nth-child(6) {
        //   min-width: 99px;
        //   width: 99px;
        // }

        img {
          margin-right: 5px;
          width: 15px;
        }
      }
    }
  }

  // content
  .content {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0;
  }

  .footer {
    position: relative;
  }

  .total-info {
    .list-group-item {
      padding-left: 0;
      padding-right: 0;

      &:first-child {
        padding-left: 0.5rem;
      }

      &:last-child {
        padding-right: 0.5rem;
      }

      p {
        font-size: 15px;
      }

      h2 {
        font-size: 13px;
      }
    }
  }

  .switch-outer {
    .custom-numpad {
      width: 100px;
    }
  }

  .footer {
    .list-group-item {
      padding: 0 15px;

      &:first-child {
        width: 31%;
      }

      &:nth-child(2) {
        width: 22%;
      }

      &:nth-child(3) {
        width: 35%;

        div {
          padding: 0 15px;
          font-size: 15px;
          height: auto;
        }
      }

      &:last-child {
        font-size: 15px;
      }
    }
  }

  .todays-takings {
    .total {
      .custom-numpad {
        width: 100%;
        margin-bottom: 10px;
      }
    }

    .cash-reconciliation .total {
      flex-direction: column !important;
      align-items: flex-start;
    }
  }
}

.report-with-scroll {
  &.taking-table {
    .pagination-wrapper {
      height: calc(100vh - 453px);
    }
  }
  &.seller-table {
    .pagination-wrapper {
      height: calc(100vh - 453px);
      @media screen and (max-width: 991px) {
        margin: 0;
      }
    }
  }
  &.register-table {
    .pagination-wrapper {
      height: calc(100vh - 477px);
    }
  }
  &.whats-selling{
    .pagination-wrapper {
      height: calc(100vh - 510px);
    }
  }
  .pagination-wrapper {
    height: calc(100vh - 506px);
    position: static;
  }

  .rcs-custom-scroll .rcs-inner-handle {
    height: 100%;
  }
}

// maxwidth_1280px
.maxwidth_1280px {
  @media screen and (max-width: 1279px) {
    max-width: 100%;
  }

  &.wrapper {
    .navbar {
      .secondary-navbar {
        padding: 9px 0.5rem;

        .nav-link {
          padding: 0 7px;
        }
      }

      .primary-navbar {
        .nav-link {
          img {
            margin-right: 5px;
            width: 15px;
          }
        }
      }
    }

    .footer {
      position: relative;

      .list-group-item {
        &:nth-child(3) {
          div {
            padding: 0 9px;
            font-size: 12px;
          }
        }
      }
    }
  }

  // content
  .content {
    padding-bottom: 0;
  }

  .register-outer {
    .col-lg-8 {
      padding-right: 0;
    }

    .card-body {
      padding-left: 0.675rem !important;
      padding-right: 0.675rem !important;
    }
  }

  .items-contain {
    thead {
      tr {
        th {
          &:nth-child(2) {
            width: 33%;
          }

          &:nth-child(6) {
            width: 10%;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          cursor: pointer;
          text-align: center;

          &:nth-child(2) {
            width: 33%;
          }

          &:nth-child(6) {
            width: 10%;
          }
        }
      }
    }
  }

  .total-info {
    .list-group-item {
      padding-left: 0;
      padding-right: 0;

      &:first-child {
        padding-left: 0.5rem;
      }

      &:last-child {
        padding-right: 0.5rem;
      }

      p {
        font-size: 15px;
      }

      h2 {
        font-size: 13px;
      }
    }
  }

  .switch-outer {
    .custom-numpad {
      width: 70px;
    }
  }

  .footer {
    .list-group-item {
      padding: 0 15px;
      font-size: 13px;

      &:first-child {
        width: 31%;
      }

      &:nth-child(2) {
        width: 22%;
      }

      &:nth-child(3) {
        width: 35%;

        div {
          padding: 0 15px;
          font-size: 14px;
        }
      }

      &:last-child {
        font-size: 14px;
      }
    }
  }

  .todays-takings {
    .total {
      .custom-numpad {
        width: 100%;
        margin-bottom: 10px;
      }
    }

    .cash-reconciliation .total {
      flex-direction: column !important;
      align-items: flex-start;
    }

    .cash-reconciliation .accordion-collapse {
      height: calc(100vh - 417px);
    }
  }
}

// maxwidth_1152px
.maxwidth_1152px {
  @media screen and (max-width: 1151px) {
    max-width: 100%;
  }

  &.wrapper {
    .navbar {
      .navbar-collapse {
        .d-xl-flex {
          display: none !important;
        }

        .d-xl-none {
          display: flex !important;
        }
      }

      .secondary-navbar {
        padding: 9px 0.5rem;

        .nav-link {
          padding: 0 7px;
        }
      }

      .primary-navbar {
        .nav-link {
          // font-size: 14px;
          // height: 45px;
          padding: 16px 11px;
          letter-spacing: 0;

          // &:nth-child(4) {
          //   min-width: 160px;
          //   width: 160px;
          // }

          // &:nth-child(6) {
          //   min-width: 89px;
          //   width: 89px;
          // }

          img {
            margin-right: 5px;
            width: 15px;
          }
        }
      }
    }

    .footer {
      position: relative;

      .list-group-item {
        &:nth-child(3) {
          div {
            padding: 0 9px;
            font-size: 12px;
          }
        }
      }
    }
  }

  // content
  .content {
    padding-bottom: 0;
  }

  .register-outer {
    .col-lg-8 {
      padding-right: 0;
    }

    .card-body {
      padding-left: 0.675rem !important;
      padding-right: 0.675rem !important;
    }
  }

  .custom-data-table .card .items-contain .table tbody {
    height: 420px;
  }

  .items-contain {
    min-height: calc(100vh - 462px);

    thead {
      tr {
        th {
          &:nth-child(2) {
            width: 33%;
          }

          &:nth-child(6) {
            width: 10%;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          cursor: pointer;

          &:nth-child(2) {
            width: 33%;
          }

          &:nth-child(6) {
            width: 10%;
          }
        }
      }
    }
  }

  .total-info {
    .list-group-item {
      padding-left: 14px;
      padding-right: 0;

      &:first-child {
        padding-left: 0.5rem;
      }

      &:last-child {
        padding-right: 0.5rem;
      }

      p {
        font-size: 15px;
      }

      h2 {
        font-size: 13px;
      }
    }
  }

  .switch-outer {
    .custom-numpad {
      width: 70px;
    }
  }

  .shortcut {
    height: calc(100vh - 427px);
  }

  .shortcut-wrapper {
    h2 {
      margin-right: 15px;
    }
  }

  .todays-takings {
    .total {
      .custom-numpad {
        width: 100%;
        margin-bottom: 10px;
      }
    }

    .cash-reconciliation .total {
      flex-direction: column !important;
      align-items: flex-start;
    }

    .cash-reconciliation .accordion-collapse {
      height: calc(100vh - 417px);
    }
  }
}

// maxwidth_960px
.maxwidth_960px {
  @media screen and (max-width: 959px) {
    max-width: 100%;
  }

  .right-tab {
    overflow: inherit;
  }

  .pay-btn-wrapper {
    position: sticky;
    border-radius: 10px 10px 0 0;
    bottom: 0;
    background: #fff;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.4);
    padding: 0 15px;
    width: 103.4%;
    margin-left: -16px;
    margin-top: 20px;
  }

  .pay-btn {
    margin-bottom: 13px;
    margin-top: 13px !important;

    &.btn-primary:disabled,
    &.btn-primary.disabled {
      background: #d3d3d3;
    }

    &:hover {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }

  &.wrapper {
    .navbar {
      .navbar-collapse {
        .d-xl-flex {
          display: none !important;
        }

        .d-xl-none {
          display: flex !important;
        }
      }

      .secondary-navbar {
        padding: 9px 0.5rem;

        .nav-link {
          padding: 0 7px;
        }
      }

      .primary-navbar {
        .nav-link {
          // font-size: 14px;
          // height: 45px;
          padding: 16px 11px;
          letter-spacing: 0;

          // &:nth-child(4) {
          //   min-width: 160px;
          //   width: 160px;
          // }

          // &:nth-child(6) {
          //   min-width: 89px;
          //   width: 89px;
          // }

          img {
            margin-right: 5px;
            width: 15px;
          }
        }
      }
    }

    .footer {
      position: relative;

      // @media screen and (max-width: 767px) {
      //   padding-bottom: 100px;
      // }

      .list-group-item {
        font-size: 14px;

        &:first-child {
          width: 50%;
        }

        &:nth-child(2) {
          width: 50%;
          justify-content: center;
          font-size: 16px;
        }

        &:nth-child(3) {
          width: 50%;
          border-top: 2px solid #0daeeb;

          div {
            padding: 0 11px;
            width: 100%;
            font-size: 13px;
          }
        }

        &:last-child {
          width: 50%;
          border-top: 2px solid #0daeeb;
          font-size: 17px;
        }
      }
    }

    .todays-takings {
      .list-group {
        flex-wrap: wrap;

        .list-group-item {
          width: 48%;
          margin-bottom: 15px;

          &:nth-child(3),
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .custom-data-table {
        min-height: auto;
        height: auto;

        thead tr th:last-child {
          text-align: left;
          pointer-events: all;
        }
      }

      .date-range-wrapper {
        .form-control {
          width: auto;
        }
      }

      .report-wrapper {
        > .custom-card {
          height: calc(100% - 16px);
        }

        &.col-9 {
          flex: 0 0 auto;
          width: 100%;
        }
      }

      .custom-accordion {
        @media screen and (min-width: 1201px) {
          position: relative;
          width: 100%;
          left: 0;
          transform: translateX(0);
          z-index: 2;
        }
      }

      .cash-reconciliation {
        background: #fff;

        .accordion-collapse {
          height: calc(100vh - 601px);
        }
      }

      .col-xxl-9,
      .col-xxl-3 {
        width: 100%;
        margin-top: 20px;
      }

      .list-group .list-group-item {
        padding: 0.5rem;
      }
    }
  }

  // content
  .content {
    // padding-left: 0.5rem;
    // padding-right: 0.5rem;
    padding-bottom: 0;
  }

  .register-outer {
    .col-lg-8 {
      width: 100%;
    }

    .col-lg-4 {
      width: 100%;
      margin-top: 20px;
    }

    // .card-body {
    //   padding-left: 0.675rem !important;
    //   padding-right: 0.675rem !important;
    // }
  }

  .custom-data-table .card .items-contain .table tbody {
    height: 240px;
  }

  .items-contain {
    min-height: calc(100vh - 672px);

    thead {
      tr {
        th {
          &:nth-child(2) {
            width: 33%;
          }

          &:nth-child(6) {
            width: 10%;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          cursor: pointer;

          &:nth-child(2) {
            width: 33%;
          }

          &:nth-child(6) {
            width: 10%;
          }
        }
      }
    }
  }

  .total-info {
    .list-group-item {
      padding-left: 0;
      padding-right: 0;

      &:first-child {
        padding-left: 0.5rem;
      }

      &:last-child {
        padding-right: 0.5rem;
      }

      p {
        // font-size: 15px;
      }

      h2 {
        // font-size: 13px;
      }
    }
  }

  .switch-outer {
    .custom-numpad {
      width: 70px;
    }
  }

  .shortcut {
    height: calc(100vh - 607px);
    width: 101.8%;
  }

  .shortcut-wrapper {
    h2 {
      margin-right: 15px;
    }
  }
}

// CUstom tab nav
.custom-tab-nav {
  border-bottom: 1px solid $blackcustom;
  padding: 0 14px;
  width: 100% !important;
  display: inline-block;

  @include media-breakpoint-down(sm) {
    display: block;
    overflow: auto;
    white-space: nowrap;
  }
}

.nav-pills {
  .nav-item {
    margin-bottom: 7px;
    margin-right: 40px;
    float: left;
    padding-right: 0 !important;
    position: relative;

    @include media-breakpoint-down(lg) {
      margin-right: 21px;
    }

    @include media-breakpoint-down(sm) {
      margin-right: 21px;
      display: inline-block;
      float: none;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.custom-tab-nav .nav-link {
  background: transparent;
  border: none;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  line-height: 19px;
  padding: 0;
  color: $dark-gray;
  text-align: left;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -7px;
    width: 100%;
    height: 3px;
    background: $primary;
    opacity: 0;
    transition: all 0.3s;
  }

  &:hover,
  &:active,
  &:focus,
  &.active {
    &:before {
      opacity: 1;
    }
  }

  &:hover,
  &:active,
  &:focus,
  &.active {
    color: $dark-gray;
    background: #fff;
  }

  @include media-breakpoint-down(md) {
    font-size: 15px;
    margin-bottom: 0 !important;
  }
}

// Reports
.todays-takings {
  h1 {
    font-size: 23px;
  }
  .list-group {
    display: flex;
    justify-content: space-between;

    // width: calc(100% - 288px);
    @media screen and (max-width: 991px) {
      flex-wrap: wrap;
    }

    .list-group-item {
      border-radius: 6px !important;
      width: 24%;
      transition: all 0.3s;
      font-size: 36px;
      font-weight: bold;
      padding: 0.685rem 0.5rem;
      color: $black;
      background: #fff;
      border-top: 5px solid transparent;

      @media screen and (max-width: 1279px) {
        padding: 0.65rem;
      }

      @media screen and (max-width: 991px) {
        width: 48%;
        margin-bottom: 15px;
      }

      @media screen and (max-width: 767px) {
        width: 48%;
      }

      &:nth-child(3),
      &:last-child {
        @media screen and (max-width: 991px) {
          margin-bottom: 0;
        }

        @media screen and (max-width: 767px) {
          margin-bottom: 15px;
        }
      }

      &:last-child {
        @media screen and (max-width: 767px) {
          margin-bottom: 0;
        }
      }

      .fw-bold {
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        margin-bottom: 9px;
        display: flex;
        align-items: center;
      }

      &:hover {
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.4);
      }

      p {
        margin-bottom: 0;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
      }

      .me-auto {
        span {
          background: #fff;
          padding: 5px 6.5px 5px 7px;
          border-radius: 5px;
          font-weight: 800;
          font-size: 14px;
          line-height: 16px;
          margin-left: 4px;

          &.up {
            color: #049e64;
          }

          &.down {
            color: #f00;
          }
        }
      }

      &.list-group-item-seagreen {
        border-top-color: #35c068;
      }

      &.list-group-item-yellow {
        border-top-color: #9cb2eb;
      }

      &.list-group-item-pink {
        border-top-color: #e38c8c;
      }

      &.list-group-item-primary {
        border-top-color: #08a298;
      }
    }
  }

  .custom-data-table {
    min-height: auto;
    height: auto;

    thead tr th:last-child {
      text-align: left;
      pointer-events: all;
    }

    .tab-pane {
      > .d-flex {
        @media screen and (max-width: 576px) {
          flex-direction: column;
          align-items: flex-start !important;
        }

        h2 {
          @media screen and (max-width: 576px) {
            margin-bottom: 15px;
          }
        }

        p {
          @media screen and (max-width: 576px) {
            margin-left: 0;
          }
        }

        > .d-flex {
          @media screen and (max-width: 576px) {
            flex-direction: column;
            align-items: flex-start !important;
          }

          h2 {
            @media screen and (max-width: 576px) {
              margin-bottom: 15px;
            }
          }

          p {
            @media screen and (max-width: 576px) {
              margin-left: 0;
            }
          }

          &.date-range-wrapper {
            @media screen and (max-width: 576px) {
              width: 100%;
              margin-top: 20px;
            }

            .d-flex {
              @media screen and (max-width: 576px) {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .pagination-wrapper .react-bootstrap-table-pagination div:first-child {
    top: -49px;

    @media screen and (max-width: 767px) {
      top: 0;
    }
  }

  .custom-data-table thead tr th:nth-child(2) {
    width: auto;
  }

  .custom-data-table thead tr th:last-child {
    width: auto;
  }

  .custom-data-table .column-visibility-table tbody tr td:last-child {
    width: auto;
  }

  .date-range-wrapper {
    flex-direction: column;

    @media screen and (max-width: 767px) {
      max-width: 100%;
    }

    .form-control {
      width: 276px;
      font-size: 12px;

      @media screen and (max-width: 991px) {
        width: auto;
      }
    }
  }

  .custom-card {
    height: calc(100% - 15px);
    margin-top: 15px;

    &.whats-selling {
      @include media-breakpoint-down(sm) {
        padding-bottom: 81px;
      }
    }
  }

  .tab-pane {
    .print {
      min-width: 102px;
      margin-left: 15px;

      @include media-breakpoint-down(sm) {
        margin-left: 0;
        margin-top: 10px;
      }
    }

    h2 {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: $dark-gray;
      margin-bottom: 0;
    }

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: $dark-gray;
      margin-left: 12px;
      margin-bottom: 0;
    }
  }

  .report-wrapper {
    transition: width 0.35s ease;

    .rcs-custom-scroll .rcs-custom-scrollbar {
      top: 0;
    }

    .rcs-custom-scroll .rcs-custom-scroll-handle {
      // top: 0 !important;
      height: 113px !important;
    }

    > .custom-card {
      height: calc(100vh - 293px);

      // max-height: 598px;
      @media screen and (max-width: 767px) {
        height: auto;
      }
      .pagination-wrapper .react-bootstrap-table-pagination{
        @media screen and (max-width: 767px) {
          bottom: -20px;
        }
      }

      .react-bootstrap-table-pagination-list {
        padding: 0px 10px;
        bottom: -57px;
        right: -15px;
      }
    }

    &.expanded {
      width: 100%;
    }

    &.col-9 {
      @media screen and (max-width: 1400px) {
        flex: 0 0 auto;
        width: 66.66667%;
      }

      @media screen and (max-width: 1200px) {
        flex: 0 0 auto;
        width: 100%;
      }
    }

    .custom-data-table tbody tr td:last-child.react-bs-table-no-data {
      padding: 90px 0;
      height: auto;
      @media screen and (max-height: 615px) {
        padding: 21px 0;
      }
    }
  }

  .position-relative {
    position: relative;

    @media screen and (max-width: 1200px) {
      margin-top: 20px;
    }
  }

  .custom-accordion {
    @media screen and (min-width: 1201px) {
      position: absolute;
      width: calc(100% - 15px);
      left: 49%;
      transform: translateX(-50%);
      z-index: 2;
    }
  }

  .cash-reconciliation {
    background: #fff;

    &.custom-card {
      min-height: 91px;
      margin-top: 0;

      .card-body {
        @media screen and (max-height: 615px) {
          height: calc(100vh - 371px);
          overflow-y: auto;
        }

        > .custom-numpad {
          .form-control {
            height: 35px;
          }
        }

        .row {
          .col-6 {
            &:nth-child(odd) {
              .custom-numberpad {
                @media screen and (max-height: 615px) {
                  left: 0;
                }
              }
            }
          }
        }
      }
    }

    .accordion-collapse {
      border: none;
      border-top: 1px solid $blackcustom;
      height: calc(100vh - 369px);
      // overflow: auto;

      @media screen and (max-width: 1200px) {
        height: calc(100vh - 351px);
      }

      @media screen and (max-width: 991px) {
        height: calc(100vh - 601px);
      }

      @media screen and (max-width: 767px) {
        height: 100%;
      }

      &.collapsing {
        transition: none;
      }
      .row {
        .col-6 {
          &:nth-child(odd) {
            .custom-numberpad{
                right: auto;
                left: 0;
            }
          }
        }
      }
    }

    .custom-numpad {
      position: relative;

      .form-control {
        @media screen and (max-width: 1679px) {
          padding: 0.32rem 0.75rem;
        }

        @media screen and (max-width: 1279px) {
          padding: 0.56rem 0.75rem;
        }
      }

      .custom-numberpad {
        right: 0;
        left: auto;
      }
    }

    .card-header {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      border: none;
      padding-bottom: 14px;

      .main-cta-accordion {
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        background: none;
        text-align: left;
        padding: 0;
        border: none;
        color: $dark-gray;
        border-radius: 0;
        box-shadow: none;
        background-repeat: no-repeat;
        background-position: right center;

        &.open {
          background-image: url("../../assets/images/caret-up.svg");
        }

        &.closed {
          background-image: url("../../assets/images/caret-down.svg");
        }
      }
    }

    .accordion-button {
      border: none;
      padding: 0.6rem 0.85rem;
      border-radius: 6px 6px 0 0;
      border-bottom: 1px solid $blackcustom !important;
      background-color: transparent;
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      color: $dark-gray;
      margin-bottom: 0;
    }

    .show + .balance-wrapper {
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.12);
      border-top: 1px solid $blackcustom;
      padding: 13px 6px 14px;

      @include media-breakpoint-down(md) {
        padding-top: 23px;
      }

      .total {
        display: flex !important;
      }

      .close-register {
        height: 40px;
      }
    }

    .balance-wrapper {
      .total {
        display: none !important;
        justify-content: flex-start;
      }

      .close-register {
        width: 100%;
        height: 32px;
        padding: 0;
      }

      .custom-numpad {
        .custom-numberpad {
          top: -240px;
          bottom: auto;
        }
      }
    }

    .total {
      border-bottom: none;
      justify-content: space-between;
      align-items: center;
      border-top: none;
      position: relative;
      min-height: 47px;

      @include media-breakpoint-down(sm) {
        align-items: flex-start;
      }

      .custom-numpad {
        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }
    }

    .balance {
      display: flex;
      flex-direction: column;
      margin-left: 15px;

      @include media-breakpoint-down(sm) {
        margin-top: 10px;
      }

      span {
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        color: #049e64;
      }
    }
  }
}

.phone-input {
  padding: 15px 15px 10px;

  input {
    width: 100%;
    border: none;
    font-size: 30px;
    font-family: Inter;
  }
}

.welcome-heading {
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  text-align: center;
  color: #357dc0;
}

.welcome-sub-heading {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: $black;
}

.off-points {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
}

.sub-headingbig {
  font-size: 32px;
  font-weight: 600;
  color: $blueshade2;
  margin: 0 0 10px;
}

// customer-window-tab
.customer-window-tab {
  // background-image: url("http://localhost:3002/images/customer-bg.png");
  // background-repeat: no-repeat;
  // background-position: top left;
  background-color: #f6f8fa;
  height: 100vh;

  // padding: 0 57px;
  // .container-fluid {
  //   padding: 0;
  // }
  // @media screen and (max-width: 1679px) {
  //   padding: 0 25px;
  // }
  // @media screen and (max-width: 767px) {
  //   padding: 0 15px;
  // }
  .phone-input {
    padding: 15px 15px 10px;

    input {
      width: 100%;
      border: none;
      font-size: 30px;
      font-family: Inter;
    }
  }

  .custom-card {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
    height: 100%;
    border: none;
    transition: all 0.3s;

    &:hover {
      box-shadow: 0 45px 100px rgba(0, 0, 0, 0.4);
    }

    .card-header {
      text-align: center;
      background: #fff;

      h1 {
        font-size: 40px;
        font-weight: bold;
        font-stretch: normal;
        text-align: center;
        color: #357dc0;

        @media screen and (max-width: 767px) {
          font-size: 27px;
        }
      }

      h2 {
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        color: $black;

        @media screen and (max-width: 767px) {
          font-size: 20px;
        }
      }

      p {
        color: rgba(0, 0, 0, 0.5);
      }
    }

    .card-body {
      padding-top: 1.25rem;
      padding-bottom: 0;

      &.right {
        padding-top: 2.25rem;

        @media screen and (max-width: 991px) {
          padding-top: 1.25rem;
        }

        .MuiButtonBase-root {
          &:last-child {
            background-image: url("http://localhost:3002/images/enter.svg");
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }
    }

    .custom-numberpad {
      position: relative;
      margin-top: 30px;

      @media screen and (max-width: 991px) {
        margin-top: 20px;
      }
    }

    .enter-number {
      border: none;
      color: $black;
      font-size: 42px;
      padding-left: 25px;
      font-weight: 600;

      @media screen and (max-width: 1279px) {
        font-size: 22px;
        padding-left: 15px;
      }

      &::placeholder {
        color: rgba(0, 0, 0, 0.5);
        font-size: 30px;

        @media screen and (max-width: 1279px) {
          font-size: 25px;
        }
      }
    }

    .MuiPaper-root.MuiPaper-elevation1 {
      width: 100%;
      background: #fff;
      box-shadow: none;
      position: relative;
      padding-top: 0;

      .jeiWCh {
        overflow: hidden;
        border-top: 1px solid #ccc;
        // border-bottom: 1px solid #ccc;
      }

      .MuiButton-root {
        box-shadow: none;
        margin: -1px;
        padding: 45px 0px !important;
        width: 33.5% !important;
        border: 1px solid #ccc;
        color: #0daeeb;
        font-size: 50px;
        font-weight: normal;
        border-radius: 0 !important;
        border-bottom: none;
        border-right: none;
        transition: all 0.3s;

        &:hover,
        &:active,
        &:focus {
          background-color: #e6f7fd;
        }

        @media screen and (max-width: 991px) {
          padding: 25px 0px !important;
          font-size: 40px;
        }
      }
    }

    &.left {
      padding: 16.6px 70px 58px;

      @media screen and (max-width: 1279px) {
        padding: 16.6px 30px;
      }

      @media screen and (max-width: 767px) {
        padding: 15px;
      }
    }

    &.thankyou-wrapper {
      .card-body {
        margin-top: 167px;
        padding: 30px;
        text-align: center;

        @media screen and (max-width: 1364px) {
          padding: 20px;
          margin-top: 127px;
        }

        @media screen and (max-width: 991px) {
          margin-top: 0;
        }

        h1 {
          font-size: 40px;
          font-weight: bold;
          color: #357dc0;
          margin-bottom: 33px;

          @media screen and (max-width: 575px) {
            font-size: 30px;
            margin-bottom: 13px;
          }
        }

        p {
          font-size: 30px;
          font-weight: 500;
          max-width: 356px;
          margin: auto;
          line-height: 1.4;

          @media screen and (max-width: 575px) {
            font-size: 20px;
          }
        }

        .list-group {
          margin-top: 59px;
          padding-top: 0;
          margin-bottom: 0;
          border: none;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;

          @media screen and (max-width: 1279px) {
            flex-direction: column;
          }

          @media screen and (max-width: 991px) {
            flex-direction: row;
          }

          @media screen and (max-width: 575px) {
            flex-direction: column;
            margin-top: 39px;
          }

          .list-group-item {
            border: 1px solid rgb(216, 216, 216);
            padding: 20px;
            transition: all 0.3s;
            width: 31%;

            a {
              display: flex;
              justify-content: center;
              flex-direction: row;
              align-items: center;
              flex-direction: column;
            }

            @media screen and (max-width: 1279px) {
              width: 100%;
              flex-direction: column;
              margin-bottom: 20px;
            }

            @media screen and (max-width: 991px) {
              width: 31%;
              margin-bottom: 0;
            }

            @media screen and (max-width: 767px) {
              padding: 15px;
            }

            @media screen and (max-width: 575px) {
              width: 100%;
              flex-direction: column;
              margin-bottom: 20px;
            }

            &:hover,
            &:active,
            &:focus {
              box-shadow: 0 45px 100px rgba(0, 0, 0, 0.4);
            }

            img {
              width: 109.5px;
              height: 109.5px;
              margin-bottom: 23px;

              @media screen and (max-width: 1364px) {
                width: 79.5px;
                height: 79.5px;
                margin-bottom: 20px;
              }
            }

            span {
              font-size: 20px;
              font-weight: 500;
              line-height: normal;
              color: #454545;

              @media screen and (max-width: 1364px) {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }

  .total-info-screen {
    .custom-card {
      .card-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: none;
        padding: 18px 34px 14px 29px;

        @media screen and (max-width: 767px) {
          flex-direction: column;
          align-items: flex-start;
          padding: 15px;
        }

        div {
          @media screen and (max-width: 767px) {
            justify-content: space-between;
            display: flex;
            width: 100%;
            align-items: center;
            flex-direction: column;
          }
        }

        h1 {
          margin-bottom: 0;

          @media screen and (max-width: 575px) {
            margin-bottom: 20px;
          }
        }

        p {
          margin-bottom: 0;
          font-size: 16px;
          font-weight: 500;
        }

        .badge {
          padding: 9px 50px;
          font-size: 30px;
          font-weight: 600;

          @media screen and (max-width: 767px) {
            margin-bottom: 10px;
          }
        }

        h1 {
          font-size: 30px;
        }
      }

      .search-bar .clear {
        background-image: url("http://localhost:3002/images/cross-blue.svg");
        background-repeat: no-repeat;
      }
    }

    .pagination-wrapper .react-bootstrap-table-pagination div:first-child {
      top: -58px;
    }

    .custom-data-table {
      min-height: auto;

      .card {
        &.custom-card {
          height: auto;
        }
      }

      thead {
        tr {
          th {
            width: auto !important;
          }
        }
      }
    }

    .list-group {
      border-radius: 8px;
      border: solid 1px #d8d8d8;
      background: #fff;
      padding: 1rem 0;
      max-width: 71%;
      transition: all 0.3s;

      &:hover {
        box-shadow: 0 45px 100px rgba(0, 0, 0, 0.4);
      }
    }

    .list-group-item {
      font-size: 18px;
      font-weight: 500;
      padding: 0.2rem 1rem;
      display: flex;
      background: #fff;
      justify-content: space-between;

      p {
        opacity: 0.45;
        margin-bottom: 0;
      }

      span {
        color: $black;
        font-size: 18px;
        font-weight: bold;
      }
    }

    .rewards-offers {
      padding: 15px 33px 21px 21px;
      border-radius: 8px;
      background-color: #f0f3fb;
      margin-bottom: 20px;
      max-width: 479px;

      span {
        color: $black;
        font-size: 16px;
        font-weight: 500;
      }

      strong {
        margin-bottom: 15px;
      }
    }

    &.with-number {
      .list-group {
        max-width: 100%;
        margin-top: 15px;
        margin-bottom: 23px;

        &:hover {
          box-shadow: none;
        }
      }

      .grand-total-info {
        p {
          text-align: right;
          margin-bottom: 0;
        }
      }

      .top-info {
        .right {
          &.card-body {
            padding-top: 1.25rem;

            h2 {
              font-size: 24px;
              font-weight: bold;
            }

            .box {
              padding-left: 22px;
            }
          }
        }
      }
    }
  }
}

.settings {
  h1 {
    font-size: 23px;
  }
  .custom-tab-nav {
    background-color: #f9f9f9;
    padding-top: 0;
    width: calc(100% + 30px);
    padding: 0;
    margin-bottom: 10px;
    // .nav-item {
    //   flex: 0;
    //   .nav-link {
    //     padding: 0.75rem 2.85rem;
    //   }
    // }
  }

  .accordion {
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.12);
  }

  .accordion-body {
    padding: 1.25rem;

    @media screen and (max-width: 767px) {
      padding: 15px;
    }
  }

  .card-body,
  .card-header {
    padding: 1.25rem;

    @media screen and (max-width: 767px) {
      padding: 15px;
    }
  }

  // .card-body{
  //   padding-top: 0;
  // }
  .accordion-button {
    background: $grey-custom-shade;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: $dark-gray;
    box-shadow: none;
    // border: none;
    // border-bottom: 1px solid $blackcustom;
    border-color: $blackcustom;
  }

  .accordion-button:not(.collapsed) {
    background: $grey-custom-shade;
  }

  .right-select {
    // .custom-react-select{
    //   width: 50%;
    // }
  }

  .general {
    .accordion-body {
      background: #fff;
      height: calc(100vh - 337px);

      @media screen and (max-width: 767px) {
        height: auto;
      }
    }
  }

  .stats {
    h2 {
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      color: $dark-gray;
    }
  }

  .registration-list {
    .list-group-item {
      padding-left: 0;
      border: none;
      border-bottom: 1px solid $blackcustom;
      padding: 15px 0;
      border-radius: 0;

      &:last-child {
        border-bottom: none;
      }

      .ms-2 {
        min-width: 200px;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #1a1a1a;
        margin-left: 0 !important;
      }

      + .list-group-item {
        border-top-width: 0;
      }

      .badge {
        padding: 0;
        border-radius: 4px !important;
        word-break: break-all;
        background-color: transparent !important;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #1a1a1a;
        text-align: left;
        white-space: normal;
      }

      &.regestration-ui {
        @media screen and (max-width: 767px) {
          width: 100%;
          // flex-direction: column;
        }

        .badge {
          @media screen and (max-width: 767px) {
            width: 100%;
            margin-left: 0.5rem;
            // white-space: nowrap;
            // overflow: hidden;
            // text-overflow: ellipsis;
          }
        }
      }
    }
  }

  .stats {
    .card {
      transition: all 0.3s;
      height: 100%;
      height: auto;
      // &:hover {
      //   box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.4);
      // }
    }
  }

  .btn-yellow {
    height: 40px;
  }

  .settings-tab {
    position: relative;
    min-height: calc(100vh - 231px);
    background: #fff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.12);
    border-radius: 6px;

    .custom-card {
      box-shadow: none;
    }

    .right-select {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .custom-react-select {
        width: 49%;

        @media screen and (max-width: 991px) {
          margin-bottom: 15px;
        }

        @media screen and (max-width: 576px) {
          width: 100%;
        }
      }
    }
  }

  .cta-btn-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1279px) {
      position: relative;
    }

    @media screen and (max-width: 991px) {
      justify-content: flex-start !important;
    }

    .btn {
      width: 298px;
      margin-bottom: 10px;

      &:first-child {
        margin-left: 0;
      }

      @media screen and (max-width: 1919px) {
        padding: 0.66rem 0.25rem;
        font-size: 0.775rem;
      }

      @media screen and (max-width: 1679px) {
        padding: 0.66rem;
        font-size: 0.875rem;
        width: 24%;
      }

      @media screen and (max-width: 991px) {
        width: 32%;
        margin-right: 10px;
      }

      @media screen and (max-width: 767px) {
        width: 100%;
        margin-right: 0;
      }

      &:nth-child(3n) {
        @media screen and (max-width: 991px) {
          margin-right: 0;
        }
      }
    }
  }
}

.maxwidth_none .settings .cta-btn-wrapper .btn {
  @media screen and (max-width: 767px) {
    width: 100%;
    margin-top: 0;
  }
}

// Thankyou customer screen

// SLick slider
// .customer-window-tab {
//   padding: 0;
//   width: 100%;
// }
.custom-slick-slider {
  // margin: 0 -25px;
  // overflow-x: hidden;
  .slick-slide {
    img {
      // height: 100vh;
      // object-fit: cover;
      width: 100%;
      height: auto;
    }
  }

  .slick-dots {
    bottom: 30px;

    li button {
      border-radius: 100%;
      background: $blackcustom;
    }

    li.slick-active button {
      color: $primary;
      opacity: 1;
    }

    li button:before {
      color: $blackcustom;
    }

    li.slick-active button:before {
      font-size: 13px;
      color: $primary;
      opacity: 1;
    }
  }
}

// custom-time-picker
.custom-time-picker {
  position: relative;

  &.with-label-up {
    .rdtPicker {
      bottom: 50px;
      top: auto;
    }
  }

  .form-control {
    &:focus {
      z-index: 0 !important;
    }
  }

  .form-label {
    position: absolute;
    top: -8px;
    width: auto;
    z-index: 1;
    left: 13px;
    background: #fff;
    padding: 0 2px;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;

    /* Text Navy */

    color: $dark-gray;
  }

  svg {
    path {
      fill: $primary;
    }
  }
}

.clock-in {
  h2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    font-size: 1.25rem;
    color: #1a1a1a;
  }
}

.modal-clock-pass {
  .modal-content {
    .modal-body {
      padding-top: 44px;
      padding-bottom: 44px;
      text-align: center;
      font-size: 1.25rem;
    }

    .modal-footer {
      .btn-outline-seagreen {
        justify-content: center;
      }
    }
  }
}

.divider {
  border-top: 1px solid $blackcustom;
  padding-top: 25px;
}

.btn-outline-seagreen {
  background: #d5efe5;
  display: flex;
  align-items: center;
  color: #1a1a1a;

  &:hover,
  &:active,
  &:focus {
    background: #9adfb3;
    color: #1a1a1a;
  }
}

.btn-outline-danger {
  background: #f6d8d8;
  color: #1a1a1a;

  &:hover,
  &:active,
  &:focus {
    background: #ae0f0f;
    color: #fff;
  }
}

.uploader {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: auto;

  .form-control {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: $dark-gray;
  }
}

.custom-time-picker {
  .rdtPicker {
    left: auto;
    right: 0;
    border: 1px solid #dfdfdf;
    /* Deep Dropdown */

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
  }
}

.rdtPicker {
  left: auto;
  right: 0;
  border: 1px solid #dfdfdf;
  /* Deep Dropdown */

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  background: $seelct-bg-color;
}

.customer-info {
  .modal-body {
    padding: 1rem 1.4rem;
  }

  .form-floating {
    height: 100%;
  }

  .bottom-block {
    padding-top: 30px;
    margin-top: 10px;
    border-radius: 0;
    border-top: 1px solid #b1b1b1;

    .list-group-item {
      h4 {
        min-width: auto;
      }

      p {
        margin-left: 15px;
      }
    }
  }

  h4 {
    font-size: 16px;
    color: #1a1a1a;
    min-width: 60px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .list-group-item {
    padding: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #1a1a1a;
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    p {
      font-weight: normal;
      margin-bottom: 0;
      margin-left: 38px;
    }

    &.flex-direction-column {
      flex-direction: column;
      align-items: flex-start;

      .btn {
        margin-top: 8px;
        min-width: 274px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .scroll-auto {
    max-height: 207px;
    /* Adjust the height as needed */
    overflow-y: auto;
    width: 100%;
  }

  textarea.form-control {
    height: 100%;
  }
}

.batch-close {
  border: 1px solid $blackcustom;
  border-radius: 6px;
  display: flex;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: $dark-gray;
  align-items: center;
  padding: 21px 25px;

  span {
    margin-right: 23px;
  }

  .form-check {
    margin-right: 25px;

    label {
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      color: $dark-gray;
      position: relative;
      top: 4px;
    }
  }
}

.empty-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: $gray-600;

  img {
    margin-bottom: 10px;
  }
}

.custom-card {
  &.shortcut-wrapper {
    @media screen and (max-width: 767px) {
      margin-bottom: 15px !important;
      border-radius: 0;
    }
  }
}

.react-bs-table-no-data {
  @media screen and (max-width: 959px) {
    padding-left: 0;
    justify-content: center !important;
  }
}

.custom-data-table {
  .no-data {
    .react-bootstrap-table-pagination {
      @media screen and (max-width: 959px) {
        display: none;
      }
    }

    tbody tr {
      @media screen and (max-width: 959px) {
        width: 100%;
        justify-content: center;
      }
    }
  }
}

.intital-device-setup {
  &.confirmation {
    .modal-body {
      padding-bottom: 0;
    }
  }
}

// customer screens
.welcome-screen {
  height: 100vh;
  background-image: url("../../assets/images/banner.png");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    font-weight: 700;
    font-size: 64px;
    line-height: 75px;
    color: $white;

    // span {
    //   margin-left: 8px;
    // }

    @media screen and (max-width: 959px) {
      font-size: 40px;

      line-height: 55px;
      text-align: center;
    }
  }
}

// number-entry
.number-entry {
  height: 100%;
  background: #fff;
  padding: 0;

  .left-side {
    align-items: center;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    color: $dark-gray;
    font-weight: 400;

    .inner-wrapper {
      text-align: center;
      background: rgba(255, 255, 255, 0.83);
      backdrop-filter: blur(2px);
      padding: 63px 20px;
      // padding: 63px 0px 0;
      width: 100%;
      // margin-top: 122px;

      @media screen and (max-width: 991px) {
        padding: 25px 10px;
      }

      @media screen and (max-height: 768px) {
        padding-top: 0 !important;
        padding-bottom: 20px;
      }
    }

    h1 {
      font-size: 35px;
      color: $dark-gray;
      line-height: 48px;
      font-weight: 400;
      margin-bottom: 20px;

      // span {
      //   margin-left: 8px;
      // }

      @media screen and (max-width: 959px) {
        font-size: 40px;

        line-height: 55px;
        text-align: center;
      }

      @media screen and (max-width: 576px) {
        font-size: 30px;
      }
    }

    h2 {
      font-weight: 400;
      font-size: 50px;
      line-height: 33px;
      color: $dark-gray;
      margin-bottom: 20px;
      line-height: normal;

      @media screen and (max-width: 576px) {
        font-size: 30px;
      }
    }

    p {
      color: $dark-gray;
      margin-bottom: 0;
      font-size: 35px;
    }

    .cacl-height {
      // height: calc(100vh - 249px);
      width: 100%;
    }

    @media screen and (max-height: 768px) {
      padding-top: 0 !important;
    }
  }

  .right-side {
    // background-image: url("../../assets/images/left.png");
    // background-repeat: no-repeat;
    // background-position: top left;
    background: #e9f0f8;
    align-items: center;
    justify-content: center;
    display: flex;

    @media screen and (max-height: 768px) {
      // padding-top: 0 !important;
    }

    .inner-wrapper {
      width: 95%;
      position: relative;
      padding-bottom: 110px;

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      .step-4 {
        color: #2e384d;
        text-align: center;
        margin-bottom: 20px;

        h1,
        h2 {
          color: #2e384d;
        }

        h1 {
          font-weight: 700;
          font-size: 41px;
          line-height: 48px;

          span {
            margin-left: 8px;
          }

          @media screen and (max-width: 959px) {
            font-size: 40px;

            line-height: 55px;
            text-align: center;
          }

          @media screen and (max-width: 576px) {
            font-size: 30px;
          }
        }

        h2 {
          font-weight: 500;
          font-size: 28px;
          line-height: 33px;

          @media screen and (max-width: 576px) {
            font-size: 24px;
          }
        }

        p {
          margin-bottom: 0;
          font-size: 20px;
        }
      }
    }

    .custom-numberpad {
      position: relative;

      .MuiPaper-root.MuiPaper-elevation1 {
        width: 100%;
        box-shadow: none;
        padding: 0;
      }

      .MuiPaper-root .MuiButton-root {
        font-weight: 700;
        font-size: 39.3388px;
        line-height: 46px;
        width: 123.92px;
        height: 90px;

        @media screen and (max-width: 576px) {
          // font-size: 29px;
          height: 97.68px;
        }

        @media screen and (max-height: 768px) {
          height: 93.68px;
        }
      }
    }

    .enter-number {
      margin-bottom: 20px;
      height: 78.68px;
      font-size: 26px;
      background: #fff;

      @media screen and (max-width: 576px) {
        height: 58.68px;
        font-size: 20px;
      }

      &::-webkit-input-placeholder {
        color: $black;
        font-size: 26px;

        @media screen and (max-width: 576px) {
          font-size: 26px;
        }
      }

      &:-ms-input-placeholder {
        color: $black;
        font-size: 26px;

        @media screen and (max-width: 576px) {
          font-size: 26px;
        }
      }

      &::placeholder {
        color: $black;
        font-size: 26px;

        @media screen and (max-width: 576px) {
          font-size: 26px;
        }
      }
    }

    .MuiButtonBase-root {
      background-repeat: no-repeat;
      background-position: center;
      margin: 6px 0;
      width: 32% !important;

      @media screen and (max-width: 576px) {
        width: 30% !important;
      }

      &:last-child {
        background-image: url("../../assets/images/enter.png");
      }

      &:nth-child(10) {
        background-image: url("../../assets/images/backspace.png");
      }
    }
  }

  &.with-keypad {
    //   // .right-side {
    //   //   background-image:none;
    //   //   display:block;
    //   // }
    //   // .left{
    //   //   padding-top: 40px;
    //   // }
    //   .thankyou-wrapper {
    //     width: 100%;
    //     background: transparent;
    //   }
    .left {
      justify-content: center;
    }
  }

  &.welcome-screen {
    background-image: url("../../assets/images/banner.png");
    // height: 108%;
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;
    padding: 16.5px 15px;

    .right-side {
      background: transparent;

      .enter-number {
        background: #fff;
        height: 57px;
        font-weight: 700;
        font-size: 39.3388px;
        line-height: normal;
        padding-left: 15px;
        margin-bottom: 18px;

        &::-webkit-input-placeholder {
          font-weight: 400;
          font-size: 22px;
          line-height: normal;
          color: $gray-600;
        }

        &:-ms-input-placeholder {
          font-weight: 400;
          font-size: 22px;
          line-height: normal;
          color: $gray-600;
        }

        &::placeholder {
          font-weight: 400;
          font-size: 22px;
          line-height: normal;
          color: $gray-600;
          position: relative;
          top: -5px;
        }
      }

      .custom-numberpad .MuiPaper-root.MuiPaper-elevation1 {
        background: transparent;
        padding: 0;
      }

      .custom-numberpad .MuiPaper-root .MuiButton-root {
        width: 123.917px !important;
        height: 78.678px;
        border-radius: 9px !important;
        margin: 10px 0;
      }

      .no-thanks {
        font-weight: 700;
        font-size: 39.3388px;
        line-height: normal;
        padding: 8px 0 !important;
        // box-shadow: -1px 1px 5px rgb(0 0 0 / 28%);
        border-radius: 9px !important;
        margin: 10px 0;
        background: #fff;
        width: 100%;
        height: 78.678px;
        color: $black;
        border: none;
        margin-bottom: 0;

        @media screen and (max-width: 1167px) {
          height: 80.68px;
        }
      }
    }

    .left-side {
      h1,
      h2,
      p {
        color: #fff;
        margin-bottom: 10px;
      }

      h1 {
        font-size: 64px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      h2 {
        // font-size: 28px;
        font-size: 42px;
        // font-style: normal;
        // font-weight: 500;
        line-height: normal;
        font-weight: 600;
      }

      p {
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 20px;
      }
    }

    .inner-wrapper {
      background: transparent;
      width: 100%;
      padding: 0;
    }

    .number-wrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      max-width: 411px;
      margin: auto;
      position: relative;
    }

    .field-wrapper {
      position: relative;
      width: 100%;

      .btn {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-75%);
      }
    }

    .no-thanks {
      // bottom: -91px;
      left: 0%;
    }
  }

  &.no-entry {
    // background: $primary;
    .right-side .inner-wrapper {
      width: 100%;
    }

    .right-side {
      background: #fff;
    }

    .left-side {
      // background: #fff;
      h1 {
        font-size: 64px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        color: #2e384d;
        margin-bottom: 10px;
      }

      h2,
      p {
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0;
      }

      h2 {
        font-weight: 600;
        font-size: 42px;
      }
    }
  }

  &.with-entry {
    h1 {
      font-size: 64px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: auto;
      color: #2e384d;

      @media screen and (max-width: 1167px) {
        max-width: none;
        margin-bottom: 20px;
      }
    }

    h2 {
      margin-bottom: 0;
    }

    h2,
    p {
      color: #2e384d;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    h2 {
      font-weight: 600;
      font-size: 42px;
    }
  }

  .field-wrapper {
    position: relative;
    width: 100%;

    .btn {
      position: absolute;
      right: 9px;
      top: 20px;
    }
  }

  .no-thanks {
    font-weight: 700;
    font-size: 39.3388px;
    line-height: 46px;
    padding: 8px 0 !important;
    // box-shadow: -1px 1px 5px rgb(0 0 0 / 28%);
    box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.28);
    border-radius: 9px !important;
    margin: 10px 0;
    background: #fff;
    width: 100%;
    height: 90px;
    color: $black;
    border: none;
    position: absolute;
    bottom: 0;

    @media screen and (max-width: 1167px) {
      height: 80.68px;
    }
  }
}

// Welcome customer
.welocme-customer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  color: #2e384d;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 0 15px;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }

  @media screen and (max-width: 991px) {
    position: relative;
    flex-direction: row;
    padding: 0;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }

  h2 {
    font-size: 30px;
  }

  span {
    text-transform: capitalize;
  }
}

// Items only
.items-only {
  padding: 0;
  height: 100%;
  background: #fff;

  .email-promition {
    position: relative;
    z-index: 1;

    .btn {
      height: 78.678px;
      padding: 10px 25px;
      font-size: 31.471px;
      font-style: normal;
      font-weight: 700;
      border-radius: 11.802px;
      box-shadow: -1.9669420719146729px 1.9669420719146729px 9.834710121154785px
        0px rgba(0, 0, 0, 0.28);

      &.btn-outline-blackcustom {
        margin-right: 15px;
        background: #0f416f;
        color: #fff;

        &:hover,
        &:active,
        &:focus {
          background: #fff;
          border: 1px solid #0f416f;
          color: #0f416f;
        }
      }
    }
  }

  .custom-slick-slider {
    height: 100%;

    .slick-slide img,
    * {
      height: 100%;
    }

    .slick-slide img {
      object-fit: cover;
    }

    .slick-dots {
      height: 20px;
    }
  }

  .objec-fit {
    height: 100%;
    object-fit: cover;
  }

  > .row {
    margin: 0;
    height: 100%;
  }

  .left {
    padding-bottom: 15px;
    justify-content: center;
    position: relative;

    &.col-md-12 {
      .grand-total-info {
        margin-top: 31px;
        text-transform: uppercase;

        .justify-content-between {
          justify-content: flex-end !important;
        }

        h2 {
          margin-right: 20px;
        }

        p {
          text-align: right;
        }
      }
    }

    .reward-offers {
      height: 140px;
      overflow-y: scroll;
      span {
        color: #049e64;
        font-weight: bold;
        margin-right: 7px;
      }
    }

    .rcs-custom-scroll .rcs-inner-handle {
      height: calc(100% - 75px);
    }

    .rcs-custom-scroll .rcs-custom-scrollbar {
      top: 74px;
    }

    .pagination-wrapper {
      max-height: 415px;
    }

    .table-bordered > :not(caption) > * > *,
    .table-bordered > :not(caption) > * {
      border-color: #b1b1b1 !important;
    }

    .card .table {
      border: 0.0313rem solid #b1b1b1;
    }

    .custom-data-table .with-scroll-on-table .react-bootstrap-table,
    .custom-data-table .with-scroll-on-table .react-bootstrap-table {
      padding: 0;
    }

    .custom-data-table thead tr th:last-child,
    .custom-data-table thead tr th:nth-child(3) {
      text-align: center;
      pointer-events: auto;
    }

    .custom-data-table tbody tr td:nth-child(3) {
      text-align: center;
    }

    .list-group {
      border: 1px solid #d3d3d3;
      border-radius: 6px;
      padding: 30px 22px;
      margin-top: 31px;
      width: 100%;

      @media screen and (max-width: 991px) {
        padding: 15px;
      }

      @media screen and (max-width: 959px) {
        // margin-top: 0;
      }

      .list-group-item {
        display: flex;
        justify-content: space-between;
        padding: 0;
        background: transparent;

        p {
          font-weight: 400;
          font-size: 32px;
          line-height: normal;
          color: $dark-gray;

          @media screen and (max-width: 991px) {
            font-size: 32px;
            margin-bottom: 10px;
          }
        }

        span {
          font-weight: bold;
          font-size: 32px;
          line-height: normal;
          color: #1a1a1a;
        }

        &:last-child {
          p {
            margin-bottom: 0;
          }
        }
      }
    }

    hr {
      margin-top: 2rem;
      background-color: #fff;
    }

    .grand-total-info {
      margin-top: 31px;

      h2 {
        font-weight: 700;
        font-size: 50px;
        line-height: normal;
        color: #2e384d;
        margin-bottom: 0;
      }

      .badge {
        font-weight: 700;
        font-size: 50px;
        line-height: normal;
        color: $white;
        padding: 8px 36px;
        border-radius: 36px;

        // @media screen and (max-width: 991px) {
        //   font-size: 22px;
        //   padding: 14px 26px;
        //   line-height: 21px;
        // }
      }

      p {
        margin: 26px 0 0 0;
        font-weight: 400;
        font-size: 24px;
        line-height: 25px;
        color: #2e384d;

        @media screen and (max-width: 991px) {
          margin: 26px 0 0 0;
          // font-size: 17px;
        }
      }
    }
  }

  .thankyou-wrapper {
    height: 100%;
    background-image: url("../../assets/images/left.png");
    background-repeat: no-repeat;
    background-position: top left;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background: rgba(255, 255, 255, 0.83);
      backdrop-filter: blur(2px);
      width: 100%;
      height: 366px;

      @media screen and (max-width: 1279px) {
        height: 95%;
      }
    }

    &.review-section {
      &:before {
        content: "";

        height: 640px;

        @media screen and (max-width: 1279px) {
          height: 100%;
        }
      }

      .qr-code {
        position: relative;
        display: flex;
        flex-direction: column;

        h1 {
          font-size: 31px;
          margin-top: 24px;
          line-height: normal;
        }

        @media screen and (max-width: 1023px) {
          font-size: 26px;
        }
      }
    }

    h1 {
      font-weight: 700;
      font-size: 41px;
      line-height: 48px;
      color: $dark-gray;
      position: relative;
      z-index: 1;
      margin: 44px 0 20px;

      @media screen and (max-width: 1279px) {
        font-size: 31px;

        margin: 24px 0 10px;
      }

      @media screen and (max-width: 767px) {
        font-size: 26px;
        margin: 14px 0 0px;
      }
    }

    p {
      font-weight: 400;
      font-size: 28px;
      line-height: 33px;
      color: $dark-gray;
      position: relative;
      z-index: 1;
      max-width: 356px;
      margin-bottom: 38px;

      @media screen and (max-width: 1279px) {
        font-size: 18px;
        line-height: 23px;
        margin-bottom: 28px;
      }

      @media screen and (max-width: 767px) {
        font-size: 18px;
        margin-bottom: 28px;
      }
    }

    .list-group {
      padding-top: 0;
      margin-bottom: 0;
      border: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      flex-direction: row;
      margin-bottom: 45px;

      @media screen and (max-width: 1279px) {
        flex-direction: column;
      }

      .list-group-item {
        border: 1px solid rgb(216, 216, 216);
        padding: 15px 9px 11px;
        transition: all 0.3s;
        width: 31%;
        border-radius: 6px;

        &:last-child {
          margin-bottom: 0;
        }

        a {
          display: flex;
          justify-content: center;
          flex-direction: row;
          align-items: center;
          flex-direction: column;
        }

        @media screen and (max-width: 1279px) {
          width: 100%;
          flex-direction: column;
          margin-bottom: 20px;
        }

        @media screen and (max-width: 767px) {
          padding: 15px;
        }

        &:hover,
        &:active,
        &:focus {
          box-shadow: 0 45px 100px rgba(0, 0, 0, 0.4);
        }

        img {
          width: 68px;
          height: auto;
          margin-bottom: 13px;

          @media screen and (max-width: 1364px) {
            width: 79.5px;
            height: 79.5px;
          }

          @media screen and (max-width: 991px) {
            width: 59.5px;
            height: 59.5px;
          }

          @media screen and (max-height: 768px) {
            width: 69.5px;
            height: 69.5px;
          }
        }

        span {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: $black !important;
        }
      }
    }

    &.email-promition {
      .d-flex {
        position: relative;
        z-index: 1;
      }

      h1 {
        margin-top: 0;
      }

      .btn {
        height: 78.678px;
        padding: 10px 25px;
        font-size: 31.471px;
        font-style: normal;
        font-weight: 700;
        border-radius: 11.802px;
        box-shadow: -1.9669420719146729px 1.9669420719146729px
          9.834710121154785px 0px rgba(0, 0, 0, 0.28);

        &.btn-outline-blackcustom {
          margin-right: 15px;
          background: #0f416f;
          color: #fff;

          &:hover,
          &:active,
          &:focus {
            background: #fff;
            border: 1px solid #0f416f;
            color: #0f416f;
          }
        }
      }
    }
  }

  .custom-data-table .card .table th,
  .custom-data-table .card .table td {
    padding-left: 15px;
  }

  .custom-data-table .card .table *,
  .list-group-item * {
    font-size: 32px;
    // color: #fff !important;
  }
}

:not(:root):fullscreen::backdrop {
  position: relative !important;
  display: none;
  background: red !important;
}

.fullscreen {
  object-fit: none;
  user-select: auto;
  position: relative !important;
  top: auto !important;
  max-width: 100% !important;
  max-height: 100% !important;
  min-height: 100% !important;
  transform: none !important;
  min-width: 100% !important;
  margin: auto !important;
  width: auto !important;
  height: auto !important;
  right: auto !important;
  left: auto !important;
  bottom: auto !important;
  box-sizing: initial !important;
}

.void-refund {
  background: #f6f8fa;
}

.refund-details {
  .list-group-item {
    padding: 0;
    display: flex;
    font-size: 16px;
    line-height: 19px;
    color: #1a1a1a;
    margin-bottom: 20px;

    .fw-bold {
      min-width: 63px;
      margin-right: 30px;
    }
  }
}

.other-options {
  .btn {
    justify-content: center;
  }
}

// Mode button in general setting

.mode {
  position: relative;
  display: flex;
  width: 360px;

  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000;
  }
}

.mode .switch {
  position: absolute;
  width: 60px;
  right: 0;
  top: -4px;
  height: 30px;
}

.mode .switch input {
  display: none;
}

.mode .switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(30, 30, 30);
  transition: all 1.4s;
  border-radius: 50px;
  z-index: 100;
  overflow: hidden;
}

.mode .switch .slider:before,
.mode .switch .slider:after {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 5px;
  bottom: 5px;
  border-radius: 30px;
  background-color: #fff;
}

.mode .switch .slider:before {
  transition: 0.4s;
}

.mode .switch .slider:after {
  transition: 0.5s;
}

// .mode .switch input:checked + .slider {
//   background-color: transparent;
// }
.mode .switch input:checked + .slider:after,
.mode .switch input:checked + .slider:before {
  transform: translateX(30px);
}

.navbar-brand,
img {
  opacity: 1;
  transition: all 1s;
}

.objec-fit {
  height: auto;
}

// Store login
.store-info {
  .form-control {
    // width: 77%;
    position: relative;

    @media screen and (max-width: 576px) {
      // width: 64%;
    }
  }

  .form-text {
    position: absolute;
    right: 0;
    top: 5px;
    color: #000;
  }
}

.show-sign-in {
  h2 {
    display: flex;
    justify-content: space-between;
  }

  a {
    color: $blue;
    text-decoration: underline;
    float: right;
  }

  h4 {
    text-transform: capitalize;
    text-align: center;
    margin: 20px 0 40px;
    font-weight: bold;
    // text-decoration: underline;
    font-size: 40px;
    color: $primary;
  }

  .btn {
    padding: 0.8rem 0.75rem;
  }
}

.pre-sign-in {
  .form-control {
    text-transform: capitalize;
  }

  .btn {
    padding: 0.8rem 0.75rem;
  }
}

@for $i from 12 through 18 {
  .font-size-#{$i} {
    .navbar,
    .items-contain,
    .total-info {
      * {
        font-size: #{$i}px !important;
      }
    }
    .items-contain {
      .form-control {
        &::-webkit-input-placeholder {
          font-size: #{$i}px !important;
        }

        &:-ms-input-placeholder {
          font-size: #{$i}px !important;
        }

        &::placeholder {
          font-size: #{$i}px !important;
        }
      }
    }
  }
}
.font-size-18 {
  .pay-btn {
    font-size: 23px;
  }
}
.font-size-17 {
  .pay-btn {
    font-size: 22px;
  }
}
.font-size-16 {
  .pay-btn {
    font-size: 21px;
  }
}
.font-size-15 {
  .pay-btn {
    font-size: 20px;
  }
}
.font-size-14 {
  .pay-btn {
    font-size: 19px;
  }
}
.font-size-13 {
  .pay-btn {
    font-size: 18px;
  }
}
.font-size-12 {
  .pay-btn {
    font-size: 17px;
  }
}

@for $i from 10 through 50 {
  .font-size-search-#{$i} {
    .register {
      .react-auto-complete {
        .list-group-item {
          span {
            line-height: normal;
            font-size: #{$i}px !important;
          }
        }
      }
    }
  }
}

// iframe {
//   display: none;
// }

// auth button
.auth-btn {
  bottom: -70px !important;
  right: 35% !important;
}

// progress bar for initial component
.custom-progress-bar {
  height: 20px;
  font-size: 14px;
}

.initial-box {
  padding-bottom: 83px;
}

// bg of disabled price
.price-bg:disabled {
  background-color: rgba(219, 227, 233, 0.34) !important;
}

.price-bg {
  // @media screen and (max-width: 767px) {
  //   width: 140px;
  // }
}

// details-btn
.btn-details {
  @media screen and (max-width: 576px) {
    width: 100% !important;
  }
}

// points requires last  child
.req-th {
  .react-bootstrap-table {
    th:last-child {
      text-align: start !important;
    }
  }
}

// timer text
.timer-txt {
  font-size: 16px !important;
  font-weight: bold !important;
  position: absolute;
  top: -10.4%;
  left: 7%;

  @media screen and (max-width: 767px) {
    top: -12.4%;
  }

  @media screen and (max-width: 576px) {
    left: 16%;
  }
}

.timer-txt-2 {
  font-size: 16px !important;
  font-weight: bold !important;
  position: absolute;
  top: -20.4%;
  left: 8%;

  @media screen and (max-width: 767px) {
    top: -31.4%;
  }

  @media screen and (max-width: 576px) {
    left: 19%;
  }
}

// no sale btn
.sale-btn {
  @media screen and (max-width: 576px) {
    margin-left: 12px;
  }
}

.ref-table {
  .with-scroll-on-table {
    height: auto !important;
  }
}

// .table-container {
//   max-height: calc(100% - 0px); /* Adjust as needed */
//   overflow-y: auto;
// }

// /* Keep the last row at the bottom */
// .table-container table tbody tr:last-child {
//   position: sticky;
//   bottom: 0;
//   background-color: white; /* To hide sticky element from being seen through */
// }
/* Apply the styles to the container with overflow */
.table-container::-webkit-scrollbar {
  width: 8px;
  /* Width of the scrollbar */
}

/* Track */
.table-container::-webkit-scrollbar-track {
  background: #fff;
  /* Background color of the track */
}

/* Handle */
.table-container::-webkit-scrollbar-thumb {
  background: #000;
  /* Color of the handle */
  border-radius: 8px;
}

/* Handle on hover */
.table-container::-webkit-scrollbar-thumb:hover {
  background: #000;
  /* Color of the handle when hovered */
}

// refund button
// .transaction-details {
//   .modal-footer {
//     .ref-btn {
//       margin-right: 136px;
//     }
//     .ref-btn-2 {
//       margin-right: 8px !important;
//     }
//   }
// }
// list group active
.list-grp.active {
  color: white !important;
}

.modal-open {
  .modal {
    display: block;
  }
}

// no button
.no-btn {
  margin-right: 162px !important;
}

// cancel modal
.cancel-modal {
  .modal-footer {
    justify-content: center;

    .btn {
      margin: 0.25rem !important;
    }
  }
}

// responsisve customer button
.cust-btn,
.new-btn {
  @media screen and (max-width: 460px) {
    width: 100%;
  }
}

.cust-btn {
  @media screen and (max-width: 460px) {
    margin-bottom: 10px !important;
  }
}

// customer info buttons
.cust-btns {
  .modal-content {
    .modal-footer {
      @media screen and (max-width: 576px) {
        justify-content: space-between;
      }
    }
  }
}

// thank btn
.thank-btn {
  padding-top: 90px;
}

.number-entry-no-thanks {
  height: auto;
}

// no button
.no-right {
  margin-right: 0px !important;
  // @media screen and (max-width: 576px) {
  //   margin-left: 163px !important;
  // }
}

.yes-right {
  right: 166px !important;
  // @media screen and (max-width: 767px) {
  //   left:222px !important;
  // }
  // @media screen and (max-width: 576px) {
  //     left:178px !important;
  //   }
}

// table-height
.with-scroll-on-table {
  .page-height {
    height: 220px !important;
  }
}

// align button
.align-btn {
  .modal-footer {
    justify-content: center;
  }
}

// confirmation modal clock
.modal-clock {
  .modal-content {
    .modal-header {
      padding: 26px 20px;
    }

    .modal-body {
      padding-top: 44px;
      padding-bottom: 44px;
      text-align: center;
      font-size: 1.25rem;
    }

    .modal-footer {
      .btn-outline-seagreen {
        justify-content: center;
      }
    }
  }
}

.modal-clock-pass {
  .modal-content {
    .modal-body {
      padding-top: 44px;
      padding-bottom: 44px;
      text-align: center;
    }

    .modal-footer {
      .btn-outline-seagreen {
        justify-content: center;
      }
    }
  }
}

.file-list {
  .img-cross {
    img {
      padding: 4px;
      border-radius: 50%;
    }

    span {
      margin-left: -14px;
      margin-top: -9px;
    }
  }
}

/* SlideInWindow.css */
.slide-in-window {
  position: fixed;
  top: 0;
  right: -300px;
  /* Adjust this value to control how far the window slides in */
  width: 300px;
  /* Adjust this value for the width of the slide-in window */
  height: 100%;
  background-color: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  transition: right 0.3s ease-in-out;
  /* Add a transition for smooth animation */
  z-index: 1000;
}

.slide-in-content {
  padding: 10px;
  height: 100%;
  overflow-y: auto;
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
  font-size: 18px;
  position: absolute;
  top: 10px;
  right: 10px;
}

/* Apply the "open" class to slide in the window */
.slide-in-window.open {
  right: 0;
}

/* Add this CSS to your stylesheet */
@media (max-width: 767px) {
  .shortcut-container {
    position: fixed;
    top: 0;
    right: -80%;
    /* Adjust this value for the width of the slide-in window */
    height: 100%;
    width: 80%;
    /* Adjust this value for the width of the slide-in window */
    background-color: #fff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
    transition: right 0.3s ease-in-out;
    /* Add a transition for smooth animation */
    z-index: 1000;

    .input-group {
      padding: 15px;
      padding-bottom: 0;

      .btn-inside-field {
        text-indent: -999999px;
        right: 15px;
        width: auto;
        padding: 10px;

        svg {
          margin: 0 !important;
        }
      }
    }

    > .btn-link {
      position: absolute;
      right: 0;
      z-index: 11;
      right: -7px;
      z-index: 11;
      top: -5px;

      img {
        width: 13px;
        height: 12px;
      }
    }

    &.bottom {
      top: auto;
      height: 50%;
      bottom: -50%;
      width: 100%;
      left: 0;
      transition: bottom 0.3s ease-in-out;
      padding: 15px;
      &.has-options {
        height: 26%;
        bottom: -26%;
      }

      > .btn-link {
        right: -2px;
        top: -3px;
      }

      &.open {
        bottom: 0;
        right: auto;
      }
    }
    &.footer-info {
      bottom: auto;
      height: 26%;
      top: -26%;
      width: 100%;
      left: 0;
      transition: top 0.3s ease-in-out;
      padding: 15px;
      z-index: 10;

      > .btn-link {
        right: -2px;
        top: -3px;
      }

      &.open {
        top: 42px;
        right: auto;
      }
    }
  }

  /* Apply the "open" class to slide in the window */
  .shortcut-container.open {
    right: 0;
  }

  /* Add this CSS to your stylesheet */
  .shortcut-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background color */
    display: none;
    z-index: 999;
    /* Place it below the slide-in window */
    cursor: pointer;
    /* Show a pointer cursor on hover */
    &.for-footer {
      z-index: 9;
    }
  }

  .shortcut-overlay.visible {
    display: block;
    /* Show the overlay when needed */
  }
}

.label-text {
  .form-label {
    font-weight: 400;
  }
}

.page-text {
  .react-bootstrap-table-pagination-total {
    display: block !important;
  }
  thead {
    th {
      &:last-child {
        text-align: left !important;
      }
    }
  }
}

.store-info-footer {
  position: fixed;
  z-index: 11;
  right: 46px;
  border-radius: 0;
  height: 41px;
  img {
    position: relative;
    top: -3px;
    left: -1px;
  }
}
video {
  width: 100%;
}

.login-height {
  @media screen and (max-height: 500px) {
    overflow-y: auto;
  }

  .shadow-soft {
    @media screen and (max-height: 500px) {
      margin-top: 92px;
    }
  }
}
::-moz-selection {
  /* Code for Firefox */
  color: $white;
  background: $primary;
}

::selection {
  color: $white;
  background: $primary;
}

// Advanced Modifier
.custom-modal.advance-popup .modal-body {
  @media screen and (max-width: 767px) {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }
}
.advance-modifier {
  @media screen and (max-width: 767px) {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 1.5rem;
  }
  .navigating {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.4);
      padding: 20px 10px 10px;
      border-radius: 6px;
      margin-bottom: 1.5rem;
    }
    .first-qty {
      min-width: 60px;
      width: auto;
    }
    .notes {
      min-width: 184px;
      @media screen and (max-width: 991px) {
        min-width: auto !important;
      }
    }
    .avg-cost {
      @media screen and (max-width: 991px) {
        min-width: 90px !important;
      }
    }
  }
  .form-floating {
    margin-right: 10px;
    @media screen and (max-width: 991px) {
      width: auto !important;
    }
    @media screen and (max-width: 767px) {
      width: 48% !important;
      margin: 0 !important;
      margin-bottom: 10px !important;
    }
  }
  .custom-react-select {
    min-width: 150px;
    margin-right: 10px;
    @media screen and (max-width: 991px) {
      min-width: 120px;
    }
    @media screen and (max-width: 767px) {
      min-width: 48%;
      margin: 0;
      margin-bottom: 10px;
    }
  }
  .add-icon {
    background: #049e64;
    border-radius: 100%;
    padding: 10px;
  }
  .btn-link {
    img {
      min-width: 26px;
      height: 26px;
    }
  }
  &.scroll-auto {
    overflow-y: auto;
    height: 296px;
    overflow-x: hidden;
    @media screen and (max-width: 767px) {
      height: calc(100vh - 208px);
    }

    &::-webkit-scrollbar {
      width: 4px;
      border: 1px solid $blue;
    }

    &::-webkit-scrollbar-track {
      border-radius: 0;
      background: #eaedf2;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0;
      background: $blue;
    }
  }
}

@for $i from 25 through 50 {
  .font-customer-size-#{$i} {
    #item-table {
      .custom-data-table .card .table *,
      .list-group-item * {
        font-size: #{$i}px !important;
      }
    }
  }
}

.sales-pos-table {
  .top-search .add-btn {
    width: 135px;
    img {
      @media screen and (max-width: 767px) {
        margin-right: 10px;
      }
    }
  }
  .pagination-wrapper .react-bootstrap-table-pagination > div:last-child {
    position: absolute;
    bottom: -65px;
    z-index: 1;
    top: auto;
    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
      align-items: flex-end;
      bottom: -111px;
    }
  }
  // &.custom-card.pagination-absolute{
  //   padding-bottom: 0;
  // }
  .pagination-wrapper {
    height: calc(100vh - 349px);
  }
  .pagination-wrapper .react-bootstrap-table-pagination {
    @media screen and (max-width: 767px) {
      position: static;
      display: block;
      bottom: 0;
    }
  }
  .pagination-wrapper .react-bootstrap-table-pagination > div:first-child {
    @media screen and (max-width: 767px) {
      position: absolute;
      right: 0;
      top: -47px;
      justify-content: space-between;
    }
  }
  .pagination-wrapper .dropdown .btn {
    @media screen and (max-width: 767px) {
      margin-right: 0;
    }
  }
  .custom-data-table
    .column-visibility-table.card.sales-pos-table
    .expand-table-row
    thead {
    position: sticky;
  }
  .go-to-page {
    margin-right: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #2e384d;
    display: flex;
    align-items: center;
    @media screen and (max-width: 767px) {
      margin-right: 0;
      margin-bottom: 15px;
    }
    .page-selector {
      display: flex;
      margin-right: 10px;
    }
    .react-select__control {
      min-width: 80px;
      // height: 37px;
      // min-height:auto;
    }
    &.custom-react-select {
      .react-select__indicator {
        left: 0;
        width: 32px;
      }
      .react-select__indicator-separator {
        display: block;
      }
    }
  }
}

// authorization model popup
.authorization{
  .react-select__value-container{
    height: 36px;
  }
}
.custom-modal.transaction-details .with-scroll-on-table .pagination-wrapper{
  padding-bottom: 10px;
}
.loader-index::before {
  display: none;
}

.index-btn {
  z-index: 9999999;
}
iframe{
  z-index: -1 !important;
}